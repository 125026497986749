import { Theme } from "react-select";

// Как задать настройки темы
// https://react-select.com/styles#overriding-the-theme
export const customTheme = () => (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "var(--chakra-colors-custom-purple-200)",
  },
});
