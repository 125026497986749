import { useMemo } from "react";
import {
  LinkOverlayProps as ChakraLinkOverlayProps,
  LinkOverlay as ChakraLinkOverlay,
  LinkProps as ChakraLinkProps,
  Link as ChakraLink,
  forwardRef,
} from "@chakra-ui/react";
import {
  NavLinkProps as RRDNavLinkProps,
  LinkProps as RRDLinkProps,
  NavLink as RRDNavLink,
  Link as RRDLink,
} from "react-router-dom";

export type LinkProps = RRDNavLinkProps & Omit<ChakraLinkProps, "href">;

export const Link = forwardRef<LinkProps, "a">((props, ref) => <ChakraLink ref={ref} as={RRDLink} {...props} />);

export type LinkOverlayProps = RRDLinkProps & Omit<ChakraLinkOverlayProps, "href">;

export const LinkOverlay = forwardRef<LinkOverlayProps, "a">((props, ref) => (
  <ChakraLinkOverlay ref={ref} as={RRDLink} {...props} />
));

export type NavLinkProps = RRDLinkProps & Omit<ChakraLinkProps, "href"> & { to: string };

export const NavLink = forwardRef<NavLinkProps, "a">(({ to, ...args }, ref) => {
  const toWithSearchParams = useMemo(() => {
    const searchParamsString = new URLSearchParams(window.location.search).toString();
    return `${to}${searchParamsString ? `?${searchParamsString}` : ""}`;
  }, [to]);
  return <ChakraLink ref={ref} to={toWithSearchParams} as={RRDNavLink} {...args} />;
});
