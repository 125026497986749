import { ComponentStyleConfig } from "@chakra-ui/react";

import { textStyles } from "../text-styles";

export const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    mb: "1px",
    marginInlineEnd: 0,
    ...textStyles.description,
    color: "custom.grey.dark.100",
    _invalid: {
      color: "custom.red.dark.100",
    },
  },
};
