import { ReactElement, HTMLInputTypeAttribute } from "react";
import { forwardRef, IconProps, InputProps } from "@chakra-ui/react";
import { useField, UseFieldConfig } from "react-final-form";

import { FormControl, FormControlProps } from "../form-control";

import { Input } from "./input";

interface Props extends Omit<FormControlProps, "isInvalid"> {
  name: string;
  fieldConfig?: UseFieldConfig<string, string>;
  inputProps?: InputProps;
  placeholder?: string;
  autoComplete?: string;
  type?: HTMLInputTypeAttribute;
  label?: string;
  icon?: ReactElement<IconProps>;
}

const identity = (value: string) => value;

export const InputField = forwardRef<Props, "input">(
  ({ name, label, fieldConfig, caption, placeholder, autoComplete, type, icon, inputProps, ...props }, ref) => {
    const { input, meta } = useField<string, HTMLInputElement, string>(name, {
      parse: identity,
      ...fieldConfig,
    });

    return (
      <FormControl
        label={label}
        isInvalid={meta.touched && !!meta.error && meta.submitFailed}
        caption={(meta.touched && meta.error) || caption}
        {...props}
      >
        <Input
          {...inputProps}
          {...input}
          placeholder={placeholder}
          autoComplete={autoComplete}
          type={type}
          icon={icon}
          ref={ref}
        />
      </FormControl>
    );
  },
);
