import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

import { textStyles } from "../text-styles";

export const Input: ComponentStyleConfig = {
  parts: ["element", "addon", "field"],
  baseStyle: {
    field: {
      ...textStyles.text,
      color: "custom.grey.dark.200",
      borderWidth: "1px",
      borderStyle: "solid",
    },
  },
  sizes: {
    sm: {
      field: {
        h: "25px",
      },
    },
    md: {
      field: {
        h: "45px",
      },
    },
    "md-panel": {
      field: {
        h: "33px",
      },
    },
  },
  variants: {
    form: (props) => ({
      ...theme.components.Input.variants?.outline(props),
      field: {
        ...theme.components.Input.variants?.outline(props).field,
        borderColor: "custom.grey.light.200",
        _focusVisible: {
          borderColor: "brand.500",
          boxShadow: "shadows.focusInput",
        },
        _invalid: {
          borderColor: "custom.red.dark.100",
          boxShadow: "custom.red.dark.100",
        },
      },
    }),
    panel: (props) => ({
      ...theme.components.Input.variants?.outline(props),
      field: {
        ...theme.components.Input.variants?.outline(props).field,
        borderColor: "transparent",
        bgColor: "white",
        // borderWidth: 0,
        _hover: {
          borderColor: "transparent",
        },
        _focusVisible: {
          borderColor: "transparent",
        },
        _invalid: {
          borderColor: "custom.red.dark.100",
          boxShadow: "custom.red.dark.100",
        },
      },
    }),
  },
  defaultProps: {
    variant: "form",
    size: "md",
  },
};
