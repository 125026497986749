import React, { useCallback, useState } from "react";
import { Row, RowSelectionState } from "@tanstack/react-table";
import { Flex, Text, useDisclosure } from "@chakra-ui/react";

import { Page } from "shared/ui/layout/page";
import { DictAdminUserData } from "features/dictionaries/api";

import { AdminParams, useUsersInfiniteQuery } from "../api";
import { FilterStatsPanel } from "../components/filter-panel";
import { RemoveOrgModal } from "../components/modal-delete-orgs";
import { OrgModal } from "../components/org-modal";
import { AdminTableUsers } from "../components/table-users";

export type Filter = Pick<AdminParams, "oiv_ids" | "org_ids" | "sphere_ids">;

export type EditOrgProps = {
  id: number;
  org?: string;
  sphere_id?: number;
  sphere?: string;
  oiv_id?: number;
  oiv?: string;
  inn?: string;
};

export type ToBeRemovedOrgProps = {
  id: number;
  org?: string;
};

export const UsersAdminPage = () => {
  const usersModalDisclosure = useDisclosure();
  const removeUserDisclosure = useDisclosure();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [filter, setFilter] = React.useState<Filter>({});

  const handleOnFiltersChange = (v: Filter) => {
    setFilter(v);
  };

  const [editUser, setEditUser] = useState<EditOrgProps>();
  const [toBeRemovedOrg, setToBeRemovedOrg] = useState<ToBeRemovedOrgProps>();

  const handleEditOrg = useCallback(
    (row: Row<DictAdminUserData>) => {
      if (row.original.id) {
        setEditUser({
          id: row.original.id,
        });
        usersModalDisclosure.onOpen();
      }
    },
    [usersModalDisclosure],
  );

  const handleRemoveOrg = useCallback(
    (row: Row<DictAdminUserData>) => {
      if (row.original.id) {
        setToBeRemovedOrg({
          id: row.original.id,
        });
        removeUserDisclosure.onOpen();
      }
    },
    [removeUserDisclosure, setToBeRemovedOrg],
  );

  const handleOrgCloseModal = () => {
    setEditUser(undefined);
    usersModalDisclosure.onClose();
  };

  const { data: stat, fetchNextPage, hasNextPage } = useUsersInfiniteQuery({ limit: 100 }, {});

  const tableData = stat?.pages.flatMap((page) => page);

  return (
    <Page>
      <FilterStatsPanel
        filter={filter}
        rowSelection={rowSelection}
        onFilterChange={handleOnFiltersChange}
        setRowSelection={setRowSelection}
        orgModalOnOpen={usersModalDisclosure.onOpen}
      />
      <Flex
        w="full"
        minH="50px"
        bg="custom.purple.light.100"
        justify="center"
        align="center"
        borderWidth="1"
        borderStyle="solid"
        borderColor="custom.purple.light.200"
        color="custom.purple.200"
      >
        <Text textStyle="textBold" textTransform="uppercase">
          Пользователи
        </Text>
      </Flex>
      <AdminTableUsers
        data={tableData}
        rowSelection={rowSelection}
        handleRowSelection={setRowSelection}
        hasNextPage={hasNextPage}
        onNextPage={fetchNextPage}
        handleEditOrg={handleEditOrg}
        handleRemoveOrg={handleRemoveOrg}
      />
      {/* <OrgModal org={editUser} isOpen={usersModalDisclosure.isOpen} onClose={handleOrgCloseModal} />
      <RemoveOrgModal
        toBeRemovedOrg={toBeRemovedOrg}
        isOpen={removeUserDisclosure.isOpen}
        onClose={removeUserDisclosure.onClose}
      /> */}
    </Page>
  );
};
