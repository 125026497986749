import {
  IconButtonProps,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  IconButton,
  ModalBody,
  Button,
  Modal,
  Flex,
  Text,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { Form } from "react-final-form";
import { read, utils, write } from "xlsx";
import drop from "lodash/drop";
import { useState } from "react";
import { uniq } from "lodash";
import { useAtom, useAtomValue } from "jotai";

import { FilePicker } from "shared/ui/file-picker";
import { CloudIcon, CrossIcon } from "shared/ui/icons";
import { SelectOrgField } from "features/dictionaries/components/fields/select-org-field";
import { useUploadOblbuhMutation } from "features/users/api";
import { toast } from "shared/ui/toast";
import { creatingReport } from "features/users/atoms";
import { readOnlyModeAtom } from "features/layout/atoms";

import { FormPayload, XlsData, XlsDataError } from "./types";
import { validate, validateUser } from "./validations";
import { fixPhone, getParseErrorMessage } from "./helpers";
import { HEADER_AND_INDEX_OFFSET, TABLE_HEADER } from "./constants";

export function ImportUsersModalIconButton(props: Omit<IconButtonProps, "aria-label">) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [xlsParseErrors, setXlsParseErrors] = useState<XlsDataError[]>([]);
  const [hasDublicatesPhones, setHasDublicatesPhones] = useState<boolean>(false);
  const [isValidatingFile, setIsValidatingFile] = useState<boolean>(false);
  const [report, setReport] = useAtom(creatingReport);
  const readOnlyMode = useAtomValue(readOnlyModeAtom);

  const { mutateAsync: uploadOblbuh } = useUploadOblbuhMutation();

  const handleCancelOnClick = () => {
    onClose();
    setXlsParseErrors([]);
    setHasDublicatesPhones(false);
    setIsValidatingFile(false);
    setReport({ filename: "", isFetching: false });
  };

  return (
    <>
      <IconButton
        variant="rounded"
        size="sm"
        icon={<CloudIcon color="white" />}
        aria-label="Импорт"
        title="Импорт"
        {...props}
        onClick={onOpen}
        {...(readOnlyMode && { isDisabled: readOnlyMode })}
      />
      <Form<FormPayload>
        validate={validate}
        onSubmit={async (values) => {
          setXlsParseErrors([]);
          setHasDublicatesPhones(false);
          setIsValidatingFile(true);
          const { file, org } = values;
          if (file) {
            const f = file[0];
            const fileData = await f.arrayBuffer();
            const workbook = read(fileData, { cellDates: true, raw: true });
            const jsonArr = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
              header: 1,
              blankrows: false,
            }) as XlsData[];

            // отрезаем шапку, оставляем только данные
            const rawData = drop(jsonArr);

            let errLocal: XlsDataError[] = [];

            const fixedData = rawData.map((user, index) => {
              const staffOrder = user[0];
              const attractedOrder = user[1];
              const username = user[2];
              const birthDate = user[3];
              const phone = user[4];

              const err = validateUser(user, index + HEADER_AND_INDEX_OFFSET, rawData);
              setXlsParseErrors((prev) => [...prev, err]);
              errLocal.push(err);

              return [staffOrder, attractedOrder, username, birthDate, fixPhone(phone)];
            });

            setXlsParseErrors((prev) => prev.filter(Boolean));
            errLocal = errLocal.filter(Boolean);
            const noErrors = errLocal.length === 0;

            const allPhones = fixedData.map((data) => data[4]);

            const allUniquePhones = uniq(allPhones);

            const hasDublicates = allPhones.length !== allUniquePhones.length;

            if (hasDublicates) {
              setHasDublicatesPhones(true);
            }

            const validData = fixedData.filter((user) => Boolean(user[4]) && Boolean(user[0]));

            if (rawData.length === validData.length && noErrors && org.oiv_id && !hasDublicates) {
              validData.unshift(TABLE_HEADER);

              const newWorkbook = utils.book_new();
              const newWorksheet = utils.aoa_to_sheet(validData);
              newWorkbook.SheetNames.push(workbook.SheetNames[0]);
              newWorkbook.Sheets[workbook.SheetNames[0]] = newWorksheet;

              const xlsxBlob = new Blob([new Uint8Array(write(newWorkbook, { bookType: "xlsx", type: "array" }))], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });

              const formData = new FormData();
              formData.append("file", xlsxBlob, f.name);

              const payload = {
                org_id: org.value,
                formData: formData,
              };

              uploadOblbuh(payload, {
                onSuccess: () => {
                  setXlsParseErrors([]);
                  setHasDublicatesPhones(false);
                  setIsValidatingFile(false);
                  onClose();
                },
                onError: () => {
                  setReport({ filename: "", isFetching: false });
                  toast.error("Ошибка при загрузке файла");
                },
              });
            } else {
              toast.error("Найдены ошибки в файле");
            }
          }
          setIsValidatingFile(false);
        }}
        render={({ handleSubmit, form }) => {
          return (
            <Modal
              isOpen={isOpen}
              onClose={
                report.isFetching
                  ? () => {
                      return;
                    }
                  : onClose
              }
            >
              <ModalOverlay />
              <ModalContent py="30px" px="50px" minW="540px" gap="20px">
                <ModalBody p="unset">
                  <Flex flexDir="column" align="center" textTransform="uppercase" gap="20px">
                    <Text textAlign="center" color="custom.grey.dark.100" textStyle="h1">
                      Импорт списков
                    </Text>
                    <SelectOrgField name="org" selectProps={{ isMulti: false }} />
                    <FilePicker name="file" />
                    {isValidatingFile || report.isFetching ? (
                      <Flex w="100%" justifyContent="center" justifyItems="center" gap="8px">
                        <Spinner size="xs" color="custom.purple.200" />
                        {isValidatingFile ? (
                          <Text color="custom.purple.200" textStyle="description" textTransform="none">
                            Проверка файла
                          </Text>
                        ) : null}
                        {report.isFetching ? (
                          <Text color="custom.purple.200" textStyle="description" textTransform="none">
                            Формирование отчета
                          </Text>
                        ) : null}
                      </Flex>
                    ) : null}
                    {xlsParseErrors.length > 0 || hasDublicatesPhones ? (
                      <Box w="100%" maxH="160px" overflowY="auto">
                        <Text color="custom.red.100" textStyle="description" textTransform="none">
                          Найдены ошибки в файле. Для загрузки файла их необходимо исправить:
                        </Text>
                        {hasDublicatesPhones ? (
                          <Text color="custom.red.100" textStyle="description" textTransform="none" py="2px">
                            В файле имеются повторяющиеся телефоны
                          </Text>
                        ) : null}
                        {xlsParseErrors.map((err, index) => (
                          <Text
                            key={index}
                            color="custom.red.100"
                            textStyle="description"
                            textTransform="none"
                            py="2px"
                          >
                            {getParseErrorMessage(err)}
                          </Text>
                        ))}
                      </Box>
                    ) : null}
                  </Flex>
                </ModalBody>

                <ModalFooter p="unset">
                  <Flex
                    w="full"
                    justifyContent="center"
                    gap="20px"
                    bgColor="custom.grey.light.100"
                    py="20px"
                    px="30px"
                    borderTopWidth={0}
                    borderRadius={15}
                  >
                    <Button
                      minW="165px"
                      leftIcon={<CloudIcon />}
                      isDisabled={isValidatingFile || report.isFetching}
                      onClick={handleSubmit}
                    >
                      Импорт
                    </Button>
                    <Button
                      minW="165px"
                      leftIcon={<CrossIcon />}
                      isDisabled={isValidatingFile || report.isFetching}
                      onClick={() => {
                        handleCancelOnClick();
                        form.reset();
                      }}
                    >
                      Отмена
                    </Button>
                  </Flex>
                </ModalFooter>
              </ModalContent>
            </Modal>
          );
        }}
      />
    </>
  );
}
