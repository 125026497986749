import { z } from "zod";
import merge from "lodash/merge";

const recursiveError = (arr: Array<string | number>, lastError: string[] | object): object => {
  if (!arr || arr.length === 0) {
    return lastError;
  }
  const [currPath, ...iss] = arr;
  let newErr;
  if (typeof currPath === "string") {
    newErr = { [currPath]: lastError };
  } else {
    const newArr = [];
    newArr[currPath] = lastError;
    newErr = newArr;
  }
  return recursiveError(iss, newErr);
};

const makeStructure = (issue: z.ZodIssue) => {
  if (issue.path.length === 1) {
    return { [issue.path[0]]: issue.message };
  }
  const [deepestPath, ...rest] = [...issue.path].reverse();
  if (typeof deepestPath === "string") {
    return recursiveError(rest, { [deepestPath]: issue.message });
  } else {
    const arr = [];
    arr[deepestPath] = issue.message;
    return recursiveError(rest, arr);
  }
};

export const formValidator =
  <I, O = I>(schema: z.ZodType<I, z.ZodTypeDef, O>, params?: Partial<z.ParseParams>) =>
  async (values: O) => {
    const result = await schema.safeParseAsync(values, params);
    return result.success
      ? ({} as Record<string, string>)
      : result.error.errors.reduce<{ [key in keyof I]?: string }>((tot, e) => {
          const structire = makeStructure(e);
          return merge(tot, structire);
        }, {});
  };
