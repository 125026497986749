import { ModalOverlay, ModalContent, ModalFooter, ModalBody, Button, Modal, Flex, Text } from "@chakra-ui/react";

import { CheckIcon, CrossIcon } from "shared/ui/icons";

type Props = {
  isLoadingSubmit?: boolean;
  isDisabledSubmit?: boolean;
  isOpen: boolean;
  titleText: string;
  descText: string | React.ReactNode;
  submitText: string;
  cancelText: string;
  onClose: () => void;
  handleSubmit: () => void;
  handleCancel?: () => void;
};

export const AlertModal = ({
  isOpen,
  isLoadingSubmit,
  isDisabledSubmit,
  titleText,
  descText,
  submitText,
  cancelText,
  onClose,
  handleSubmit,
  handleCancel,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent py="30px" px="50px" minW="540px" gap="20px">
        <ModalBody p="unset">
          <Flex flexDir="column" align="center" textTransform="uppercase" gap="20px">
            <Text textAlign="center" color="custom.grey.dark.100" textStyle="h1">
              {titleText}
            </Text>
            <Text textAlign="center" color="custom.grey.dark.100" textStyle="textLight">
              {descText}
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter p="unset">
          <Flex
            w="full"
            justifyContent="center"
            gap="20px"
            bgColor="custom.grey.light.100"
            py="20px"
            px="30px"
            borderTopWidth={0}
            borderRadius={15}
          >
            <Button
              minW="165px"
              leftIcon={<CheckIcon />}
              onClick={handleSubmit}
              isDisabled={isDisabledSubmit}
              isLoading={isLoadingSubmit}
            >
              {submitText}
            </Button>
            <Button
              minW="165px"
              isDisabled={isLoadingSubmit}
              leftIcon={<CrossIcon />}
              onClick={handleCancel || onClose}
            >
              {cancelText}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
