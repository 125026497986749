import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const RegistrationIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#9C8717" rx="2" />
      <path
        fill="#FAFAFF"
        d="m3.789 6.461 2.916-2.916a.417.417 0 0 1 .59 0L10.21 6.46a.416.416 0 0 1 .122.295v3.994a.417.417 0 0 1-.416.417H7.833V8.25H6.167v2.917H4.083a.417.417 0 0 1-.416-.417V6.756c0-.11.044-.217.122-.295Z"
      />
    </Icon>
  );
};
