import {
  QueryClient,
  UseInfiniteQueryOptions,
  UseMutateAsyncFunction,
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import ReconnectingWebSocket from "reconnecting-websocket";

import { QueryError } from "api/types";
import { DICT_QUERY_KEYS } from "features/dictionaries/api/keys";
import { DICT_ENTITY_NAMES } from "features/dictionaries/api";
import { toast } from "shared/ui/toast";

import { OblbuhParams, OblbuhQuery } from "./types";
import { OBLBUH_QUERY_KEYS } from "./keys";
import { getOblbuh } from "./api";

export function useOblbuhQuery<T = OblbuhQuery>(
  params: OblbuhParams,
  options: UseQueryOptions<OblbuhQuery, QueryError, T> = {},
) {
  return useQuery<OblbuhQuery, QueryError, T>([OBLBUH_QUERY_KEYS.OBLBUH, params], () => getOblbuh(params), {
    keepPreviousData: true,
    ...options,
  });
}

export function useOblbuhInfiniteQuery(
  params: OblbuhParams,
  options: UseInfiniteQueryOptions<OblbuhQuery, QueryError> = {},
) {
  return useInfiniteQuery<OblbuhQuery, QueryError>(
    [OBLBUH_QUERY_KEYS.OBLBUH_INFINITE, params],
    async ({ pageParam = 0 }) => getOblbuh({ ...params, offset: pageParam * params.limit }),
    {
      keepPreviousData: true,
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        const allCount = allPages.flatMap(({ data }) => data).length;
        if (lastPage.count > allCount) return allPages.length;
        return false;
      },
    },
  );
}

const LS_MGS_IDS_KEY = "msg_ids";

export const connectWS = ({
  token,
  queryClient,
  setReport,
  setIsSyncing,
  downloadOblbuhXls,
  signOut,
}: {
  token: string;
  queryClient: QueryClient;
  setReport: ({ filename, isFetching }: { filename: string; isFetching: boolean }) => void;
  setIsSyncing: ({ id, sync }: { id: string; sync: boolean }) => void;
  downloadOblbuhXls?: UseMutateAsyncFunction<string | Blob, QueryError, void, unknown>;
  signOut: () => void;
}) => {
  const wsBaseUrl = process.env.REACT_APP_BASE_WS_URL;

  const websocket = new ReconnectingWebSocket(`${wsBaseUrl}/${token}`, [], { maxRetries: 0 });

  websocket.onopen = () => {
    heartbeat();
    console.info("[open] Соединение установлено");
  };

  websocket.onmessage = (event) => {
    const data = JSON.parse(event.data);

    if (data.msg === "updated oblbuh") {
      setIsSyncing({ id: "0", sync: false });
      queryClient.invalidateQueries([OBLBUH_QUERY_KEYS.OBLBUH_INFINITE]);
      queryClient.invalidateQueries([OBLBUH_QUERY_KEYS.OBLBUH]);
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.ORG}`]);
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.OIV}`]);
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.SPHERE}`]);
      toast.success("Запись успешно изменена");
    }
    if (data.msg === "posted oblbuh") {
      setIsSyncing({ id: "0", sync: false });
      queryClient.invalidateQueries([OBLBUH_QUERY_KEYS.OBLBUH_INFINITE]);
      queryClient.invalidateQueries([OBLBUH_QUERY_KEYS.OBLBUH]);
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.ORG}`]);
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.OIV}`]);
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.SPHERE}`]);
      toast.success("Запись успешно создана");
    }
    if (data.msg === "deleted oblbuh") {
      setIsSyncing({ id: "0", sync: false });
      queryClient.invalidateQueries([OBLBUH_QUERY_KEYS.OBLBUH_INFINITE]);
      queryClient.invalidateQueries([OBLBUH_QUERY_KEYS.OBLBUH]);
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.ORG}`]);
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.OIV}`]);
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.SPHERE}`]);
    }
    if (data.msg === "updated dicts") {
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.ORG}`]);
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.OIV}`]);
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${DICT_ENTITY_NAMES.SPHERE}`]);
    }
    if (data.msg === "updated oblbuh status") {
      queryClient.invalidateQueries([OBLBUH_QUERY_KEYS.OBLBUH_INFINITE]);
      queryClient.invalidateQueries([OBLBUH_QUERY_KEYS.OBLBUH]);
    }
    if (data.msg === "insert batch") {
      downloadOblbuhXls?.(undefined, {
        onSuccess: () => {
          toast.info("Формирование отчета завершено");
        },
        onError: (error) => {
          console.log({ mgs: "download oblbuh report", error });
        },
        onSettled: () => {
          setReport({ filename: "", isFetching: false });
        },
      });
    }

    // "обзвон" сообщениями сотрудников
    // показываем сообщения только тем, у кого msg_id нет в localStorage
    if (data.msg_id) {
      const msgId = data.msg_id as string;
      const msg = data.msg as string;
      const msg_ids = localStorage.getItem(LS_MGS_IDS_KEY);

      if (msg_ids === null) {
        localStorage.setItem(LS_MGS_IDS_KEY, JSON.stringify([msgId]));
        toast.info(msg, { duration: Infinity });
      } else {
        const parsed_mgs_ids = JSON.parse(msg_ids);
        if (!parsed_mgs_ids.includes(msgId)) {
          toast.info(msg, { duration: Infinity });
        }
      }
    }

    if (data.msg === "unauthorized, check login") {
      signOut();
    }
    if (
      data.msg === "error post oblbuh" ||
      data.msg === "error update oblbuh" ||
      data.msg === "error check status" ||
      data.msg === "error delete oblbuh"
    ) {
      toast.error("Системная ошибка");
    }
  };

  websocket.onclose = (event) => {
    if (event.wasClean) {
      console.info(`[close] Соединение закрыто, код=${event.code} причина=${event.reason}`);
    } else {
      // например, сервер убил процесс или сеть недоступна
      // обычно в этом случае event.code 1006
      console.info("[close] Соединение прервано");
    }
  };

  websocket.onerror = (error) => {
    console.error([error]);
  };

  const heartbeat = () => {
    if (!websocket) return;
    if (websocket.readyState !== 1) return;
    websocket.send("heartbeat");
    setTimeout(heartbeat, process.env.REACT_APP_WS_HEARTBEAT_DELAY ? +process.env.REACT_APP_WS_HEARTBEAT_DELAY : 30000);
  };

  return () => {
    websocket.close();
  };
};
