import { PatternFormat, PatternFormatProps } from "react-number-format";
import { forwardRef } from "react";

import { Input, InputProps } from "../input/input";

export interface MaskInputProps
  extends Omit<InputProps, "type" | "defaultValue" | "onChange" | "color" | "width" | "height">,
    Omit<PatternFormatProps, "size" | "value" | "format" | "height" | "color" | "width"> {
  value?: string;
  inputFormat: PatternFormatProps["format"];
  isClearable?: boolean;
}

export const MaskInput = forwardRef<HTMLInputElement, MaskInputProps>(
  ({ value, defaultValue, inputFormat, type, onChange, ...props }, ref) => {
    return (
      <PatternFormat
        {...props}
        format={inputFormat}
        getInputRef={ref}
        type={type}
        defaultValue={defaultValue}
        customInput={Input}
        value={value}
        onChange={onChange}
      />
    );
  },
);
MaskInput.displayName = "MaskInput";
