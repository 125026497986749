import { HeaderContext, RowData } from "@tanstack/react-table";
import { Flex, FlexProps, Text } from "@chakra-ui/react";

import { PercentIndicator, PercentIndicatorProps } from "shared/ui/percent-indicator";

interface Props<TData extends RowData, TValue = unknown, TMeta = Record<string, unknown>> extends FlexProps {
  cell: HeaderContext<TData, TValue>;
  type?: PercentIndicatorProps["type"];
  metaKey: keyof TMeta;
  isPercent?: boolean;
}

export function StringWithPercentagesFooter<
  TData extends RowData = unknown,
  TValue = unknown,
  TMeta = Record<string, unknown>,
>({ cell, type = "less-better", metaKey, isPercent = true }: Props<TData, TValue, TMeta>) {
  const columnId = cell.column.id as keyof TMeta;
  const percentIndicatorValue = Number((cell.table.options.meta as TMeta)?.[columnId] ?? 0);
  const value = Number((cell.table.options.meta as TMeta)?.[metaKey] ?? 0);

  return (
    <Flex position="relative" gap="10px" height="100%" alignItems="center" justifyContent="center" w="full">
      <Text textStyle="textBold" color="custom.blue.dark.100" ml="-30px">
        {value}
      </Text>
      {!isPercent ? null : <PercentIndicator value={percentIndicatorValue} type={type} />}
    </Flex>
  );
}
