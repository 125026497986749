import { FC } from "react";
import { Flex, FormLabel, Icon, IconProps, Input } from "@chakra-ui/react";

export type SwitcherProps = {
  name: string;
  iconColor: string;
  trueIcon: FC<IconProps>;
  falseIcon: FC<IconProps>;
  trueTip?: string;
  falseTip?: string;
  value: boolean;
  isDisabled?: boolean;
  onChange: (v: boolean) => void;
};

export const Switcher = ({
  name,
  iconColor,
  trueIcon,
  falseIcon,
  trueTip = "",
  falseTip = "",
  value = false,
  isDisabled,
  onChange,
}: SwitcherProps) => {
  return (
    <FormLabel
      htmlFor={`${name}-switcher`}
      as={"label"}
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={2}
      position="relative"
      title={value ? trueTip : falseTip}
    >
      <Input
        id={`${name}-switcher`}
        type="checkbox"
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        isDisabled={isDisabled}
        position="absolute"
        display="inline-block"
        appearance="none"
        cursor="pointer"
        height="32px"
        width="50px"
        border="0.5px solid"
        borderColor="custom.purple.100"
        borderRadius="full"
        boxShadow="shadows.inner"
        zIndex={1}
      />
      <Flex
        transition="all 0.2s ease-in"
        transform={value ? "translateX(18px)" : "translateX(0)"}
        position="absolute"
        cursor="pointer"
        top={"1px"}
        left={"1px"}
        w={"30px"}
        h={"30px"}
        bg="white"
        borderRadius="full"
        justifyContent="center"
        alignItems="center"
      >
        <Icon as={value ? trueIcon : falseIcon} color={iconColor} w={"20px"} h={"20px"} />
      </Flex>
      <Flex height="32px" width="50px" borderRadius="full" bg="custom.purple.100" />
    </FormLabel>
  );
};
