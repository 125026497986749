import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { Center, Text } from "@chakra-ui/react";

export function ErrorPage() {
  const error = useRouteError();
  return (
    <Center pos="fixed" top="0" bottom="0" left="0" right="0">
      <Text>{isRouteErrorResponse(error) ? error.status : "404"}</Text>
    </Center>
  );
}
