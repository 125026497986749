import { CellContext, RowData } from "@tanstack/react-table";
import { Text, TextProps } from "@chakra-ui/react";

interface Props<TData extends RowData, TValue = unknown> extends TextProps, CellContext<TData, TValue> {}

export function StringCell<TData extends RowData, TValue = unknown>({
  table,
  column,
  row,
  cell,
  getValue,
  renderValue,
  ...args
}: Props<TData, TValue>) {
  if (!row || !cell || !table) return undefined;
  const value = getValue<string>();
  return (
    <Text textStyle="textLight" color="custom.blue.dark.100" noOfLines={2} title={value} {...args}>
      {value}
    </Text>
  );
}
