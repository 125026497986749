import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const NoEyeOutlinedIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M18.403 9.496c-.687-1.447-1.504-2.626-2.451-3.54l-.994.995c.81.774 1.516 1.787 2.126 3.049-1.624 3.363-3.931 4.96-7.083 4.96-.947 0-1.818-.145-2.615-.436L6.309 15.6c1.107.511 2.337.767 3.692.767 3.753 0 6.554-1.955 8.402-5.865a1.177 1.177 0 0 0 0-1.006ZM17.16 3.233 16.33 2.4a.156.156 0 0 0-.221 0L13.97 4.535c-1.177-.602-2.501-.902-3.97-.902-3.754 0-6.555 1.955-8.403 5.865a1.178 1.178 0 0 0 0 1.006c.738 1.554 1.627 2.8 2.666 3.738l-2.067 2.066a.156.156 0 0 0 0 .221l.834.834a.156.156 0 0 0 .221 0l13.91-13.908a.156.156 0 0 0 0-.222ZM2.916 10c1.627-3.363 3.934-4.961 7.085-4.961 1.065 0 2.033.183 2.912.554L11.54 6.966a3.437 3.437 0 0 0-4.651 4.651l-1.63 1.63C4.359 12.45 3.582 11.373 2.917 10Zm4.819 0a2.19 2.19 0 0 1 2.855-2.084L7.84 10.668A2.188 2.188 0 0 1 7.735 10Z"
      />
      <path
        fill="currentColor"
        d="M9.922 12.187c-.067 0-.134-.003-.2-.009L8.69 13.21a3.44 3.44 0 0 0 4.442-4.442L12.101 9.8a2.208 2.208 0 0 1-.157 1.037 2.185 2.185 0 0 1-2.022 1.35Z"
      />
    </Icon>
  );
};
