import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const DegReadyIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#528661" rx="2" />
      <path
        fill="#fff"
        d="m5.807 8.126 3.52-3.52a.566.566 0 0 1 .417-.163c.169 0 .308.055.417.164.11.11.164.248.164.417a.565.565 0 0 1-.164.418L6.225 9.378a.572.572 0 0 1-.418.18.572.572 0 0 1-.417-.18l-1.55-1.55a.566.566 0 0 1-.165-.418c0-.169.055-.308.164-.417a.566.566 0 0 1 .418-.164c.169 0 .308.054.417.164l1.133 1.133Z"
      />
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M3.675 4.443h6.65v5.114h-6.65z" />
        </clipPath>
      </defs>
    </Icon>
  );
};
