export enum STORAGE_KEY {
  ACCESS = "access",
}

type STORAGE_KEY_STRING = keyof typeof STORAGE_KEY;
const getKeyWithPrefix = (key: STORAGE_KEY_STRING) => `skzk_${STORAGE_KEY[key]}`;

export function setLocalStorageData<T = Record<string, unknown>>(key: STORAGE_KEY_STRING, data: T) {
  const storageKey = getKeyWithPrefix(key);
  const dataString = JSON.stringify(data);
  localStorage.setItem(storageKey, dataString);
}

export function removeLocalStorageData(key?: STORAGE_KEY_STRING) {
  if (!key) return localStorage.clear();
  const storageKey = getKeyWithPrefix(key);
  localStorage.removeItem(storageKey);
}

export function getLocalStorageData<T = Record<string, unknown>>(key: STORAGE_KEY_STRING) {
  const storageKey = getKeyWithPrefix(key);
  const storageData = localStorage.getItem(storageKey);
  if (!storageData || storageData === "undefined") return null;

  return <T>JSON.parse(storageData);
}
