import { FlexProps, AccordionButton, Flex, Text, Checkbox, CheckboxProps, IconButton } from "@chakra-ui/react";
import { useAtomValue } from "jotai";

import { PlusIcon } from "shared/ui/icons";
import { readOnlyModeAtom } from "features/layout/atoms";
import { CurrentValues } from "features/users/helpers";

import { AccordionIcon } from "./icon";
import { AccordionDescription } from "./descpirtion";

interface Props extends FlexProps {
  title: string;
  isExpanded: boolean;
  isOrganisation?: boolean;
  orgId?: number;
  lastItem?: boolean;
  summary: CurrentValues;
  checkboxProps?: CheckboxProps;
  handleClickAddButton?: ({ title, orgId }: { title: string; orgId: number }) => void;
}

export const CustomAccordionButton = ({
  title,
  isExpanded,
  isOrganisation,
  summary,
  orgId,
  lastItem,
  handleClickAddButton,
  checkboxProps,
  ...flexProps
}: Props) => {
  const readOnlyMode = useAtomValue(readOnlyModeAtom);

  return (
    <Flex
      bgColor="custom.purple.light.100"
      alignItems="center"
      borderBottomColor="custom.purple.200"
      borderBottomWidth={!lastItem ? 1 : 0}
      borderBottomStyle="solid"
    >
      {isOrganisation ? <Checkbox ml="16px" {...checkboxProps} isReadOnly={summary.cnt_all === 0} /> : null}
      <AccordionButton
        bgColor="custom.purple.light.100"
        borderBottomWidth={1}
        borderBottomColor="transparent"
        borderBottomStyle="solid"
        justifyContent="flex-start"
      >
        <Flex flexDir="column" flexGrow={1} alignItems="flex-start" gap="5px" {...flexProps}>
          <Text textStyle="textBold" textTransform="uppercase" color="custom.purple.200">
            {title}
          </Text>
          <AccordionDescription
            total={summary.cnt_all}
            workers={summary.staff}
            attracted={summary.relative}
            arch_total={summary.arch_cnt_all}
            arch_workers={summary.arch_staff}
            arch_attracted={summary.arch_attracted}
          />
        </Flex>
        {isOrganisation ? (
          <IconButton
            variant="rounded-light"
            size="sm"
            icon={<PlusIcon color="white" />}
            aria-label="Добавить сотрудника"
            title="Добавить сотрудника"
            mr="4px"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (handleClickAddButton && title && orgId) {
                handleClickAddButton({ title, orgId });
              }
            }}
            sx={{
              backgroundColor: "custom.purple.light",
            }}
            {...(readOnlyMode && { isDisabled: readOnlyMode })}
          />
        ) : null}
        <AccordionIcon isExpanded={isExpanded} />
      </AccordionButton>
    </Flex>
  );
};
