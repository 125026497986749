import React, { useCallback, useState } from "react";
import { Row, RowSelectionState } from "@tanstack/react-table";
import { Flex, Text, useDisclosure } from "@chakra-ui/react";

import { Page } from "shared/ui/layout/page";
import { DictAdminData, useDictSphereOivOrgInfiniteQuery } from "features/dictionaries/api";

import { AdminParams } from "../api";
import { FilterStatsPanel } from "../components/filter-panel";
import { AdminTable } from "../components/table";
import { RemoveOrgModal } from "../components/modal-delete-orgs";
import { OrgModal } from "../components/org-modal";

export type Filter = Pick<AdminParams, "oiv_ids" | "org_ids" | "sphere_ids">;

export type EditOrgProps = {
  org_id: number;
  org?: string;
  sphere_id?: number;
  sphere?: string;
  oiv_id?: number;
  oiv?: string;
  inn?: string;
};

export type ToBeRemovedOrgProps = {
  org_id: number;
  org?: string;
};

export const OrgsAdminPage = () => {
  const orgModalDisclosure = useDisclosure();
  const removeOrgDisclosure = useDisclosure();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [filter, setFilter] = React.useState<Filter>({});

  const handleOnFiltersChange = (v: Filter) => {
    setFilter(v);
  };

  const [editOrg, setEditOrg] = useState<EditOrgProps>();
  const [toBeRemovedOrg, setToBeRemovedOrg] = useState<ToBeRemovedOrgProps>();

  const handleEditOrg = useCallback(
    (row: Row<DictAdminData>) => {
      if (row.original.org_id) {
        setEditOrg({
          org_id: row.original.org_id,
          org: row.original.org,
          oiv_id: row.original.oiv_id,
          oiv: row.original.oiv,
          sphere_id: row.original.sphere_id,
          sphere: row.original.sphere,
          inn: row.original.inn,
        });
        orgModalDisclosure.onOpen();
      }
    },
    [orgModalDisclosure],
  );

  const handleRemoveOrg = useCallback(
    (row: Row<DictAdminData>) => {
      if (row.original.org_id) {
        setToBeRemovedOrg({
          org_id: row.original.org_id,
          org: row.original.org,
        });
        removeOrgDisclosure.onOpen();
      }
    },
    [removeOrgDisclosure, setToBeRemovedOrg],
  );

  const handleOrgCloseModal = () => {
    setEditOrg(undefined);
    orgModalDisclosure.onClose();
  };

  const {
    data: stat,
    fetchNextPage,
    hasNextPage,
  } = useDictSphereOivOrgInfiniteQuery({
    ...filter,
    limit: 100,
    order_by: "org",
    order_type: "asc",
  });

  const tableData = stat?.pages.flatMap((page) => page.data);

  return (
    <Page>
      <FilterStatsPanel
        filter={filter}
        rowSelection={rowSelection}
        onFilterChange={handleOnFiltersChange}
        setRowSelection={setRowSelection}
        orgModalOnOpen={orgModalDisclosure.onOpen}
      />
      <Flex
        w="full"
        minH="50px"
        bg="custom.purple.light.100"
        justify="center"
        align="center"
        borderWidth="1"
        borderStyle="solid"
        borderColor="custom.purple.light.200"
        color="custom.purple.200"
      >
        <Text textStyle="textBold" textTransform="uppercase">
          Организации
        </Text>
      </Flex>
      <AdminTable
        data={tableData}
        rowSelection={rowSelection}
        handleRowSelection={setRowSelection}
        hasNextPage={hasNextPage}
        onNextPage={fetchNextPage}
        handleEditOrg={handleEditOrg}
        handleRemoveOrg={handleRemoveOrg}
      />
      <OrgModal org={editOrg} isOpen={orgModalDisclosure.isOpen} onClose={handleOrgCloseModal} />
      <RemoveOrgModal
        toBeRemovedOrg={toBeRemovedOrg}
        isOpen={removeOrgDisclosure.isOpen}
        onClose={removeOrgDisclosure.onClose}
      />
    </Page>
  );
};
