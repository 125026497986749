import { ComponentStyleConfig } from "@chakra-ui/react";

import { textStyles } from "../text-styles";

export const Checkbox: ComponentStyleConfig = {
  parts: ["container", "icon", "label", "control"],
  baseStyle: {
    container: {
      alignItems: "flex-start",
      _disabled: {
        cursor: "not-allowed",
      },
    },
    control: {
      borderColor: "custom.purple.200",
      borderRadius: "4px",
      borderWidth: "1px",
      bgColor: "white",
      _focusVisible: {
        borderColor: "custom.purple.200",
        boxShadow: "0px 0px 0px 1px var(--chakra-shadows-shadows-focusInput)",
        outlineColor: "custom.purple.200",
        outlineWidth: "1px",
        outlineStyle: "solid",
      },
      _checked: {
        backgroundColor: "white",
        color: "custom.purple.200",
        _disabled: {
          backgroundColor: "white",
        },
        _hover: {
          backgroundColor: "white",
        },
      },
      _indeterminate: {
        backgroundColor: "white",
        color: "custom.purple.200",
      },
    },
    icon: {
      color: "custom.purple.200",
    },
  },
  variants: {
    default: {
      label: {
        ...textStyles.textLight,
      },
    },
  },
  sizes: {
    md: {
      control: {
        w: "20px",
        h: "20px",
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "default",
    colorScheme: "brand",
  },
};
