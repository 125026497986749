import { authInstance } from "api/client";

import { SignInParams, SignInQuery } from "./types";

export const signIn = async (params: SignInParams) => {
  const url = "/login";
  const { data } = await authInstance.post<SignInQuery>(url, params, {
    skipAuthRefresh: true,
  });

  return data.result;
};

export const signOut = async () => {
  const url = "/logout";
  const { data } = await authInstance.post(url);

  return data.result;
};
