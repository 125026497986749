import { AccordionItem, AccordionItemProps } from "@chakra-ui/react";

import { DictStatData } from "features/dictionaries/api";
import { getCurrentValues } from "features/users/helpers";

import { UserFilter } from "../filter-panel/popover";

import { OivAccordionPanel } from "./oiv-accordion-panel";
import { CustomAccordionButton } from "./button";

interface SphereAccordionItemProps extends AccordionItemProps {
  sphere: DictStatData;
  userFilter: UserFilter;
  lastItem: boolean;
  handleClickAddButton: ({ title, orgId }: { title: string; orgId: number }) => void;
}

export function SphereAccordionItem({
  userFilter,
  sphere,
  handleClickAddButton,
  lastItem,
  ...args
}: SphereAccordionItemProps) {
  const currentValues = getCurrentValues({ filter: userFilter, values: sphere });
  return (
    <AccordionItem {...args}>
      {({ isExpanded }) => (
        <>
          <CustomAccordionButton
            title={`Уровень 1: ${sphere.sphere}`}
            summary={currentValues}
            isExpanded={isExpanded}
            lastItem={lastItem}
          />
          <OivAccordionPanel
            isExpanded={isExpanded}
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            sphereId={sphere.sphere_id}
            userFilter={userFilter}
            handleClickAddButton={handleClickAddButton}
          />
        </>
      )}
    </AccordionItem>
  );
}
