import { ReactElement } from "react";
import { forwardRef, IconProps, InputProps } from "@chakra-ui/react";
import { useField, UseFieldConfig } from "react-final-form";
import { PatternFormatProps } from "react-number-format";

import { FormControl, FormControlProps } from "../form-control";

import { MaskInput } from "./mask-input";

export type MaskInputFieldValues = Record<string, string>;

export interface MaskInputFieldProps extends Omit<FormControlProps, "isInvalid" | "defaultValue"> {
  name: string;
  inputFormat: PatternFormatProps["format"];
  defaultValue?: PatternFormatProps["defaultValue"];
  fieldConfig?: UseFieldConfig<string, string>;
  inputProps?: InputProps;
  placeholder?: string;
  autoComplete?: string;
  type?: PatternFormatProps["type"];
  label?: string;
  icon?: ReactElement<IconProps>;
  isClearable?: boolean;
}

const identity = (value: string) => value;

export const MaskInputField = forwardRef<MaskInputFieldProps, "input">(
  (
    {
      name,
      label,
      fieldConfig,
      caption,
      placeholder,
      autoComplete,
      type,
      icon,
      inputFormat,
      defaultValue,
      inputProps,
      isClearable,
      ...props
    },
    ref,
  ) => {
    const { input, meta } = useField<string, HTMLInputElement, string>(name, {
      parse: identity,
      ...fieldConfig,
    });

    return (
      <FormControl
        label={label}
        isInvalid={meta.touched && !!meta.error && meta.submitFailed}
        caption={(meta.touched && meta.error) || caption}
        {...props}
      >
        <MaskInput
          {...inputProps}
          {...input}
          isClearable={isClearable}
          defaultValue={defaultValue}
          inputFormat={inputFormat}
          placeholder={placeholder}
          autoComplete={autoComplete}
          type={type}
          icon={icon}
          ref={ref}
        />
      </FormControl>
    );
  },
);
