/** Склонение существительных */
export function getNoun(number: number, one: string, two: string, five: string) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) return five;
  n %= 10;
  if (n === 1) return one;
  if (n >= 2 && n <= 4) return two;
  return five;
}

export function parseMaskToPhone(v = "") {
  let phone = v.trim().replace(/\D/g, "").substring(0, 11);
  if (phone.startsWith("8")) {
    phone = `7${phone.substring(1, 11)}`;
  }
  return phone;
}

export function formatPhoneToMask(v = "") {
  if (!v?.length) return "";
  const mask = v
    .replace(/[^0-9]+/, "")
    .replace(/\D/g, "")
    .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
  return (
    `+7 (${mask?.[2]}` +
    (mask?.[3] ? `) ${mask?.[3]}` : "") +
    (mask?.[4] ? `-${mask?.[4]}` : "") +
    (mask?.[5] ? `-${mask?.[5]}` : "")
  );
}

export function formatPhoneToMaskOnlyNumbers(v = "") {
  if (!v?.length) return "";
  const mask = v
    .replace(/[^0-9]+/, "")
    .replace(/\D/g, "")
    .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
  return (
    `7${mask?.[2]}` +
    (mask?.[3] ? `${mask?.[3]}` : "") +
    (mask?.[4] ? `${mask?.[4]}` : "") +
    (mask?.[5] ? `${mask?.[5]}` : "")
  );
}
