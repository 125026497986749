import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { useSetAtom } from "jotai";

import { DICT_ENTITY_NAMES, DictStatData, useDictQuery, useReadOnlyStatus } from "features/dictionaries/api";
import { useAccessStorageStore } from "shared/store/use-access-storage-store";
import { readOnlyModeAtom } from "features/layout/atoms";
import { ROLES } from "shared/constants";
import { toast } from "shared/ui/toast";

import { FilterInfo } from "./info";
import { UserFilter } from "./popover";
import { FilterActions } from "./actions";

interface Props {
  summary: Pick<DictStatData, "relative" | "staff" | "cnt_all">;
  userFilter: UserFilter;
  onUserFilterChange: (filter: UserFilter) => void;
}

const useAccessController = () => useAccessStorageStore();

export const FilterStatsPanel = ({ userFilter, onUserFilterChange, summary }: Props) => {
  const { access } = useAccessController();
  const { data: readOnlyStatus } = useReadOnlyStatus();
  const setReadOnlyMode = useSetAtom(readOnlyModeAtom);

  useEffect(() => {
    if (readOnlyStatus && access && access?.roles[0].id !== ROLES.DTOIV) {
      setReadOnlyMode(true);
    }
  }, [readOnlyStatus, setReadOnlyMode, access]);

  useEffect(() => {
    if (readOnlyStatus) {
      toast.warning("Система работает в режиме показа информации. Редактирование невозможно", {
        duration: 99999999,
        isClosable: true,
      });
    }
  }, [readOnlyStatus]);

  const { data: orgCalc = 0 } = useDictQuery(
    {
      limit: 1,
      name: DICT_ENTITY_NAMES.ORG,
      ...(!userFilter.sphere ? {} : { sphere_ids: userFilter.sphere.map((s) => Number(s.sphere_id)) }),
      ...(!userFilter.oiv ? {} : { oiv_ids: userFilter.oiv.map((s) => Number(s.oiv_id)) }),
      ...(!userFilter.org ? {} : { org_ids: userFilter.org.map((s) => Number(s.org_id)) }),
    },
    {
      select: (data) => data.count,
      enabled: !!userFilter.sphere?.length,
    },
  );

  const organizationsCount = Object.keys(userFilter).length ? orgCalc : 0;

  return (
    <Flex
      position="sticky"
      top={0}
      zIndex={2}
      bgGradient="linear(to-b, rgb(235,235,235), rgb(247,247,247))"
      minH="50px"
      px="20px"
      borderBottomWidth={1}
      borderBottomStyle="solid"
      borderBottomColor="custom.purple.100"
      alignItems="center"
      gap="6px"
    >
      <Flex flex={1}>
        <FilterInfo
          total={summary.cnt_all}
          workers={summary.staff}
          attracted={summary.relative}
          statusesCount={userFilter.statuses?.length ?? 0}
          organizationsCount={organizationsCount}
        />
      </Flex>
      <FilterActions onUserFilterChange={onUserFilterChange} userFilter={userFilter} />
    </Flex>
  );
};
