export type StatParams = {
  name: SWITCHER_STAT_ENTITY_NAMES;
  limit: number;
  offset?: number;
  order_by?: string;
  order_type?: "asc" | "desc";
  oiv?: string[];
  oiv_ids?: number[];
  org?: string[];
  org_ids?: number[];
  sphere?: string;
  sphere_ids?: number[];
};

/**
  @param msk_staff Всего (моксвичей)
  @param sphere Сфера (Уровень 1)
  @param no_cab Нет учётной записи
  @param edit Актуализировать данные
  @param cab Всего учетных записей
  @param deg ДЭГ-готовность (Готовность)
  @param oiv ОИВ
  @param org Организация (уровень 3)
  @param reestr В реестре
  @param voted Проголосовало
 */
export type StatData = {
  org_ids: string[];
  org_id: number;
  msk_staff: number;
  sphere: string;
  no_cab: number;
  no_mos: number;
  edit: number;
  cab: number;
  deg: number;
  oiv: string;
  org: string;
  reestr: number;
  voted: number;
  no_cab_perc: number;
  no_mos_perc: number;
  reestr_perc: number;
  edit_perc: number;
  voted_perc: number;
  cab_perc: number;
  deg_perc: number;
};

export type StatTotal = {
  cab: number;
  cab_: number;
  deg: number;
  deg_perc: number;
  edit: number;
  edit_perc: number;
  msk_staff: number;
  no_cab: number;
  no_mos: number;
  no_mos_perc: number;
  no_cab_perc: number;
  reestr_perc: number;
  voted_perc: number;
};

export type StatQuery = {
  data: Array<StatData>;
  count: number;
  total: StatTotal;
};

export enum STAT_ENTITY_NAMES {
  OIV = "oiv",
  OIV_RELATIVE = "oiv_relative",
  ORG = "org",
  ORG_RELATIVE = "org_relative",
  SPHERE = "sphere",
  SPHERE_RELATIVE = "sphere_relative",
  VOTE_OIV = "vote_oiv",
  VOTE_OIV_RELATIVE = "vote_oiv_relative",
  VOTE_ORG = "vote_org",
  VOTE_ORG_RELATIVE = "vote_org_relative",
  VOTE_SPHERE = "vote_sphere",
  VOTE_SPHERE_RELATIVE = "vote_sphere_relative",
}

export type STAT_ENTITY_NAMES_STRING = keyof typeof STAT_ENTITY_NAMES;

export enum SWITCHER_STAT_ENTITY_NAMES {
  OIV = STAT_ENTITY_NAMES.OIV,
  OIV_RELATIVE = STAT_ENTITY_NAMES.OIV_RELATIVE,
  ORG = STAT_ENTITY_NAMES.ORG,
  ORG_RELATIVE = STAT_ENTITY_NAMES.ORG_RELATIVE,
  SPHERE = STAT_ENTITY_NAMES.SPHERE,
  SPHERE_RELATIVE = STAT_ENTITY_NAMES.SPHERE_RELATIVE,
}

export type SWITCHER_STAT_ENTITY_NAMES_STRING = keyof typeof SWITCHER_STAT_ENTITY_NAMES;

export enum STAT_TYPE {
  EMPLOYEES = "employees", // заполненность кабинетов сотрудники
  EMPLOYEES_VOTED = "employees_voted", // период голосования сотрудники
  RELATIVE = "relative", // заполненность кабинетов доп. телефоны
  RELATIVE_VOTED = "relative_voted", // период голосования доп. телефоны
  RESOURCE = "resource", // потенциальный ресурс
}

export type EmployeeData = {
  sphere_id: number;
  oiv_id: number;
  org_id: number;
  sphere: string;
  oiv: string;
  org: string;
  all_members: number;
  potentially_resources_1: number;
  attr_1: number;
  potentially_resources_3: number;
  attr_3: number;
  attr_3_perc: number;
  attr_1_perc: number;
};

export type EmployeeTotal = {
  all_members: number;
  potentially_resources_1: number;
  attr_1: number;
  potentially_resources_3: number;
  attr_3: number;
  attr_3_perc: number;
  attr_1_perc: number;
};

export type EmployeeQuery = {
  data: Array<EmployeeData>;
  count: number;
  total: EmployeeTotal;
};
