import { Text } from "@chakra-ui/react";

interface Props {
  amount: number;
  variant?: "primary" | "secondary";
  label: React.ReactNode;
}

export function Summary({ label, amount, variant, ...args }: Props) {
  return (
    <Text
      color="custom.blue.dark.100"
      textStyle="textLight"
      whiteSpace="nowrap"
      {...(variant === "secondary" && { color: "custom.purple.200" })}
      {...args}
    >
      {label}
      <Text
        as="span"
        pl="6px"
        textStyle="textBold"
        color="custom.blue.dark.100"
        {...(variant === "secondary" && { color: "inherit", textStyle: "textLight" })}
      >
        {amount}
      </Text>
    </Text>
  );
}
