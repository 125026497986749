import { GroupBase, StylesConfig } from "react-select";
import { CSSProperties } from "react";

import { textStyles } from "config/theme/text-styles";

import { SelectOption } from "./types";

// Как стилизовать
// https://react-select.com/styles

export const customStyles = ({
  variant = "default",
}): StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> | undefined => ({
  placeholder: (base) => ({
    ...base,
    ...(textStyles.text as CSSProperties),
    color: "var(--chakra-colors-custom-purple-100)",
  }),
  control: (base) => ({
    ...base,
    borderWidth: 0,
    borderRadius: 0,
    borderBottomWidth: variant === "default" ? 1 : 0,
    boxShadow: "none",
    backgroundColor: variant === "default" ? "white" : "transparent",
  }),
  option: (base, { data }) => ({
    ...base,
    ...(textStyles.text as CSSProperties),
    background: data.color ? data.color : "white",
    borderWidth: 0,
    color: "var(--chakra-colors-custom-purple-200)",
    maxWidth: "380px",
    "&:hover": {
      background: "var(--chakra-colors-custom-purple-light-200)",
    },
  }),
  menu: ({ width, ...base }) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  }),
  menuList: (base) => ({
    ...base,
  }),
  input: (base) => ({
    ...base,
    color: "var(--chakra-colors-custom-purple-200)",
  }),
  singleValue: (base) => ({ ...base, color: "var(--chakra-colors-custom-purple-200)" }),
});
