import { UseInfiniteQueryOptions, UseQueryOptions, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { flatMap } from "lodash";

import { QueryError } from "api/types";
import { DictAdminQuery, DictAdminUserQuery } from "features/dictionaries/api";

import { AdminParams, UserAdminParams } from "./types";
import { STAT_QUERY_KEYS } from "./keys";
import { getUsers } from "./api";

// export function useUsersQuery(params: AdminParams, options: UseQueryOptions<DictAdminQuery, QueryError> = {}) {
//   return useQuery<DictAdminQuery, QueryError>([STAT_QUERY_KEYS.STAT, params], () => getUsers(params), {
//     keepPreviousData: true,
//     ...options,
//   });
// }

export function useUsersInfiniteQuery(
  params: UserAdminParams,
  options: UseInfiniteQueryOptions<DictAdminUserQuery, QueryError> = {},
) {
  return useInfiniteQuery<DictAdminUserQuery, QueryError>(
    [STAT_QUERY_KEYS.STAT_INFINITE, params],
    async ({ pageParam = 0 }) => getUsers({ ...params, offset: pageParam * params.limit }),
    {
      keepPreviousData: true,
      ...options,
      getNextPageParam: (allPages) => {
        const allCount = flatMap(allPages).length;
        return allPages.length;
      },
    },
  );
}
