import { PropsWithChildren, useState } from "react";
import { Box, Button, Center, Flex, Link, Text } from "@chakra-ui/react";
import { ValidationErrors } from "final-form";
import { Navigate } from "react-router-dom";
import { Form } from "react-final-form";

import { useAccessStorageStore } from "shared/store/use-access-storage-store";
import { routePaths } from "config/routes/routePaths";
import { useSignInMutation } from "features/auth/api";
import { PasswordField } from "shared/form/password";
import { PhoneField } from "shared/form/mask-input";

type FormPayload = {
  phone: string;
  password: string;
};

const initialValues: FormPayload = {
  phone: "",
  password: "",
};

const useAccessController = () => useAccessStorageStore();

export const LoginPage = () => {
  const isProd = process.env.NODE_ENV === "production";
  const accessController = useAccessController();
  const { mutateAsync: singIn, isLoading } = useSignInMutation();

  const [errorMessage, setErrorMessage] = useState<string>();

  const handleSubmit = async ({ password, phone }: FormPayload) => {
    singIn(
      { password, login: phone },
      {
        onError: (error) =>
          // TODO: Ожидаем ошибку => backend (error.response?.data.error)
          setErrorMessage("Проверьте правильность введенного пароля, или получите новый через телеграм-бот"),
      },
    );
  };

  const validate = async (v: FormPayload) => {
    const errors: ValidationErrors = {};
    if (v.phone?.length < 11) errors.phone = "Дозаполните номер телефона";
    if (!v.phone) errors.phone = "Заполните номер телефона";
    if (!v.password) errors.password = "Заполните пароль";
    return errors;
  };

  if (!!accessController.access) return <Navigate to={routePaths.main()} />;

  return (
    <Center pos="fixed" left="0" top="0" bottom="0" right="0" bg="custom.bg.200">
      <Form<FormPayload>
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({ values, handleSubmit }) => {
          return (
            <Box
              as="form"
              w="400px"
              px="50px"
              pt="30px"
              pb="40px"
              bgColor="white"
              boxShadow="shadows.default"
              borderRadius="10px"
              borderWidth="1px"
              borderStyle="solid"
              borderColor="custom.purple.light.200"
              onSubmit={handleSubmit}
            >
              <Flex flexDirection="column" gap="20px">
                <Text color="custom.grey.dark.100" textStyle="h1">
                  Авторизация
                </Text>
                <Flex flexDirection="column" gap="10px">
                  <PhoneField
                    name="phone"
                    label="Номер телефона"
                    inputFormat="+# (###) ### ## ##"
                    isClearable={false}
                  />
                  <PasswordField name="password" label="Пароль" />
                </Flex>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <Button w="full" type="submit" isLoading={isLoading}>
                  Войти
                </Button>
                <Text color="custom.grey.dark.100" textStyle="textLight">
                  *Срок действия пароля - сутки. Получите актуальный пароль в{" "}
                  <Link href={process.env.REACT_APP_TELEGRAM_BOT_URL} color="custom.blue.dark.100" target="_blank">
                    telegram-bot&apos;е
                  </Link>
                </Text>
              </Flex>
            </Box>
          );
        }}
      />
    </Center>
  );
};

function ErrorMessage({ children }: PropsWithChildren) {
  if (!children) return null;
  return (
    <Text
      bg="custom.yellow.light.300"
      p="10px"
      color="custom.yellow.dark.100"
      textStyle="text"
      textAlign="center"
      borderColor="custom.grey.light.100"
      borderWidth="1px"
      borderStyle="solid"
      borderRadius="5px"
    >
      {children}
    </Text>
  );
}
