import { Flex, Text } from "@chakra-ui/react";

export type PercentIndicatorProps = {
  value: number;
  type: "less-better" | "more-better";
};

export const PercentIndicator = ({ value, type }: PercentIndicatorProps) => {
  return (
    <Flex
      position="absolute"
      right="0"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      w="50px"
      h="21px"
      bgColor={getBgColor({ value, type })}
    >
      <Text textStyle="description" color="custom.blue.dark.100">
        {value}%
      </Text>
    </Flex>
  );
};

export const getBgColor = ({ value, type }: PercentIndicatorProps) => {
  const num = Math.max(0, Math.min(100, value));
  // prettier-ignore
  switch (true) {
    case num <= 15: return `progressBg.${type}.15`
    case num <= 30: return `progressBg.${type}.30`
    case num <= 46: return `progressBg.${type}.46`
    case num <= 73: return `progressBg.${type}.73`
    case num <= 85: return `progressBg.${type}.85`
    default: return `progressBg.${type}.100`
  }
};
