import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

// иконка проверки
export const VerificationIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#6D6D6D" rx="2" />
      <path
        fill="#fff"
        d="M10.063 10.113A4.4 4.4 0 1 1 11.35 7h-1.1a3.3 3.3 0 1 0-.968 2.332l.781.781ZM8.6 7H13l-2.2 2.2L8.6 7Z"
      />
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M2 1.5h11v11H2z" />
        </clipPath>
      </defs>
    </Icon>
  );
};
