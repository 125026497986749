import { IconButton } from "@chakra-ui/react";

import { BallotPaperIcon } from "shared/ui/icons";
import { Filter } from "features/stats/pages";

type Props = {
  filter: Filter;
  isActive: boolean;
  onClick: () => void;
};

export function VoteStatIconButton({ filter, isActive = false, onClick }: Props) {
  return (
    <IconButton
      variant="icon"
      size="icon"
      icon={<BallotPaperIcon color={isActive ? "white" : "custom.purple.200"} />}
      aria-label="Период голосования"
      title="Период голосования"
      onClick={onClick}
      isActive={isActive}
      _focusVisible={{ boxShadow: "0px 0px 0px 1px var(--chakra-shadows-shadows-focusInput)" }}
      _hover={{ bg: "custom.purple.light.200" }}
      _active={{
        bg: "custom.purple.200",
        borderColor: "custom.purple.200",
        borderWidth: 1,
        borderStyle: "solid",
      }}
    />
  );
}
