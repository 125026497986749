import { InputProps } from "@chakra-ui/react";
import { useField, UseFieldConfig } from "react-final-form";

import { FormControl, FormControlProps } from "../form-control";

import { PasswordInput } from "./password";

interface Props extends Omit<FormControlProps, "isInvalid"> {
  name: string;
  fieldConfig?: UseFieldConfig<string, string>;
  inputProps?: InputProps;
  placeholder?: string;
  autoComplete?: string;
  label?: string;
}

const identity = (value: string) => value;

export const PasswordField = ({ name, label, fieldConfig, caption, placeholder, autoComplete, ...props }: Props) => {
  const { input, meta } = useField<string, HTMLInputElement, string>(name, {
    parse: identity,
    ...fieldConfig,
  });

  return (
    <FormControl
      label={label}
      isInvalid={meta.touched && !!meta.error && meta.submitFailed}
      caption={(meta.touched && meta.error) || caption}
      {...props}
    >
      <PasswordInput {...props} {...input} placeholder={placeholder} autoComplete={autoComplete} />
    </FormControl>
  );
};
