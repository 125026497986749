import { useState, useEffect } from "react";
import {
  AccordionPanel,
  useDisclosure,
  IconButton,
  Collapse,
  Flex,
  Box,
  Button,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useAtom, useAtomValue } from "jotai";
import Sticky from "react-stickynode";

import { CreateOblbuhParams, OBLBUH_TYPE, OblbuhData, useOblbuhInfiniteQuery } from "features/users/api";
import { syncUsers, useOrgSelectedAtoms, useUsersSelectedAtoms } from "features/users/atoms";
import { DictStatData } from "features/dictionaries/api";
import { RefreshIcon } from "shared/ui/icons";
import { userFilterAdapter } from "features/users/lib";
import { getCurrentAttractedValues } from "features/users/helpers";
import { readOnlyModeAtom } from "features/layout/atoms";

import { UserFilter } from "../filter-panel/popover";
import { BasicCard } from "../basic-card";

import { PanelActions } from "./panel-actions";

interface Props {
  userFilter: UserFilter;
  isExpanded?: boolean;
  org: DictStatData;
}

export const UserAccordionPanel = ({ userFilter, isExpanded, org }: Props) => {
  const oblbuhQuery = useOblbuhInfiniteQuery(
    {
      ...userFilterAdapter(userFilter, ["statuses"]),
      ...(userFilter.phone?.parent_phone
        ? { phone: userFilter.phone.parent_phone }
        : userFilterAdapter(userFilter, ["phone"])),
      limit: 100,
      ...(org?.sphere_id && { sphere_ids: [org.sphere_id] }),
      ...(org?.oiv_id && { oiv_ids: [org.oiv_id] }),
      ...(org.org_id && { org_ids: [org.org_id] }),
      type: OBLBUH_TYPE.STAFF,
    },
    { enabled: isExpanded },
  );

  const oblbuh = oblbuhQuery.data?.pages.flatMap((page) => page.data).filter((data) => data !== null) ?? [];

  return (
    <AccordionPanel px="16px" pb="8px" borderBottomWidth={1} w="full">
      {oblbuh?.map((item) => (
        <Box position="relative" id={`id${item?.id}`} key={item?.id}>
          <Staff key={item?.id} item={item} userFilter={userFilter} />
        </Box>
      ))}
      <Button
        hidden={!oblbuhQuery.hasNextPage}
        w="full"
        size="sm"
        borderRadius="unset"
        bg="rgb(120 114 180 / 50%)"
        onClick={() => oblbuhQuery.fetchNextPage()}
        isLoading={oblbuhQuery.isLoading || oblbuhQuery.isFetchingNextPage}
      >
        Ещё сотрудники
      </Button>
    </AccordionPanel>
  );
};

interface StaffProps {
  userFilter: UserFilter;
  item: OblbuhData;
}

function Staff({ userFilter, item }: StaffProps) {
  const collapseDisclosure = useDisclosure();
  const [syncUser] = useAtom(syncUsers);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const readOnlyMode = useAtomValue(readOnlyModeAtom);

  const { usersSelected, isUserSelected, onUsersSelected } = useUsersSelectedAtoms();
  const { isOrgSelected, orgSelected } = useOrgSelectedAtoms();
  const hasParentSelected = usersSelected.some((u) => u.id === item.id);

  const oblbuhQuery = useOblbuhInfiniteQuery(
    {
      ...userFilterAdapter(userFilter, ["statuses", "phone"]),
      ...(!userFilter.phone?.parent_phone
        ? { parent_phone: userFilter.phone?.phone }
        : userFilterAdapter(userFilter, ["phone"])),
      limit: 50,
      sphere_ids: [item.sphere_id],
      oiv_ids: [item.oiv_id],
      org_ids: [item.org_id],
      staff_id: item.id,
      type: OBLBUH_TYPE.ALL,
    },
    { enabled: collapseDisclosure.isOpen },
  );
  const oblbuh = oblbuhQuery.data?.pages.flatMap((page) => page.data || []) ?? [];

  const [attractStaff, setAttractStaff] = useState<CreateOblbuhParams>();
  const handleOnAttractStaffClick = () => {
    const defaultValue: CreateOblbuhParams = {
      org_id: item.org_id,
      parent_id: item.id,
      phone: undefined,
      status: 0,
    };
    setAttractStaff(defaultValue);
  };

  useEffect(() => {
    if (attractStaff && isOrgSelected(item.org_id)) setAttractStaff(undefined);
    if (attractStaff && isUserSelected(item)) setAttractStaff(undefined);
    // eslint-disable-next-line
  }, [usersSelected, orgSelected]);

  useEffect(() => {
    if (attractStaff && !collapseDisclosure.isOpen) collapseDisclosure.onOpen();
    if (collapseDisclosure.isOpen && !oblbuh?.length && !syncUser.sync) collapseDisclosure.onClose();
    // eslint-disable-next-line
  }, [attractStaff]);

  return (
    <Flex
      flexDir="column"
      height="fit-content"
      _notLast={{ boxShadow: "0px 1px 0px 0px var(--chakra-colors-gray-200)" }}
    >
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore */}
      <Sticky enabled={collapseDisclosure.isOpen} top={50} innerZ={1} bottomBoundary={`#id${item.id}`}>
        <Flex
          justifyContent="space-between"
          align="center"
          py="7px"
          bgColor={collapseDisclosure.isOpen ? "white" : "inherit"}
          borderBottomWidth={1}
          borderBottomStyle="solid"
          borderBottomColor="custom.purple.100"
        >
          <BasicCard
            // isOpenParent={collapseDisclosure.isOpen}
            isChecked={isUserSelected(item) || isOrgSelected(item.org_id)}
            onCancelClick={() => setAttractStaff(undefined)}
            onAttractedClick={collapseDisclosure.onToggle}
            isDisabled={isOrgSelected(item.org_id)}
            onChange={() => onUsersSelected(item)}
            userFilter={userFilter}
            item={item}
            isWorker
          />
          <PanelActions
            isAttractStaffDisabled={!!attractStaff || hasParentSelected || isOrgSelected(item.org_id)}
            isLoading={oblbuhQuery.isFetchingNextPage || oblbuhQuery.isFetching}
            onAttractStaffClick={() => handleOnAttractStaffClick()}
            onToggle={collapseDisclosure.onToggle}
            hasAttracted={getCurrentAttractedValues({ filter: userFilter, values: item }) > 0}
            isOpen={collapseDisclosure.isOpen}
          />
        </Flex>
      </Sticky>
      <Collapse in={collapseDisclosure.isOpen}>
        <Box
          hidden={oblbuhQuery.isLoading}
          position="relative"
          boxSize="full"
          pb="7px"
          borderBottomWidth={1}
          borderBottomStyle="solid"
          borderBottomColor="custom.purple.100"
        >
          <Flex justifyContent="flex-start" gap="16px" flexWrap="wrap" align="center">
            {oblbuh?.map((o) => {
              const hasParent = usersSelected.some((u) => u.id === o.parent_id);
              return (
                <BasicCard
                  key={o.id}
                  item={o}
                  isChecked={isUserSelected(o) || hasParent || isOrgSelected(item.org_id)}
                  onChange={() => onUsersSelected(o)}
                  isDisabled={hasParent || isOrgSelected(item.org_id)}
                />
              );
            })}

            {!attractStaff ? null : (
              <BasicCard
                isNewElement
                item={{ ...item, ...attractStaff }}
                onCleanInviteStaff={() => setAttractStaff(undefined)}
                isChecked={isUserSelected(item) || isOrgSelected(item.org_id)}
                showSpinner={showSpinner}
                setShowSpinner={setShowSpinner}
              />
            )}

            {!syncUser.id && syncUser.sync && showSpinner ? (
              <Flex gap="4px" alignItems="center">
                <Spinner color="custom.purple.200" size="xs" />
                <Text textStyle="text" color="custom.purple.200">
                  Синхронизация с сервером
                </Text>
              </Flex>
            ) : null}

            <Flex hidden={!oblbuhQuery.hasNextPage} alignItems="center" h="50px" marginInlineStart="auto" gap="16px">
              <IconButton
                aria-label="next page"
                icon={<RefreshIcon />}
                hidden={!oblbuhQuery.hasNextPage}
                size="sm"
                variant="outline"
                color="custom.purple.200"
                w="min-content"
                onClick={() => oblbuhQuery.fetchNextPage()}
                isLoading={oblbuhQuery.isFetchingNextPage}
                title="Показать ещё 50 записей"
              />
              {/* <IconButton
                aria-label="Добавить подчиненного"
                variant="rounded"
                size="sm"
                icon={<PlusIcon />}
                color="custom.purple.200"
                bg="white"
                _hover={{
                  bg: "custom.purple.100",
                  color: "custom.purple.200",
                }}
                {...((readOnlyMode || !!attractStaff || hasParentSelected || isOrgSelected(item.org_id)) && {
                  isDisabled: !!attractStaff || hasParentSelected || isOrgSelected(item.org_id) || readOnlyMode,
                })}
                onClick={handleOnAttractStaffClick}
              /> */}
            </Flex>
          </Flex>
        </Box>
      </Collapse>
    </Flex>
  );
}
