import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const SortDownIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="10px" height="10px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M4.712 7.974a.35.35 0 0 0 .575 0l3.15-4.55a.35.35 0 0 0-.287-.55h-6.3a.35.35 0 0 0-.288.55l3.15 4.55Z"
      />
    </Icon>
  );
};
