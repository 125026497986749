import { Flex } from "@chakra-ui/react";
import { components, ControlProps } from "react-select";

import { SearchIcon } from "shared/ui/icons";

import { SelectOption } from "./types";

export const Control = ({ children, ...props }: ControlProps<SelectOption>) => {
  return (
    <components.Control {...props}>
      {props.selectProps.isSearchable ? (
        <Flex pl={"16px"}>
          <SearchIcon color="custom.purple.200" />
        </Flex>
      ) : null}

      {children}
    </components.Control>
  );
};
