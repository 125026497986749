import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const ConfirmAccountIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#9C8717" rx="2" />
      <path
        stroke="#F4F2DD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m3.313 3.688 7.374 6.624"
      />
    </Icon>
  );
};
