import { useState } from "react";
import { IconButton } from "@chakra-ui/react";

import { useExportOblbuh, useCreateReportOblbuh } from "features/users/api";
import { DownloadIcon } from "shared/ui/icons";
import { toast } from "shared/ui/toast";
import { userFilterAdapter } from "features/users/lib";

import { UserFilter } from "../filter-panel/popover";

const REPEAT_REQUEST_TIME = 5000;

export function ExportOblbuhIconButton({ filter }: { filter: UserFilter }) {
  const { mutateAsync: createReport } = useCreateReportOblbuh();
  const { isLoading: isLoadingExportOblbuh, mutateAsync: exportReport } = useExportOblbuh();
  const [isLoadingCreateReport, setIsLoadingCreateReport] = useState<boolean>(false);

  const filterParams = userFilterAdapter(filter, ["statuses", "org_ids", "sphere_ids", "oiv_ids"]);

  const handleDownloadReport = (uuid: string) => {
    exportReport(
      { uuid },
      {
        onSuccess: (data) => {
          if (typeof data === "string" && data === "doing") {
            setIsLoadingCreateReport(true);
            setTimeout(() => {
              handleDownloadReport(uuid);
            }, REPEAT_REQUEST_TIME);
          } else {
            setIsLoadingCreateReport(false);
            toast.success("Загрузка отчета завершена");
          }
        },
      },
    );
  };

  const handleExportOblbuh = () =>
    createReport(filterParams, {
      onSuccess: (data) => {
        if (data.uuid) {
          toast.info("Отчет начал формироваться");
          handleDownloadReport(data.uuid);
        }
      },
    });

  return (
    <IconButton
      variant="icon"
      size="icon"
      icon={<DownloadIcon color="custom.purple.200" />}
      aria-label="Скачать отчёт"
      title="Скачать отчёт"
      isLoading={isLoadingCreateReport || isLoadingExportOblbuh}
      onClick={handleExportOblbuh}
      _hover={{ bg: "custom.purple.light.200" }}
    />
  );
}
