import { InfiniteData } from "@tanstack/query-core";

import { EmployeeQuery, STAT_ENTITY_NAMES, SWITCHER_STAT_ENTITY_NAMES, StatQuery } from "./api";
import { DefineType } from "./components/fields/define-type-switch-group-field";

export const getStatEntityNames = (
  voting: 1 | 2 | 3,
  switchGroup: Partial<DefineType>,
  name: SWITCHER_STAT_ENTITY_NAMES.SPHERE | SWITCHER_STAT_ENTITY_NAMES.OIV | SWITCHER_STAT_ENTITY_NAMES.ORG,
): SWITCHER_STAT_ENTITY_NAMES => {
  const vote = voting === 2 ? "vote_" : "";
  const relative = switchGroup?.person ? "_relative" : "";

  return `${vote}${name}${relative}` as SWITCHER_STAT_ENTITY_NAMES;
};

export const getTableData = ({
  name,
  sphereStat,
  oivStat,
  orgStat,
}: {
  name: SWITCHER_STAT_ENTITY_NAMES;
  sphereStat?: InfiniteData<StatQuery>;
  oivStat?: InfiniteData<StatQuery>;
  orgStat?: InfiniteData<StatQuery>;
}) => {
  switch (name) {
    case SWITCHER_STAT_ENTITY_NAMES.SPHERE:
    case SWITCHER_STAT_ENTITY_NAMES.SPHERE_RELATIVE:
      return sphereStat;
    case SWITCHER_STAT_ENTITY_NAMES.OIV:
    case SWITCHER_STAT_ENTITY_NAMES.OIV_RELATIVE:
      return oivStat;
    case SWITCHER_STAT_ENTITY_NAMES.ORG:
    case SWITCHER_STAT_ENTITY_NAMES.ORG_RELATIVE:
      return orgStat;
    default:
      sphereStat;
  }
};

export const getHasNextPage = ({
  name,
  hasNextPageSphere,
  hasNextPageOiv,
  hasNextPageOrg,
}: {
  name: SWITCHER_STAT_ENTITY_NAMES;
  hasNextPageSphere?: boolean;
  hasNextPageOiv?: boolean;
  hasNextPageOrg?: boolean;
}) => {
  switch (name) {
    case SWITCHER_STAT_ENTITY_NAMES.SPHERE:
    case SWITCHER_STAT_ENTITY_NAMES.SPHERE_RELATIVE:
      return hasNextPageSphere;
    case SWITCHER_STAT_ENTITY_NAMES.OIV:
    case SWITCHER_STAT_ENTITY_NAMES.OIV_RELATIVE:
      return hasNextPageOiv;
    case SWITCHER_STAT_ENTITY_NAMES.ORG:
    case SWITCHER_STAT_ENTITY_NAMES.ORG_RELATIVE:
      return hasNextPageOrg;
    default:
      hasNextPageSphere;
  }
};

export const fetchNextPage =
  ({
    name,
    fetchNextPageSphere,
    fetchNextPageOiv,
    fetchNextPageOrg,
  }: {
    name: SWITCHER_STAT_ENTITY_NAMES;
    fetchNextPageSphere?: () => void;
    fetchNextPageOiv?: () => void;
    fetchNextPageOrg?: () => void;
  }) =>
  () => {
    switch (name) {
      case SWITCHER_STAT_ENTITY_NAMES.SPHERE:
      case SWITCHER_STAT_ENTITY_NAMES.SPHERE_RELATIVE:
        fetchNextPageSphere?.();
        break;
      case SWITCHER_STAT_ENTITY_NAMES.OIV:
      case SWITCHER_STAT_ENTITY_NAMES.OIV_RELATIVE:
        fetchNextPageSphere?.();
        fetchNextPageOiv?.();
        break;
      case SWITCHER_STAT_ENTITY_NAMES.ORG:
      case SWITCHER_STAT_ENTITY_NAMES.ORG_RELATIVE:
        fetchNextPageSphere?.();
        fetchNextPageOiv?.();
        fetchNextPageOrg?.();
        break;
      default:
        fetchNextPageSphere?.();
        break;
    }
  };

export const getTableEmployeeData = ({
  name,
  sphereStat,
  oivStat,
  orgStat,
}: {
  name: SWITCHER_STAT_ENTITY_NAMES;
  sphereStat?: InfiniteData<EmployeeQuery>;
  oivStat?: InfiniteData<EmployeeQuery>;
  orgStat?: InfiniteData<EmployeeQuery>;
}) => {
  switch (name) {
    case SWITCHER_STAT_ENTITY_NAMES.SPHERE:
    case SWITCHER_STAT_ENTITY_NAMES.SPHERE_RELATIVE:
      return sphereStat;
    case SWITCHER_STAT_ENTITY_NAMES.OIV:
    case SWITCHER_STAT_ENTITY_NAMES.OIV_RELATIVE:
      return oivStat;
    case SWITCHER_STAT_ENTITY_NAMES.ORG:
    case SWITCHER_STAT_ENTITY_NAMES.ORG_RELATIVE:
      return orgStat;
    default:
      sphereStat;
  }
};
