import { useMemo } from "react";
import { Form } from "react-final-form";
import {
  PopoverContent,
  PopoverTrigger,
  PopoverFooter,
  PopoverHeader,
  useDisclosure,
  PopoverBody,
  Popover,
  Portal,
  Button,
  Flex,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { omit, uniqBy } from "lodash";

import { SelectDictSphereStatField } from "features/dictionaries/components/fields/select-dict-sphere-stat-field";
import { SelectDictOivStatField } from "features/dictionaries/components/fields/select-dict-oiv-stat-field";
import { SelectDictOrgStatField } from "features/dictionaries/components/fields/select-dict-org-stat-field";
import { CrossIcon, CheckIcon, FilterIcon } from "shared/ui/icons";
import { STAT_ENTITY_NAMES, SWITCHER_STAT_ENTITY_NAMES, StatQuery } from "features/stats/api";
import { WithPermission } from "features/permissions";
import { SelectOption } from "shared/form/select-v2";
import { useAccessController } from "config/routes/router-provider";
import { ROLES } from "shared/constants";
import { DictStatData } from "features/dictionaries/api";

import { Filter } from "../pages";

import { DefineType, DefineTypeSwitchGroupFiled } from "./fields/define-type-switch-group-field";
import { ExportStatIconButton } from "./buttons/export-stat-icon-button";
import { ShowNameElementField } from "./fields/show-name-element-field";
import { VoteStatIconButton } from "./buttons/vote-stat-icon-button";
import { EmployeeIconButton } from "./buttons/employee-stat-icon-button";
import { RelativeStatIconButton } from "./buttons/relative-stat-icon-button";

export type ExtendedDictStat = SelectOption &
  Omit<
    DictStatData,
    | "arch_attracted"
    | "relative"
    | "attracted_no_voted"
    | "attracted_voted"
    | "arch_staff"
    | "staff"
    | "staff_no_voted"
    | "staff_voted"
    | "arch_cnt_all"
    | "cnt_all"
    | "cnt_all_no_voted"
    | "cnt_voted"
  >;

export type FormPayload = {
  name: SWITCHER_STAT_ENTITY_NAMES;
  sphere?: Array<ExtendedDictStat>;
  oiv?: Array<ExtendedDictStat>;
  org?: Array<ExtendedDictStat>;
};

interface Props {
  filter: Filter;
  switchGroup: Partial<DefineType>;
  voting: 1 | 2 | 3;
  counts: { sphere_count: number; oiv_count: number; org_count: number };
  onFilterChange: (filter: Filter) => void;
  onVotingChange: (v: 1 | 2 | 3) => void;
  onSwitchGroupChange?: (v: Partial<DefineType>) => void;
}

export const FilterStatsPanel = ({
  onFilterChange,
  onSwitchGroupChange,
  onVotingChange,
  filter,
  switchGroup,
  voting,
  counts,
}: Props) => {
  const { access } = useAccessController();
  const currentUserRoleId = access?.roles?.[0].id;
  const isDTOIVRole = currentUserRoleId === ROLES.DTOIV;
  const isSphereRole = currentUserRoleId === ROLES.SPHERE;
  const isEmployee = voting === 3;

  const popoverDisclosure = useDisclosure();

  const initialValues = useMemo<FormPayload>(
    () => ({
      name: isDTOIVRole || isSphereRole ? SWITCHER_STAT_ENTITY_NAMES.SPHERE : SWITCHER_STAT_ENTITY_NAMES.OIV,
      sphere: [],
      oiv: [],
      org: [],
    }),
    [isDTOIVRole, isSphereRole],
  );

  const handleOnSubmit = ({
    sphere = [],
    oiv = [],
    org = [],
    name = SWITCHER_STAT_ENTITY_NAMES.SPHERE,
  }: FormPayload) => {
    const sphere_ids = sphere.map((o) => +o.value);
    const org_ids = org.map((o) => +o.value);
    const oiv_ids = oiv.map((o) => +o.value);
    const newValue: Filter = { name, sphere_ids, org_ids, oiv_ids };
    onFilterChange(newValue);
    popoverDisclosure.onClose();
  };

  return (
    <Form<FormPayload>
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      render={({ handleSubmit: submit, form, dirty, values }) => {
        return (
          <Flex
            as="form"
            id="filter-stat-form"
            onSubmit={submit}
            minH="50px"
            px="20px"
            borderBottomWidth={1}
            borderBottomStyle="solid"
            borderBottomColor="custom.purple.100"
            alignItems="center"
            gap="6px"
          >
            <Flex flex={1} />
            <Flex gap="10px" mr="26px">
              {isDTOIVRole || isSphereRole ? (
                <ShowNameElementField defineType="SPHERE">
                  <Text textStyle="textLight" color="custom.purple.200">
                    уровень 1 выбрано:{" "}
                    <Text as="span" pr="6px">
                      {counts.sphere_count}
                    </Text>
                  </Text>
                </ShowNameElementField>
              ) : null}
              <ShowNameElementField defineType="OIV">
                <Text textStyle="textLight" color="custom.purple.200">
                  уровень 2 выбрано:{" "}
                  <Text as="span" pr="6px">
                    {counts.oiv_count}
                  </Text>
                </Text>
              </ShowNameElementField>
              <ShowNameElementField defineType="ORG">
                <Text textStyle="textLight" color="custom.purple.200">
                  уровень 3 выбрано:{" "}
                  <Text as="span" pr="6px">
                    {counts.org_count}
                  </Text>
                </Text>
              </ShowNameElementField>
            </Flex>
            <Popover isLazy placement="bottom-start" {...popoverDisclosure} closeOnBlur>
              <PopoverTrigger>
                <IconButton
                  variant="rounded"
                  size="sm"
                  icon={<FilterIcon color="white" />}
                  aria-label="Фильтр"
                  title="Фильтр"
                  bg={popoverDisclosure.isOpen ? "custom.purple.200" : "custom.purple.100"}
                />
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  w="510px"
                  borderRadius={10}
                  borderWidth={1}
                  borderStyle="solid"
                  borderColor="custom.purple.light.200"
                  boxShadow="shadows.default"
                  px="50px"
                  pt="30px"
                  pb="40px"
                >
                  <PopoverHeader borderBottomWidth={0}>
                    <Text textStyle="h1" color="custom.grey.dark.100">
                      Фильтр
                    </Text>
                  </PopoverHeader>
                  <PopoverBody>
                    <Flex flexDir="column" gap="20px">
                      <ShowNameElementField defineType="SPHERE">
                        <SelectDictSphereStatField
                          selectProps={{
                            allowSelectAll: true,
                          }}
                          onChange={() => {
                            form.initialize((values) => omit(values, ["oiv", "org"]));
                          }}
                        />
                      </ShowNameElementField>
                      <ShowNameElementField defineType="OIV">
                        <SelectDictOivStatField
                          selectProps={{
                            allowSelectAll: true,
                          }}
                          onChange={(v) => {
                            const value = v.length ? v : values.sphere ?? [];
                            const sphere = uniqBy(
                              value.map((o) => ({
                                value: String(o?.sphere_id),
                                sphere_id: Number(o?.sphere_id),
                                label: "",
                              })),
                              "sphere_id",
                            );
                            form.initialize((values) => ({
                              ...omit(values, ["org"]),
                              sphere,
                            }));
                          }}
                        />
                      </ShowNameElementField>
                      <ShowNameElementField defineType="ORG">
                        <SelectDictOrgStatField
                          selectProps={{
                            allowSelectAll: true,
                          }}
                          onChange={(v) => {
                            const value = v.length ? v : values.oiv ?? [];
                            const sphere = uniqBy(
                              value.map((o) => ({
                                value: String(o?.sphere_id),
                                sphere_id: Number(o?.sphere_id),
                                label: "",
                                title: "",
                              })),
                              "sphere_id",
                            );
                            const oiv = uniqBy(
                              value.map((o) => ({
                                value: String(o?.oiv_id),
                                oiv_id: Number(o?.oiv_id),
                                sphere_id: o?.sphere_id,
                                label: "",
                              })),
                              "oiv_id",
                            );
                            form.initialize((values) => ({
                              ...values,
                              sphere,
                              oiv,
                            }));
                          }}
                        />
                      </ShowNameElementField>
                    </Flex>
                  </PopoverBody>
                  <PopoverFooter
                    bgColor="custom.grey.light.100"
                    mt="20px"
                    py="20px"
                    borderRadius={15}
                    borderTopWidth={0}
                  >
                    <Flex justifyContent="center" gap="30px">
                      <Button w="full" leftIcon={<CheckIcon />} onClick={form.submit} isDisabled={!dirty}>
                        ОК
                      </Button>
                      <Button
                        w="full"
                        leftIcon={<CrossIcon />}
                        onClick={() => {
                          // form.reset() и form.restart() не отрабатывали корректно, поэтому вручную очищаем
                          form.change("sphere", []);
                          form.change("oiv", []);
                          form.change("org", []);
                          onFilterChange({ name: values.name });
                          popoverDisclosure.onClose();
                        }}
                      >
                        Отмена
                      </Button>
                    </Flex>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </Popover>
            <Flex bg="custom.purple.100" boxShadow="shadows.inner" rounded={9999} gap={2}>
              <RelativeStatIconButton
                filter={filter}
                isActive={voting === 1}
                onClick={() => {
                  onVotingChange(1);
                }}
              />
              <VoteStatIconButton
                filter={filter}
                isActive={voting === 2}
                onClick={() => {
                  onVotingChange(2);
                }}
              />
              <EmployeeIconButton
                filter={filter}
                isActive={isEmployee}
                onClick={() => {
                  if (switchGroup.stats === 1) {
                    form.change("name", SWITCHER_STAT_ENTITY_NAMES.SPHERE);
                  }
                  if (switchGroup.stats === 2) {
                    form.change("name", SWITCHER_STAT_ENTITY_NAMES.OIV);
                  }
                  if (switchGroup.stats === 3) {
                    form.change("name", SWITCHER_STAT_ENTITY_NAMES.ORG);
                  }
                  onSwitchGroupChange?.({ ...switchGroup, person: false });
                  onVotingChange(3);
                }}
              />
            </Flex>
            <DefineTypeSwitchGroupFiled
              isEmployee={isEmployee}
              name="name"
              onChange={(v, t) => {
                onFilterChange({ ...filter, name: v });
                onSwitchGroupChange?.(t);
              }}
            />
            <WithPermission permission={["DTOIV", "SPHERE", "OIV"]}>
              <ExportStatIconButton filter={filter} voting={voting} />
            </WithPermission>
          </Flex>
        );
      }}
    />
  );
};
