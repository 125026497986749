import qs from "qs";

type ID = number | string;

export const routePaths = {
  main: () => "/",
  login: () => "/login",
  users: () => "/users",
  statistics: () => "/statistics",
  adminOrganizations: () => "/admin/organizations",
  adminUsers: () => "/admin/users",
};
