import { Divider, Flex } from "@chakra-ui/react";

import { Summary } from "../summary";

interface Props {
  organizationsCount?: number;
  statusesCount?: number;
  attracted?: number;
  workers?: number;
  total?: number;
}

export const FilterInfo = ({
  organizationsCount = 0,
  statusesCount = 0,
  attracted = 0,
  workers = 0,
  total = 0,
}: Props) => {
  return (
    <Flex gap="10px" mr="26px">
      <Summary amount={total} label="Всего:" />
      <Summary amount={workers} label="сотрудников:" />
      <Summary amount={attracted} label="дополнительных телефонов:" />
      <CustomDivider />
      <Summary variant="secondary" amount={statusesCount} label="Выбрано статусов:" />
      <CustomDivider />
      <Summary variant="secondary" amount={organizationsCount} label="Выбрано уровней:" />
    </Flex>
  );
};

function CustomDivider() {
  return <Divider orientation="vertical" opacity="1" borderColor="custom.purple.100" />;
}
