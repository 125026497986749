import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const SnilsIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#0A0077" rx="2" />
      <path stroke="#FAFAFF" strokeLinecap="round" strokeLinejoin="round" d="M3.111 5.333h7.778M3.11 8.667H7" />
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M2 4.222h10v5.556H2z" />
        </clipPath>
      </defs>
    </Icon>
  );
};
