export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) {
    return "0";
  } else {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["байт", "КБ", "МБ", "ГБ", "ТБ"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }
}

export const percentAmount = (n: number, total: number) => {
  const calc = Math.round((n * 100) / total);
  return !Number.isFinite(calc) || Number.isNaN(calc) ? 0 : calc;
};

export const percentOfNumber = (p = 0, total: number) => {
  const calc = Math.ceil(total * (p / 100));
  return !Number.isFinite(calc) || Number.isNaN(calc) ? 0 : calc;
};
