import { RouterProvider as RRDRouterProvider, createBrowserRouter } from "react-router-dom";

import { useAccessStorageStore } from "shared/store/use-access-storage-store";

import { getRoleRoutes } from "./";

export const useAccessController = () => useAccessStorageStore();

export function RouterProvider() {
  const { access } = useAccessController();
  const currentUserRoleId = access?.roles?.[0].id;
  const routes = getRoleRoutes(currentUserRoleId);

  const routeConstructor = createBrowserRouter(routes);
  return <RRDRouterProvider router={routeConstructor} />;
}
