import { UseInfiniteQueryOptions, UseQueryOptions, useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { QueryError } from "api/types";

import { DICT_ENTITY_NAMES, DictAdminQuery, DictParams, DictQuery, DictStatParams, DictStatQuery } from "./types";
import { getDictStat, getDict, getReadOnlyStatus, getAdminDict } from "./api";
import { DICT_QUERY_KEYS } from "./keys";

export function useDictQuery<T = DictQuery>(
  params: DictParams,
  options: UseQueryOptions<DictQuery, QueryError, T> = {},
) {
  return useQuery<DictQuery, QueryError, T>([DICT_QUERY_KEYS.DICT, params], () => getDict(params), {
    keepPreviousData: true,
    ...options,
  });
}

export function useDictInfiniteQuery<T = DictQuery>(
  params: DictParams,
  options: UseInfiniteQueryOptions<DictQuery, QueryError, T> = {},
  queryKey: string = DICT_QUERY_KEYS.DICT_INFINITE,
) {
  return useInfiniteQuery<DictQuery, QueryError, T>(
    [queryKey, params],
    async ({ pageParam = 0 }) => getDict({ ...params, offset: pageParam * params.limit }),
    {
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        const allCount = allPages.flatMap(({ data }) => data).length;
        if (lastPage.count > allCount) return allPages.length;
        return false;
      },
    },
  );
}

export function useDictAdminQuery<T = DictAdminQuery>(
  params: DictParams,
  options: UseQueryOptions<DictAdminQuery, QueryError, T> = {},
) {
  return useQuery<DictAdminQuery, QueryError, T>([DICT_QUERY_KEYS.DICT, params], () => getAdminDict(params), {
    keepPreviousData: true,
    ...options,
  });
}

export function useDictAdminInfiniteQuery<T = DictAdminQuery>(
  params: DictParams,
  options: UseInfiniteQueryOptions<DictAdminQuery, QueryError, T> = {},
  queryKey: string = DICT_QUERY_KEYS.DICT_INFINITE,
) {
  return useInfiniteQuery<DictAdminQuery, QueryError, T>(
    [queryKey, params],
    async ({ pageParam = 0 }) => getAdminDict({ ...params, offset: pageParam * params.limit }),
    {
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        const allCount = allPages.flatMap(({ data }) => data).length;
        if (lastPage.count > allCount) return allPages.length;
        return false;
      },
    },
  );
}

export function useDictStatQuery<T = DictStatQuery>(
  params: DictStatParams,
  options: UseQueryOptions<DictStatQuery, QueryError, T> = {},
) {
  const name = params.name;
  const queryKey = `${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${name}`;
  return useQuery<DictStatQuery, QueryError, T>([queryKey, params], () => getDictStat(params), {
    keepPreviousData: true,
    ...options,
  });
}

export function useDictStatInfiniteQuery<T = DictStatQuery>(
  params: DictStatParams,
  options: UseInfiniteQueryOptions<DictStatQuery, QueryError, T> = {},
  queryKey: string = DICT_QUERY_KEYS.DICT_STAT_INFINITE,
) {
  return useInfiniteQuery<DictStatQuery, QueryError, T>(
    [queryKey, params],
    async ({ pageParam = 0 }) => getDictStat({ ...params, offset: pageParam * params.limit }),
    {
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        const allCount = allPages.flatMap(({ data }) => data).length;
        if (lastPage.count > allCount) return allPages.length;
        return false;
      },
    },
  );
}

export function useDictSphereStatInfiniteQuery<T = DictStatQuery>(
  params: Omit<DictStatParams, "name">,
  options: UseInfiniteQueryOptions<DictStatQuery, QueryError, T> = {},
) {
  const name = DICT_ENTITY_NAMES.SPHERE;
  const queryKey = `${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${name}`;
  return useDictStatInfiniteQuery<T>({ name, ...params }, options, queryKey);
}

export function useDictOivStatInfiniteQuery<T = DictStatQuery>(
  params: Omit<DictStatParams, "name">,
  options: UseInfiniteQueryOptions<DictStatQuery, QueryError, T> = {},
) {
  const name = DICT_ENTITY_NAMES.OIV;
  const queryKey = `${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${name}`;
  return useDictStatInfiniteQuery<T>({ name, ...params }, options, queryKey);
}

export function useDictOrgStatInfiniteQuery<T = DictStatQuery>(
  params: Omit<DictStatParams, "name">,
  options: UseInfiniteQueryOptions<DictStatQuery, QueryError, T> = {},
) {
  const name = DICT_ENTITY_NAMES.ORG;
  const queryKey = `${DICT_QUERY_KEYS.DICT_STAT_INFINITE}_${name}`;
  return useDictStatInfiniteQuery<T>({ name, ...params }, options, queryKey);
}

export function useDictSphereOivOrgInfiniteQuery<T = DictAdminQuery>(
  params: Omit<DictParams, "name">,
  options: UseInfiniteQueryOptions<DictAdminQuery, QueryError, T> = {},
) {
  const name = DICT_ENTITY_NAMES.SPHERE_OIV_ORG;
  const queryKey = `${DICT_QUERY_KEYS.DICT_INFINITE}_${name}`;
  return useDictAdminInfiniteQuery<T>({ name, ...params }, options, queryKey);
}

export function useReadOnlyStatus(options: UseQueryOptions<boolean, QueryError> = {}) {
  return useQuery<boolean, QueryError>(["readOnlyStatus"], () => getReadOnlyStatus(), {
    keepPreviousData: true,
    ...options,
  });
}
