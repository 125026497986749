import qs from "qs";

import { apiInstance } from "api/client";
import { DictAdminUserQuery } from "features/dictionaries/api";

import { AddOrgParams, RemoveOrgsParams, UpdateOrgParams, UserAdminParams } from "./types";

export const getUsers = async (params: UserAdminParams) => {
  const queryString = qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
  const url = `/admin/users?${queryString}`;
  const { data } = await apiInstance.get<DictAdminUserQuery>(url);

  return data.result;
};

export const addOrg = async (params: AddOrgParams) => {
  const url = "/admin/dict/org";
  const { data } = await apiInstance.post<boolean>(url, { ...params });
  return data.result;
};

export const updateOrg = async ({ id, ...restParams }: UpdateOrgParams) => {
  const url = `/admin/dict/org/${id}`;
  const { data } = await apiInstance.put<boolean>(url, { ...restParams });
  return data.result;
};

export const removeOrgs = async ({ ids }: RemoveOrgsParams) => {
  const url = "/admin/dict/org";
  const { data } = await apiInstance.delete<boolean>(url, { data: { ids } });
  return data.result;
};
