export type DictParams = {
  name: DICT_ENTITY_NAMES;
  limit: number;
  offset?: number;
  order_by?: keyof DictStatData;
  order_type?: "asc" | "desc";
  search_title?: string;
  sphere_ids?: number[];
  oiv_ids?: number[];
  org_ids?: number[];
  are_ignored_user_orgs?: boolean;
};

export type DictData = {
  oiv_id?: number;
  org_id?: number;
  sphere_id?: number;
  sphere: string;
  oiv: string;
  org: string;
};

export type DictOivData = {
  id: number;
  oiv_id?: number;
  org_id?: number;
  sphere_id?: number;
  title?: string;
};

export type DictAdminUserData = {
  id: number;
  name: string;
  phone: string;
  is_active: boolean;
  roles: string[];
  org_ids: number[];
};

export type DictAdminData = {
  id: number;
  inn?: string;
  oiv_id?: number;
  org_id?: number;
  sphere_id?: number;
  sphere?: string;
  oiv?: string;
  org?: string;
};

export type DictStatParams = Omit<DictParams, "name"> & {
  name: Extract<DICT_ENTITY_NAMES, DICT_ENTITY_NAMES.SPHERE | DICT_ENTITY_NAMES.OIV | DICT_ENTITY_NAMES.ORG>;
};

export type DictStatData = {
  arch_attracted: number;
  relative: number; // ex prop name "attracted"
  attracted_no_voted: number;
  attracted_voted: number;
  arch_cnt_all: number;
  cnt_all: number;
  cnt_all_no_voted: number;
  cnt_voted: number;
  arch_staff: number;
  staff: number;
  staff_no_voted: number;
  staff_voted: number;
  sphere?: string;
  sphere_id?: number;
  oiv?: string;
  oiv_id?: number;
  org?: string;
  org_id?: number;
  inn?: string;
};

export type DictAdminUserQuery = Array<DictAdminUserData>;

export type DictAdminQuery = {
  data: Array<DictAdminData>;
  count: number;
};

export type DictQuery = {
  data: Array<DictData>;
  count: number;
};

export type DictStatQuery = {
  data: Array<DictStatData>;
  count: number;
};

export enum DICT_ENTITY_NAMES {
  IS_MOS = "is_mos",
  REESTR = "reestr",
  SPHERE_STAT = "sphere_stat",
  SPHERE = "sphere",
  STATUS = "status",
  ELK = "elk",
  OIV_STAT = "oiv_stat",
  OIV = "oiv",
  ORG_STAT = "org_stat",
  ORG = "org",
  SPHERE_OIV_ORG = "sphere_oiv_org",
}

export type DICT_ENTITY_NAMES_STRING = keyof typeof DICT_ENTITY_NAMES;
