import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const ListIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.40541 4.7027H17V6.21622H6.40541V4.7027ZM6.40541 10.7568V9.24324H17V10.7568H6.40541ZM4.13514 4.32433C4.43619 4.32433 4.72492 4.44392 4.9378 4.6568C5.15068 4.86968 5.27027 5.1584 5.27027 5.45946C5.27027 5.76052 5.15068 6.04924 4.9378 6.26212C4.72492 6.475 4.43619 6.5946 4.13514 6.5946C3.83408 6.5946 3.54535 6.475 3.33247 6.26212C3.11959 6.04924 3 5.76052 3 5.45946C3 5.1584 3.11959 4.86968 3.33247 4.6568C3.54535 4.44392 3.83408 4.32433 4.13514 4.32433ZM4.13514 8.86487C4.43619 8.86487 4.72492 8.98446 4.9378 9.19734C5.15068 9.41022 5.27027 9.69894 5.27027 10C5.27027 10.3011 5.15068 10.5898 4.9378 10.8027C4.72492 11.0155 4.43619 11.1351 4.13514 11.1351C3.83408 11.1351 3.54535 11.0155 3.33247 10.8027C3.11959 10.5898 3 10.3011 3 10C3 9.69894 3.11959 9.41022 3.33247 9.19734C3.54535 8.98446 3.83408 8.86487 4.13514 8.86487ZM6.40541 15.2973V13.7838H17V15.2973H6.40541ZM4.13514 13.4054C4.43619 13.4054 4.72492 13.525 4.9378 13.7379C5.15068 13.9508 5.27027 14.2395 5.27027 14.5405C5.27027 14.8416 5.15068 15.1303 4.9378 15.3432C4.72492 15.5561 4.43619 15.6757 4.13514 15.6757C3.83408 15.6757 3.54535 15.5561 3.33247 15.3432C3.11959 15.1303 3 14.8416 3 14.5405C3 14.2395 3.11959 13.9508 3.33247 13.7379C3.54535 13.525 3.83408 13.4054 4.13514 13.4054Z"
        fill="currentColor"
      />
    </Icon>
  );
};
