import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const EyeOutlinedIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M 18.402344 9.496094 C 16.550781 5.59375 13.75 3.632812 10 3.632812 C 6.246094 3.632812 3.449219 5.59375 1.597656 9.5 C 1.449219 9.816406 1.449219 10.183594 1.597656 10.503906 C 3.449219 14.40625 6.25 16.367188 10 16.367188 C 13.753906 16.367188 16.550781 14.40625 18.402344 10.5 C 18.554688 10.1875 18.554688 9.820312 18.402344 9.496094 Z M 10 14.960938 C 6.851562 14.960938 4.542969 13.363281 2.914062 10 C 4.542969 6.636719 6.851562 5.039062 10 5.039062 C 13.148438 5.039062 15.457031 6.636719 17.085938 10 C 15.460938 13.363281 13.152344 14.960938 10 14.960938 Z M 9.921875 6.5625 C 8.023438 6.5625 6.484375 8.101562 6.484375 10 C 6.484375 11.898438 8.023438 13.4375 9.921875 13.4375 C 11.820312 13.4375 13.359375 11.898438 13.359375 10 C 13.359375 8.101562 11.820312 6.5625 9.921875 6.5625 Z M 9.921875 12.1875 C 8.710938 12.1875 7.734375 11.210938 7.734375 10 C 7.734375 8.789062 8.710938 7.8125 9.921875 7.8125 C 11.132812 7.8125 12.109375 8.789062 12.109375 10 C 12.109375 11.210938 11.132812 12.1875 9.921875 12.1875 Z M 9.921875 12.1875"
      />
    </Icon>
  );
};
