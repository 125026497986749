import React from "react";
import { useDropzone, DropzoneRootProps, Accept } from "react-dropzone";
import { CenterProps, Progress, Center, Text, VStack, Flex, Box } from "@chakra-ui/react";
import { UseFieldConfig, useField } from "react-final-form";

import { formatBytes } from "shared/helpers";
import { FormControl } from "shared/form/form-control";

export interface FilePickerProps extends Omit<CenterProps, "onChange"> {
  name: string;
  fieldConfig?: UseFieldConfig<FilePickerType, FilePickerType>;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isLoading?: boolean;
  isReadOnly?: boolean;
  accept?: Accept;
  maxSize?: number;
  maxFiles?: number;
  multiple?: boolean;
  isCover?: boolean;
  children?: React.ReactNode;
}

type FilePickerType = File | null;

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100Mb

export function FilePicker({
  name,
  fieldConfig,
  isDisabled,
  isReadOnly,
  isInvalid,
  isLoading,
  accept = {
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  },
  maxSize = MAX_FILE_SIZE,
  maxFiles,
  multiple = false,
  isCover,
  children,
  ...args
}: FilePickerProps) {
  const formats = Object.values(accept).flatMap((v) => v);

  const { input, meta } = useField<FilePickerType, HTMLElement, FilePickerType>(name, fieldConfig);

  const dropzone = useDropzone({
    accept,
    maxFiles,
    maxSize,
    multiple,
    onDrop: input.onChange,
    disabled: isDisabled || isReadOnly,
  });

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections, acceptedFiles } =
    dropzone;

  const isHaveErrors = fileRejections.length > 0;

  return (
    <FormControl isInvalid={meta.touched && !!meta.error && meta.submitFailed} caption={meta.touched && meta.error}>
      <Box w="100%">
        <Center
          {...getRootProps<Omit<DropzoneRootProps, "css">>({
            boxSize: "full",
            borderWidth: "2px",
            borderStyle: "dashed",
            borderRadius: "8px",
            overflow: "hidden",
            pos: "relative",
            p: "16px",
            bgColor: "transparent",
            borderColor: "custom.purple.100",
            color: "text.secondary",
            cursor: isReadOnly ? undefined : "pointer",
            transitionProperty: "common",
            transitionDuration: "normal",
            _focusVisible: {
              outlineColor: "transparent",
              borderColor: "custom.purple.200",
              borderStyle: "solid",
            },
            ...args,
          })}
          {...(input.value && isCover
            ? {
                border: "none",
                _focusVisible: {
                  outlineColor: "transparent",
                  boxShadow: "var(--chakra-shadows-shadows-default)",
                },
              }
            : null)}
          {...(!isDragActive
            ? null
            : {
                borderColor: "border_divider.focused",
                bgColor: "custom.purple.light.200",
                borderStyle: "dashed",
              })}
          {...(!isDragAccept
            ? null
            : {
                bgColor: "custom.green.light.100",
                borderStyle: "dashed",
              })}
          {...(!isDragReject
            ? null
            : {
                borderColor: "custom.red.100",
                borderStyle: "dashed",
              })}
          {...(!isInvalid && !isHaveErrors
            ? null
            : {
                borderColor: "custom.red.100",
                borderStyle: "dashed",
              })}
          {...(!isDisabled || isLoading
            ? {
                _hover: {
                  bgColor: "brand.tertiary.20",
                },
              }
            : { bgColor: "white", borderColor: "border_divider.light", color: "text.disabled", cursor: "not-allowed" })}
        >
          <input {...getInputProps({ disabled: isDisabled || isLoading })} />
          {!isLoading ? null : (
            <Progress left={4} right={4} bottom={4} borderRadius="full" pos="absolute" size="xs" isIndeterminate />
          )}
          {input.value && isCover ? null : (
            <VStack spacing={3} align="stretch" alignItems="center" textAlign="center">
              <Flex wrap="wrap" justifyContent="center" columnGap="26px">
                <Text as="span" textStyle="text" color="custom.purple.200" noOfLines={1}>
                  {acceptedFiles.length > 0 ? acceptedFiles[0].name : "Перетащите или выберите файл"}
                </Text>
              </Flex>
              <Text
                textStyle="text"
                color="custom.purple.200"
                transitionProperty="color"
                transitionDuration="normal"
                {...(!isDisabled || isLoading ? null : { color: "text.disabled" })}
              >
                <Text as="span" textTransform="uppercase">
                  {formats.join(", ")}
                </Text>
                &nbsp;до&nbsp;{formatBytes(maxSize)}
              </Text>
            </VStack>
          )}

          {input.value && isCover ? children : null}
        </Center>

        {/* {fileRejections.map(({ file, errors }) => (
        <VStack alignItems="flex-start" key={file.name} mt="12px">
          {errors.map(({ code }) => (
            <VStack alignItems="flex-start" key={code}>
              <Text color="system.error">{code}</Text>
            </VStack>
          ))}
        </VStack>
      ))} */}
      </Box>
    </FormControl>
  );
}
