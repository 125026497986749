import { useState, useCallback } from "react";
import debounce from "lodash/debounce";
import { InputActionMeta } from "react-select";

import { DICT_ENTITY_NAMES, type DictData, useDictQuery } from "features/dictionaries/api";
import { SelectField, SelectOption } from "shared/form/select-v2";
import { SelectFieldProps } from "shared/form/select-v2/field";

export interface ExtendedSelectOption extends SelectOption, DictData {}

interface Props extends Omit<SelectFieldProps, "options" | "name" | "onChange"> {
  name?: string;
  onChange?: (v: ExtendedSelectOption[]) => void;
}

export function SelectOrgField({ selectProps, name = "org", onChange, ...args }: Props) {
  const [inputValue, setInputValue] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetInputValue = useCallback(debounce(setInputValue, 300), []);

  const onInputChange = (value: string, action: InputActionMeta) => {
    if (action?.action !== "input-blur" && action?.action !== "menu-close" && action?.action === "input-change") {
      debounceSetInputValue(value);
    }
  };

  const { data: options = [], isFetching } = useDictQuery<ExtendedSelectOption[]>(
    { limit: 30, name: DICT_ENTITY_NAMES.ORG, search_title: inputValue },
    { select: ({ data = [] }) => data?.map((o) => ({ ...o, value: String(o.org_id), label: o.org })) || [] },
  );

  return (
    <SelectField
      name={name}
      selectProps={{
        isLoading: isFetching,
        closeMenuOnSelect: true,
        ...selectProps,
        onInputChange: onInputChange,
      }}
      valueLabel="элементов уровня 3"
      placeholder="ВЫБЕРИТЕ ЭЛЕМЕНТЫ УРОВНЯ 3..."
      onChange={(v) => onChange?.(v as ExtendedSelectOption[])}
      options={options}
      {...args}
    />
  );
}
