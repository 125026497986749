import { ReactNode } from "react";
import { Text, TextProps } from "@chakra-ui/react";

interface ColumnHeader {
  children: ReactNode;
  textAlign?: TextProps["textAlign"];
}

export function ColumnHeader({ children, textAlign = "center" }: ColumnHeader) {
  return (
    <Text w="100%" textStyle="textBold" color="custom.purple.200" textAlign={textAlign}>
      {children}
    </Text>
  );
}
