import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/react";

import { textStyles } from "../text-styles";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "5px",
    ...textStyles.button,
  },
  variants: {
    filter: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "custom.purple.200",
      borderRadius: "full",
      bgColor: "custom.purple.light.200",
      color: "custom.purple.200",
      _hover: {
        bg: "custom.purple.light.100",
      },
    },
    icon: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "custom.purple.100",
      borderRadius: "full",
      bg: "white",
      p: 0,
    },
    onlyIcon: {
      bg: "white",
      borderRadius: "full",
      color: "custom.purple.200",
      p: 0,
    },
    rounded: {
      borderWidth: 0,
      borderStyle: "solid",
      borderRadius: "full",
      bg: "custom.purple.100",
      color: "white",
      p: 0,
      _hover: {
        bg: "custom.purple.200",
        color: "white",
        _disabled: {
          bg: "custom.grey.light.200",
          color: "custom.grey.dark.100",
        },
      },
      _disabled: {
        bg: "custom.grey.light.200",
        color: "custom.grey.dark.100",
      },
    },
    "rounded-light": {
      borderWidth: 0,
      borderStyle: "solid",
      borderRadius: "full",
      bg: "custom.purple.light.200",
      color: "white",
      p: 0,
      _hover: {
        bg: "custom.purple.200",
        color: "white",
        _disabled: {
          bg: "custom.grey.light.200",
          color: "custom.grey.dark.100",
        },
      },
      _disabled: {
        bg: "custom.grey.light.200",
        color: "custom.grey.dark.100",
      },
    },
  },
  sizes: {
    lg: {
      w: "fit-content",
      h: "45px",
    },
    icon: {
      w: "32px",
      h: "32px",
    },
    sm: {
      w: "30px",
      minW: "30px",
      h: "30px",
    },
    xs: {
      w: "20px",
      minW: "20px",
      h: "20px",
    },
  },
  defaultProps: {
    size: "lg",
    colorScheme: "brand",
  },
};
