import { MaskInputFieldProps, PhoneField } from "shared/form/mask-input";
import { SearchIcon } from "shared/ui/icons";

interface Props extends Omit<MaskInputFieldProps, "name" | "inputFormat" | "onChange"> {
  name?: string;
  onCustomChange?: (v: string) => void;
}

export function PhoneUsersField({ onCustomChange, name = "phone", ...props }: Props) {
  return (
    <PhoneField
      {...props}
      onCustomChange={onCustomChange}
      name="phone"
      size="sm"
      maxW="190px"
      inputFormat="+# (###) ### ## ##"
      placeholder="Найти телефон..."
      icon={<SearchIcon />}
      inputProps={{
        pl: "28px",
        size: "md-panel",
        variant: "panel",
      }}
    />
  );
}
