import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const CloudIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M16.25 8.5A6.309 6.309 0 0 0 10 3C7.52 3 5.365 4.45 4.381 6.735 2.448 7.313 1 9.138 1 11.1c0 2.481 2.019 4.5 4.5 4.5h9.9c1.985 0 3.6-1.615 3.6-3.6a3.609 3.609 0 0 0-2.75-3.5Zm-5.35 2.6v2.7H9.1v-2.7H6.4L10 6.6l3.6 4.5h-2.7Z"
      />
    </Icon>
  );
};
