import { useMemo } from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import { Checkbox, Flex, IconButton } from "@chakra-ui/react";

import { StringCell } from "shared/ui/table/cells/string";
import { PencilIcon, TrashIcon } from "shared/ui/icons";
import { DictAdminUserData } from "features/dictionaries/api";

import { ColumnHeader } from "../components/column-header";

interface useColumnsProps {
  handleEditOrg?: (row: Row<DictAdminUserData>) => void;
  handleRemoveOrg?: (row: Row<DictAdminUserData>) => void;
}

export const useColumnsUsers = ({ handleEditOrg, handleRemoveOrg }: useColumnsProps) =>
  useMemo<ColumnDef<DictAdminUserData>[]>(
    () => [
      {
        accessorKey: "select",
        size: 50,
        maxSize: 10,
        enableSorting: false,
        header: ({ table }) => (
          <Checkbox
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            p={1}
            isChecked={row.getIsSelected()}
            isIndeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        accessorKey: "name",
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">ФИО</ColumnHeader>,
        cell: StringCell,
      },
      {
        accessorKey: "phone",
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">телефон</ColumnHeader>,
        cell: StringCell,
      },
      {
        accessorKey: "is_active",
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">статус</ColumnHeader>,
        cell: ({ row }) => <Flex>{Boolean(row.original.is_active) ? "Активен" : "Не активен"}</Flex>,
      },
      {
        accessorKey: "roles",
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">роль</ColumnHeader>,
        cell: ({ row }) => <Flex>{row.original.roles.join(", ")}</Flex>,
      },
      {
        accessorKey: "actions",
        maxSize: 10,
        enableSorting: false,
        sortDescFirst: false,
        header: () => <ColumnHeader textAlign="left"> </ColumnHeader>,
        cell: ({ row }) => (
          <Flex gap="8px" justifyContent="flex-end">
            <IconButton
              variant="onlyIcon"
              size="sm"
              icon={<PencilIcon />}
              aria-label="Редактировать"
              title="Редактировать"
              onClick={() => handleEditOrg?.(row)}
            />
            <IconButton
              variant="onlyIcon"
              size="sm"
              icon={<TrashIcon />}
              aria-label="Удалить"
              title="Удалить"
              onClick={() => handleRemoveOrg?.(row)}
            />
          </Flex>
        ),
      },
    ],
    [handleEditOrg, handleRemoveOrg],
  );
