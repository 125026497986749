import { MOSCOW_CODE_NUMBER, NON_DIGITS_REGEXP } from "./constants";
import { XlsDataError } from "./types";

export const fixPhone = (phone: number | string) => {
  const phoneStrOnlyDigits = String(phone).replace(NON_DIGITS_REGEXP, "");

  if (phoneStrOnlyDigits.length === 7) {
    return Number(`${MOSCOW_CODE_NUMBER}${phoneStrOnlyDigits}`);
  }
  if (phoneStrOnlyDigits.length === 10) {
    return Number(`7${phoneStrOnlyDigits}`);
  }
  if (phoneStrOnlyDigits.length === 11 && phoneStrOnlyDigits.startsWith("8")) {
    return Number(`7${phoneStrOnlyDigits.substring(1, 11)}`);
  }
  if (phoneStrOnlyDigits.length > 11) {
    return Number(phoneStrOnlyDigits.substring(0, 11));
  }
  if (phoneStrOnlyDigits.length !== 11) {
    return null;
  }

  return Number(phoneStrOnlyDigits);
};

export const getParseErrorMessage = (err: XlsDataError) => {
  if (err) {
    const { rowId, staff, phone, notLinkedAttracted } = err;

    return `Строка ${rowId}: ${staff ? "не указан номер сотрудника" : ""}${staff && phone ? ", " : ""}${
      phone ? "неправильный телефон" : ""
    } ${(staff || phone) && notLinkedAttracted ? ", " : ""} ${
      notLinkedAttracted ? "у привлеченного не указан сотрудник" : ""
    }\n`;
  }

  return "";
};
