import { Flex, Text } from "@chakra-ui/react";

type AccordionDescriptionProps = {
  total: number;
  workers: number;
  attracted: number;
  arch_total?: number;
  arch_workers?: number;
  arch_attracted?: number;
};

export const AccordionDescription = ({
  total,
  workers,
  attracted,
  arch_total,
  arch_workers,
  arch_attracted,
}: AccordionDescriptionProps) => {
  const options = [
    { value: total, label: "всего" },
    { value: workers, label: "сотрудников" },
    { value: attracted, label: "дополнительных телефонов" },
  ];

  const archOptions = [
    { value: arch_total, label: "всего" },
    { value: arch_workers, label: "сотрудников" },
    { value: arch_attracted, label: "дополнительных телефонов" },
  ];

  return (
    <Flex gap="10px">
      {options.map((o, i) => (
        <Flex gap="5px" key={i}>
          <Text textStyle="textLight" color="custom.blue.dark.100">
            {o.label}:
          </Text>
          <Text textStyle="textBold" color="custom.blue.dark.100">
            {o.value}
          </Text>
        </Flex>
      ))}
      {archOptions[0].value ? (
        <>
          <Text textStyle="textLight" color="custom.blue.dark.100">
            |
          </Text>
          <Text textStyle="textBold" color="custom.blue.dark.200">
            В 2024:
          </Text>
          {archOptions.map((o, i) => (
            <Flex gap="5px" key={i}>
              <Text textStyle="textLight" color="custom.blue.dark.200">
                {o.label}:
              </Text>
              <Text textStyle="textBold" color="custom.blue.dark.200">
                {o.value}
              </Text>
            </Flex>
          ))}
        </>
      ) : null}
    </Flex>
  );
};
