import { atom, useAtom } from "jotai";

import { OblbuhData } from "./api";

export const usersSelectedAtom = atom<OblbuhData[]>([]);

export const syncUsers = atom<{ id: string; sync: boolean }>({ id: "0", sync: false });
export const creatingReport = atom<{ filename: string; isFetching: boolean }>({ filename: "", isFetching: false });

export const useUsersSelectedAtoms = () => {
  const [usersSelected, setUsersSelected] = useAtom(usersSelectedAtom);

  const onUsersSelected = (user: OblbuhData) => {
    const hasUser = usersSelected.some((u) => user.id === u.id);
    const remove = usersSelected.filter((u) => user.id !== u.id);
    const add = usersSelected.concat(user);
    const newUsersSelected = hasUser ? remove : add;
    setUsersSelected(newUsersSelected);
  };

  const isUserSelected = (user?: OblbuhData) => usersSelected.some((u) => u.id === user?.id);

  return {
    setUsersSelected,
    onUsersSelected,
    isUserSelected,
    usersSelected,
  };
};

export const orgSelectedAtom = atom<number[]>([]);

export const useOrgSelectedAtoms = () => {
  const [orgSelected, setOrgSelected] = useAtom(orgSelectedAtom);

  const onOrgSelected = (orgId: number) => {
    const hasOrg = orgSelected.some((id) => id === orgId);
    const remove = orgSelected.filter((id) => id !== orgId);
    const add = orgSelected.concat(orgId);
    const newOrgSelected = hasOrg ? remove : add;
    setOrgSelected(newOrgSelected);
  };

  const isOrgSelected = (orgId?: number) => orgSelected.some((id) => id === orgId);

  return {
    setOrgSelected,
    onOrgSelected,
    isOrgSelected,
    orgSelected,
  };
};
