import { flexRender, Table } from "@tanstack/react-table";
import { VirtualItem } from "@tanstack/react-virtual";
import { Td, Tr } from "@chakra-ui/react";

interface Props<T> {
  table: Table<T>;
  virtualRows: VirtualItem<HTMLElement>[];
}

export const TableContent = <T,>({ table, virtualRows }: Props<T>) => {
  return (
    <>
      {virtualRows.map((virtualRow) => {
        const { rows } = table.getRowModel();
        const row = rows[virtualRow.index];
        return (
          <Tr
            key={row.id}
            borderBottom="1px solid var(--chakra-colors-custom-purple-light-200)"
            bgColor={row.getIsSelected() ? "custom.purple.light.100" : "transparent"}
            _hover={{
              bgColor: "custom.purple.light.100",
            }}
            h="39px"
          >
            {row.getVisibleCells().map((cell) => (
              <Td key={cell.id} p={1} _first={{ pl: 4 }}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </Tr>
        );
      })}
    </>
  );
};
