import { ValidationErrors } from "final-form";
import { Form } from "react-final-form";
import {
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  IconButton,
  ModalBody,
  Button,
  Modal,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useAtomValue } from "jotai";

import { SelectOrgField } from "features/dictionaries/components/fields/select-org-field";
import { getNoun } from "shared/lib/string";
import { CheckIcon, CrossIcon, PaperclipIcon } from "shared/ui/icons";
import { useMoveOblbuhMutation } from "features/users/api";
import { SelectOption } from "shared/form/select-v2";
import { useOrgSelectedAtoms, useUsersSelectedAtoms } from "features/users/atoms";
import { readOnlyModeAtom } from "features/layout/atoms";

export type FormPayload = {
  new_org: SelectOption;
};

export function MoveUserModalIconButton() {
  const { usersSelected, setUsersSelected } = useUsersSelectedAtoms();
  const { orgSelected, setOrgSelected } = useOrgSelectedAtoms();
  const readOnlyMode = useAtomValue(readOnlyModeAtom);
  const isNothingSelected = usersSelected.length + orgSelected.length === 0;

  const haveAttractedUsers = usersSelected.some((user) => user.parent_phone);
  const haveUsers = usersSelected.length > 0;
  const haveOrgs = orgSelected.length > 0;
  const userSelectedLabel = haveUsers
    ? `${usersSelected.length} ${getNoun(usersSelected.length, "cотрудника", "сотрудников", "сотрудников")}`
    : "";
  const orgSelectedLabel = haveOrgs
    ? `${orgSelected.length} ${getNoun(
        orgSelected.length,
        "элемента уровня 3",
        "элементов уровня 3",
        "элементов уровня 3",
      )}`
    : "";

  const unionLabel = haveUsers && haveOrgs ? " и " : "";

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isLoading, mutateAsync: move } = useMoveOblbuhMutation();

  const handleOnSubmit = async (values: FormPayload) => {
    const data = {
      new_org_id: +values.new_org.value,
      ...(usersSelected.length && { ids: usersSelected.map((user) => user.id) }),
      ...(orgSelected.length && { old_org_ids: orgSelected }),
    };
    move(data, {
      onSuccess: () => {
        onClose();
        setUsersSelected([]);
        setOrgSelected([]);
      },
    });
  };

  const validate = async (v: FormPayload) => {
    const errors: ValidationErrors = {};
    if (!v.new_org) errors.org = "Выберите уровень 3";
    return errors;
  };

  return (
    <>
      <IconButton
        variant="rounded"
        size="sm"
        icon={<PaperclipIcon color="white" />}
        aria-label="Переместить сотрудника или уровень 3"
        title="Переместить сотрудника или уровень 3"
        onClick={onOpen}
        {...((readOnlyMode || isNothingSelected || haveAttractedUsers) && {
          isDisabled: readOnlyMode || isNothingSelected || haveAttractedUsers,
        })}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <Form<FormPayload>
          onSubmit={handleOnSubmit}
          validate={validate}
          render={({ handleSubmit: submit, form, dirty, values }) => {
            return (
              <form onSubmit={submit}>
                <ModalContent py="30px" px="50px" minW="540px" gap="20px">
                  <ModalBody p="unset">
                    <Flex flexDir="column" align="center" gap="20px">
                      <Text textAlign="center" color="custom.grey.dark.100" textStyle="h1" textTransform="uppercase">
                        Перемещение {userSelectedLabel} {unionLabel} {orgSelectedLabel}
                      </Text>
                      <SelectOrgField
                        name="new_org"
                        selectProps={{ isMulti: false }}
                        placeholder="ВЫБЕРИТЕ ЦЕЛЕВОЙ УРОВЕНЬ 3..."
                      />
                      <Text textStyle="button" textAlign="center" color="custom.grey.dark.100">
                        при перемещении сотрудников будут
                        <br />
                        перемещены и их дополнительные телефоны!
                      </Text>
                    </Flex>
                  </ModalBody>

                  <ModalFooter p="unset">
                    <Flex
                      w="full"
                      justifyContent="center"
                      gap="20px"
                      bgColor="custom.grey.light.100"
                      py="20px"
                      px="30px"
                      borderTopWidth={0}
                      borderRadius={15}
                    >
                      <Button
                        minW="165px"
                        leftIcon={<CheckIcon />}
                        onClick={form.submit}
                        isDisabled={!dirty}
                        isLoading={isLoading}
                      >
                        Ок
                      </Button>
                      <Button minW="165px" isDisabled={isLoading} leftIcon={<CrossIcon />} onClick={onClose}>
                        Отмена
                      </Button>
                    </Flex>
                  </ModalFooter>
                </ModalContent>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
}
