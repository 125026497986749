import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryError } from "api/types";

import { setReadOnlyStatus } from "./api";

export function useSetReadOnlyStatus() {
  const queryClient = useQueryClient();
  return useMutation<{ is_read_only: boolean }, QueryError, boolean>((param: boolean) => setReadOnlyStatus(param), {
    onSuccess: () => {
      queryClient.invalidateQueries(["readOnlyStatus"]);
    },
  });
}
