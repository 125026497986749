import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Text } from "@chakra-ui/react";

import { StringWithPercentagesFooter } from "shared/ui/table/footer/string-with-percentages";
import { StringWithPercentagesCell } from "shared/ui/table/cells/string-with-percentages";
import { StringCell } from "shared/ui/table/cells/string";

import { ColumnHeader } from "../components/column-header";
import { EmployeeData } from "../api/types";

export type UseColumnsProps = {
  voting?: number;
  sortedData: {
    all_members: number[];
    potentially_resources_1: number[];
    attr_1: number[];
    potentially_resources_3: number[];
    attr_3: number[];
  };
};

export const useEmployeeColumns = ({ voting, sortedData }: UseColumnsProps) =>
  useMemo<ColumnDef<EmployeeData>[]>(
    () => [
      {
        accessorKey: "sphere",
        size: 100,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">уровень 1</ColumnHeader>,
        cell: StringCell,
        footer: () => (
          <Text textStyle="textBold" textAlign="left" color="custom.blue.dark.100">
            Итого
          </Text>
        ),
      },
      {
        accessorKey: "oiv",
        size: 180,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">уровень 2</ColumnHeader>,
        cell: StringCell,
      },
      {
        accessorKey: "org",
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">уровень 3</ColumnHeader>,
        cell: StringCell,
      },
      {
        accessorKey: "all_members",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader>всего</ColumnHeader>,
        cell: (cell) => <StringCell {...cell} textAlign="center" ml="-30px" />,
        footer: (cell) => (
          <StringWithPercentagesFooter cell={cell} isPercent={false} metaKey="all_members" type="more-better" />
        ),
      },
      {
        accessorKey: "attr_1_perc",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader>имеют хотя бы 1 привлеченного</ColumnHeader>,
        cell: (cell) => (
          <StringWithPercentagesCell
            cell={cell}
            accessorKey="attr_1"
            sortedData={sortedData.attr_1}
            type="more-better"
          />
        ),
        footer: (cell) => <StringWithPercentagesFooter cell={cell} metaKey="attr_1" type="more-better" />,
      },
      {
        accessorKey: "potentially_resources_1",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader>потенциальный ресурс +1</ColumnHeader>,
        cell: (cell) => <StringCell {...cell} textAlign="center" ml="-30px" />,
        footer: (cell) => (
          <StringWithPercentagesFooter
            cell={cell}
            isPercent={false}
            metaKey="potentially_resources_1"
            type="more-better"
          />
        ),
      },
      {
        accessorKey: "attr_3_perc",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader>имеют хотя бы 3 привлеченных</ColumnHeader>,
        cell: (cell) => (
          <StringWithPercentagesCell
            cell={cell}
            accessorKey="attr_3"
            sortedData={sortedData.attr_3}
            type="more-better"
          />
        ),
        footer: (cell) => <StringWithPercentagesFooter cell={cell} metaKey="attr_3" type="more-better" />,
      },
      {
        accessorKey: "potentially_resources_3",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader>потенциальный ресурс +3</ColumnHeader>,
        cell: (cell) => <StringCell {...cell} textAlign="center" ml="-30px" />,
        footer: (cell) => (
          <StringWithPercentagesFooter
            cell={cell}
            isPercent={false}
            metaKey="potentially_resources_3"
            type="more-better"
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      sortedData.all_members,
      sortedData.attr_1,
      sortedData.attr_3,
      sortedData.potentially_resources_1,
      sortedData.potentially_resources_3,
      voting,
    ],
  );
