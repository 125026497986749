import qs from "qs";

import { apiInstance, authInstance, statInstance } from "api/client";

import { DictAdminQuery, DictParams, DictQuery, DictStatParams, DictStatQuery } from "./types";

export const getDict = async ({ name, ...params }: DictParams) => {
  const queryString = qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
  const url = `/dict/${name}?${queryString}`;
  const { data } = await statInstance.get<DictQuery>(url);
  return data.result;
};

export const getAdminDict = async ({ name, ...params }: DictParams) => {
  const queryString = qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
  const url = `/dict/${name}?${queryString}`;
  const { data } = await apiInstance.get<DictAdminQuery>(url);
  return data.result;
};

export const getDictStat = async ({ name, ...params }: DictStatParams) => {
  const queryString = qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
  const url = `/short_stat/${name}?${queryString}`;
  const { data } = await statInstance.get<DictStatQuery>(url);
  return data.result;
};

export const getReadOnlyStatus = async () => {
  const url = "/status";
  const { data } = await authInstance.get<{ is_read_only: boolean }>(url);
  return data.result.is_read_only;
};

export const setReadOnlyStatus = async (param: boolean) => {
  const url = "admin/system/status";
  const { data } = await apiInstance.post<{ is_read_only: boolean }>(url, { is_read_only: param });
  return data.result;
};
