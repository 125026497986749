import { forwardRef } from "@chakra-ui/react";
import { useField, UseFieldConfig } from "react-final-form";
import { PropsValue } from "react-select";

import { FormControl, FormControlProps } from "../form-control";

import { CustomSelectProps, Select } from "./select/select";
import { SelectOption } from "./select/types";

export interface SelectFieldProps extends Omit<FormControlProps, "isInvalid" | "onChange"> {
  name: string;
  options: SelectOption[];
  components?: CustomSelectProps["components"];
  valueLabel?: string;
  fieldConfig?: UseFieldConfig<PropsValue<SelectOption>, PropsValue<SelectOption>>;
  selectProps?: Omit<CustomSelectProps, "value" | "onChange">;
  isSearchable?: boolean;
  placeholder?: string;
  variant?: string;
  onChange?: (v: PropsValue<SelectOption>) => void;
  label?: string;
}

export const SelectField = forwardRef<SelectFieldProps, "select">(
  (
    {
      name,
      label,
      fieldConfig,
      caption,
      placeholder,
      autoComplete,
      valueLabel,
      defaultValue,
      isSearchable,
      selectProps,
      variant,
      onChange,
      components,
      ...props
    },
    ref,
  ) => {
    const { input, meta } = useField<PropsValue<SelectOption>, HTMLInputElement, PropsValue<SelectOption>>(
      name,
      fieldConfig,
    );

    return (
      <FormControl
        label={label}
        isInvalid={meta.touched && !!meta.error && meta.submitFailed}
        caption={(meta.touched && meta.error) || caption}
        {...props}
      >
        <Select
          {...props}
          {...input}
          onChange={(v) => {
            onChange?.(v);
            input.onChange(v);
          }}
          {...selectProps}
          placeholder={placeholder}
          valueLabel={valueLabel}
          isSearchable={isSearchable}
          variant={variant}
          components={components}
        />
      </FormControl>
    );
  },
);
