import { RecursiveProperty } from "@chakra-ui/react";

// interfaces from chakra [not exported=(]
interface RecursiveObject<T = string | number> {
  [property: string]: RecursiveProperty<T>;
}
interface Typography {
  fonts: RecursiveObject<string>;
  fontSizes: RecursiveObject;
  fontWeights: RecursiveObject;
  letterSpacings: RecursiveObject;
  lineHeights: RecursiveObject;
}

export const typography: Partial<Typography> = {
  fonts: {
    body: "'Roboto'",
    heading: "'Roboto'",
  },
};
