import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const LogoutIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="m16 17-1.375-1.45 2.55-2.55H9v-2h8.175l-2.55-2.55L16 7l5 5-5 5ZM3 21V3h9v2H5v14h7v2H3Z"
      />
    </Icon>
  );
};
