import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const BallotPaperIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M4.056 17.253c-.428 0-.795-.153-1.1-.458a1.495 1.495 0 0 1-.456-1.098v-3.539l2.139-2.43 1.108 1.108-1.555 1.75h10.616l-1.516-1.711L14.4 9.767l2.1 2.391v3.54c0 .427-.152.793-.457 1.098a1.495 1.495 0 0 1-1.099.457H4.056Zm4.355-5.911L5.708 8.6a1.505 1.505 0 0 1-.447-1.108c0-.441.15-.81.447-1.109l3.811-3.81a1.505 1.505 0 0 1 1.109-.448c.44 0 .81.15 1.108.447l2.742 2.703c.298.298.45.664.457 1.098.007.434-.14.8-.438 1.1l-3.889 3.888a1.462 1.462 0 0 1-1.099.438 1.523 1.523 0 0 1-1.098-.457Zm4.978-4.978-2.761-2.722-3.85 3.85 2.76 2.722 3.85-3.85Z"
      />
    </Icon>
  );
};
