import {
  GroupBase,
  MultiValue as MultiValueType,
  OptionsOrGroups,
  Props,
  default as ReactSelect,
  SingleValue,
} from "react-select";

import { Control } from "./control";
import { Option } from "./option";
import { MultiValue } from "./multi-value";
import { ValueContainer } from "./value-container";
import { ClearIndicator } from "./clear-indicator";
import { DropdownIndicator } from "./dropdown-indicator";
import { SelectOption } from "./types";
import { customTheme } from "./theme";
import { customStyles } from "./styles";
import { allOption } from "./constants";

declare module "react-select/dist/declarations/src/Select" {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    valueLabel?: string;
  }
}

export type CustomSelectProps = Props<SelectOption> & {
  valueLabel?: string;
  variant?: string;
  allowSelectAll?: boolean;
  options?: OptionsOrGroups<SelectOption<string>, GroupBase<SelectOption<string>>>;
  onChange: (v: SingleValue<SelectOption<string>> | MultiValueType<SelectOption<string>>) => void;
};

export const Select = ({ variant = "default", valueLabel, components, ...props }: CustomSelectProps) => {
  if (props.allowSelectAll) {
    return (
      <ReactSelect
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable
        {...props}
        valueLabel={valueLabel}
        components={{
          Control,
          Option,
          MultiValue,
          ValueContainer,
          ClearIndicator,
          DropdownIndicator,
          IndicatorSeparator: null,
          ...components,
        }}
        theme={customTheme()}
        styles={customStyles({ variant })}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options={[allOption, ...props.options]}
        onChange={(selected, event) => {
          if (selected !== null && Array.isArray(selected) && Array.isArray(props.options) && selected.length > 0) {
            // (selected.length - 1) -- исключаем из кол-ва выделенных саму опцию Выделить всё
            if (event.option?.value === allOption.value && selected.length - 1 === props.options.length) {
              return props.onChange([]);
            } else if (selected[selected.length - 1].value === allOption.value) {
              return props.onChange([...props.options]);
            }
            let result = [];
            if (selected.length === props.options.length) {
              if (selected.includes(allOption)) {
                result = selected.filter((option) => option.value !== allOption.value);
              } else if (event.action === "select-option") {
                result = [...props.options];
              }
              return props.onChange(result);
            }
          }

          return props.onChange(selected);
        }}
      />
    );
  }

  return (
    <ReactSelect
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable
      {...props}
      valueLabel={valueLabel}
      components={{
        Control,
        Option,
        MultiValue,
        ValueContainer,
        ClearIndicator,
        DropdownIndicator,
        IndicatorSeparator: null,
        ...components,
      }}
      theme={customTheme()}
      styles={customStyles({ variant })}
    />
  );
};
