import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const PassportNumIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#0A0077" rx="2" />
      <path
        fill="#FAFAFF"
        d="M9.495 3.783a.469.469 0 1 0-.928-.132l-.21 1.475H6.179l.191-1.341a.469.469 0 1 0-.928-.132l-.21 1.472h-.888a.469.469 0 0 0 0 .938h.753L4.83 7.938H3.719a.469.469 0 0 0 0 .937h.978l-.191 1.34a.468.468 0 1 0 .928.133l.21-1.473h2.178l-.191 1.34a.468.468 0 1 0 .928.133l.21-1.473h.887a.469.469 0 1 0 0-.937h-.754l.268-1.875h1.111a.469.469 0 1 0 0-.937h-.977l.191-1.343Zm-1.272 2.28-.267 1.875H5.777l.268-1.875h2.178Z"
      />
    </Icon>
  );
};
