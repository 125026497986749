import { IconButton } from "@chakra-ui/react";

import { STAT_TYPE, useGetExportStats } from "features/stats/api";
import { DownloadIcon } from "shared/ui/icons";
import { Filter } from "features/stats/pages";

export function ExportStatIconButton({ filter, voting }: { filter: Filter; voting: 1 | 2 | 3 }) {
  const isRelativeType = filter.name.endsWith("_relative");

  const { isLoading: isLoadingExportStats, mutateAsync: exportReport } = useGetExportStats({
    filename: getReportTypeLabel(voting, isRelativeType),
  });

  const handleExportStats = () => {
    const type = getReportType(voting, isRelativeType);
    exportReport({ type });
  };

  return (
    <IconButton
      variant="icon"
      size="icon"
      icon={<DownloadIcon color="custom.purple.200" />}
      aria-label="Скачать отчёт"
      title="Скачать отчёт"
      isLoading={isLoadingExportStats}
      onClick={handleExportStats}
      _hover={{ bg: "custom.purple.light.200" }}
    />
  );
}

const getReportTypeLabel = (voting: 1 | 2 | 3, isRelativeType: boolean) => {
  const relativeLabel = isRelativeType ? "дополнительные_телефоны" : "сотрудники";

  if (voting === 1) {
    return relativeLabel + "_заполненность_кабинетов";
  }
  if (voting === 2) {
    return relativeLabel + "_период_голосования";
  }
  if (voting === 3) {
    return "потенциальный_ресурс";
  }

  return "сотрудники_заполненность_кабинетов";
};

const getReportType = (voting: 1 | 2 | 3, isRelativeType: boolean) => {
  if (voting === 1) {
    return isRelativeType ? STAT_TYPE.RELATIVE : STAT_TYPE.EMPLOYEES;
  }
  if (voting === 2) {
    return isRelativeType ? STAT_TYPE.RELATIVE_VOTED : STAT_TYPE.EMPLOYEES_VOTED;
  }
  if (voting === 3) {
    return STAT_TYPE.RESOURCE;
  }

  return STAT_TYPE.EMPLOYEES;
};
