import { ModalOverlay, ModalContent, ModalFooter, ModalBody, Button, Modal, Flex, Text } from "@chakra-ui/react";

import { CheckIcon, CrossIcon } from "shared/ui/icons";

import { ToBeRemovedOrgProps } from "../pages/organizations";
import { useRemoveOrgMutation } from "../api/mutations";

interface Props {
  toBeRemovedOrg?: ToBeRemovedOrgProps;
  isOpen: boolean;
  onClose: () => void;
}

export function RemoveOrgModal({ toBeRemovedOrg, isOpen, onClose }: Props) {
  const { isLoading, mutateAsync: removeOrgs } = useRemoveOrgMutation();

  const handleOnRemoveClick = async () => {
    if (toBeRemovedOrg?.org_id) {
      removeOrgs(
        { ids: [toBeRemovedOrg.org_id] },
        {
          onSuccess: onClose,
        },
      );
    }
    // const ids = usersSelected.map((u) => u.id);
    // const org_ids = orgSelected;
    // const params: RemoveOblbuhParams = { ids };
    // if (org_ids.length) params.org_ids = org_ids;
    // remover(params, {
    //   onSuccess: () => {
    //     onClose();
    //   },
    // });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent py="30px" px="50px" minW="540px" gap="20px">
        <ModalBody p="unset">
          <Flex flexDir="column" align="center" textTransform="uppercase" gap="20px">
            <Text textAlign="center" color="custom.grey.dark.100" textStyle="h1">
              Удаление организации
            </Text>
            <Text textAlign="center" color="custom.grey.dark.100" textStyle="textLight">
              Вы действительно хотите удалить &quot;{toBeRemovedOrg?.org}&quot;
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter p="unset">
          <Flex
            w="full"
            justifyContent="center"
            gap="20px"
            bgColor="custom.grey.light.100"
            py="20px"
            px="30px"
            borderTopWidth={0}
            borderRadius={15}
          >
            <Button minW="165px" leftIcon={<CheckIcon />} onClick={handleOnRemoveClick} isLoading={isLoading}>
              Удалить
            </Button>
            <Button minW="165px" isDisabled={isLoading} leftIcon={<CrossIcon />} onClick={onClose}>
              Отмена
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
