import { MouseEvent } from "react";
import { components, OptionProps } from "react-select";
import { Checkbox, Flex, Text } from "@chakra-ui/react";

import { SelectOption } from "./types";
import { allOption } from "./constants";

export const Option = (props: OptionProps<SelectOption>) => {
  const {
    selectProps: { isMulti },
  } = props;

  const handleChange = (event: MouseEvent<HTMLDivElement>) => {
    props.selectOption({ ...props.data });
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <components.Option {...props}>
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        gap="15px"
        onClick={handleChange}
        cursor={props.data.value === allOption.value ? "pointer" : "auto"}
      >
        {isMulti && props.data.value !== allOption.value ? <Checkbox isChecked={props.isSelected} px="10px" /> : null}
        {props.data.value === allOption.value ? <Flex w="40px" /> : null}
        <Text as="label" cursor={props.data.value === allOption.value ? "pointer" : "auto"}>
          {props.label}
        </Text>
      </Flex>
    </components.Option>
  );
};
