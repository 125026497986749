import { ReactNode, PropsWithChildren, FC } from "react";
import { FormControl as ChakraFormControl, FormControlProps, FormErrorMessage, FormLabel } from "@chakra-ui/react";

type OwnProps = {
  // field label
  label?: ReactNode;
  formControlLabel?: string;
  caption?: ReactNode;
};

export type Props = PropsWithChildren<Omit<FormControlProps, keyof OwnProps> & OwnProps>;

export const FormControl: FC<Props> = ({
  children,
  label,
  caption,
  isInvalid,
  formControlLabel,
  ...formControlProps
}) => {
  return (
    <ChakraFormControl label={formControlLabel} isInvalid={isInvalid} {...formControlProps}>
      {!!label && <FormLabel>{label}</FormLabel>}
      {children}
      {!!caption && <>{isInvalid ? <FormErrorMessage>{caption}</FormErrorMessage> : null}</>}
    </ChakraFormControl>
  );
};
