import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const PersonIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M3.25 17.5S2 17.5 2 16.25s1.25-5 7.5-5 7.5 3.75 7.5 5-1.25 1.25-1.25 1.25H3.25ZM9.5 10a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
      />
    </Icon>
  );
};
