import { UseInfiniteQueryOptions, useInfiniteQuery } from "@tanstack/react-query";

import { QueryError } from "api/types";

import { EmployeeQuery, StatParams, StatQuery } from "./types";
import { STAT_QUERY_KEYS } from "./keys";
import { getResouceStat, getStat } from "./api";

export function useStatInfiniteQuery(params: StatParams, options: UseInfiniteQueryOptions<StatQuery, QueryError> = {}) {
  return useInfiniteQuery<StatQuery, QueryError>(
    [STAT_QUERY_KEYS.STAT_INFINITE, params],
    async ({ pageParam = 0 }) => getStat({ ...params, offset: pageParam * params.limit }),
    {
      keepPreviousData: true,
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        const allCount = allPages.flatMap(({ data }) => data).length;
        if (lastPage.count > allCount) return allPages.length;
        return false;
      },
    },
  );
}

export function useResourceStatInfiniteQuery(
  params: StatParams,
  options: UseInfiniteQueryOptions<EmployeeQuery, QueryError> = {},
) {
  return useInfiniteQuery<EmployeeQuery, QueryError>(
    [STAT_QUERY_KEYS.RECOURCE_STAT_INFINITE, params],
    async ({ pageParam = 0 }) => getResouceStat({ ...params, offset: pageParam * params.limit }),
    {
      keepPreviousData: true,
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        const allCount = allPages.flatMap(({ data }) => data).length;
        if (lastPage.count > allCount) return allPages.length;
        return false;
      },
    },
  );
}
