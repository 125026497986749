import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Form } from "react-final-form";
import { z } from "zod";

import { CheckIcon, CrossIcon } from "shared/ui/icons";
import { SelectDictOivStatField } from "features/dictionaries/components/fields/select-dict-oiv-stat-field";
import { InputField } from "shared/form/input/field";
import { EditOrgProps } from "features/admin/pages/organizations";
import { SelectOption } from "shared/form/select-v2";
import { useAddOrgMutation, useUpdateOrgMutation } from "features/admin/api/mutations";
import { formValidator } from "shared/form/form-validator";

interface Props {
  org?: EditOrgProps;
  isOpen: boolean;
  onClose: () => void;
}

interface FormPayload {
  oiv: SelectOption;
  org: string;
  inn: string;
}

const validationSchema = z.object({
  oiv: z.object(
    {
      value: z.string(),
      label: z.any(),
    },
    { required_error: "Обязательное поле", invalid_type_error: "Обязательное поле" },
  ),
  org: z
    .string({
      required_error: "Обязательное поле",
    })
    .trim()
    .min(1, { message: "Обязательное поле" }),
  inn: z
    .string({
      required_error: "Обязательное поле",
    })
    .trim()
    .min(10, { message: "От 10 до 12 цифр" })
    .max(12, { message: "От 10 до 12 цифр" }),
});

export function OrgModal({ org, isOpen, onClose }: Props) {
  const updateOrg = useUpdateOrgMutation();
  const addOrg = useAddOrgMutation();

  const isLoading = updateOrg.isLoading || addOrg.isLoading;

  const isEditMode = !!org?.org_id;

  const initialValues = isEditMode
    ? {
        org: org.org,
        inn: org.inn,
        oiv: { label: org.oiv, value: String(org.oiv_id) },
      }
    : {};

  const handleUpdate = (values: FormPayload) => {
    if (org?.org_id) {
      const payload = {
        id: org.org_id,
        title: values.org.trim(),
        inn: values.inn.trim(),
        oiv_id: Number(values.oiv.value),
      };

      updateOrg.mutateAsync(payload, { onSuccess: onClose });
    }
  };

  const handleCreate = (values: FormPayload) => {
    if (values.org.trim() && values.inn.trim() && values.oiv.value) {
      const payload = {
        title: values.org.trim(),
        inn: values.inn.trim(),
        oiv_id: Number(values.oiv.value),
      };
      addOrg.mutateAsync(payload, { onSuccess: onClose });
    }
  };

  const handleSubmitForm = (values: FormPayload) => {
    return isEditMode ? handleUpdate(values) : handleCreate(values);
  };

  const validate = formValidator(validationSchema);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent py="30px" px="50px" minW="540px" gap="20px">
        <ModalBody p="unset">
          <Flex flexDir="column" align="center" textTransform="uppercase" gap="20px" mb="20px">
            <Text textAlign="center" color="custom.grey.dark.100" textStyle="h1">
              {`${isEditMode ? "Редактировать" : "Добавить"} организацию`}
            </Text>
          </Flex>
          <Form<FormPayload>
            initialValues={initialValues}
            validate={validate}
            onSubmit={handleSubmitForm}
            render={({ handleSubmit, submitError, values }) => {
              return (
                <Box as="form" id="org-form" onSubmit={handleSubmit}>
                  <Flex flexDir="column" gap="20px">
                    <SelectDictOivStatField name="oiv" selectProps={{ isMulti: false, closeMenuOnSelect: true }} />
                    <InputField
                      name="org"
                      label="Организация"
                      fieldConfig={{ parse: (value) => value.replace(/[\'\"\`]/g, "") }}
                    />
                    <InputField
                      name="inn"
                      label="ИНН"
                      fieldConfig={{ parse: (value) => value.replace(/\D/g, "") }}
                      inputProps={{
                        maxLength: 12,
                      }}
                    />
                  </Flex>
                  <VStack spacing={4}>
                    <Text color="system.error">{submitError}</Text>
                  </VStack>
                </Box>
              );
            }}
          />
        </ModalBody>
        <ModalFooter p="unset">
          <Flex
            w="full"
            justifyContent="center"
            gap="20px"
            bgColor="custom.grey.light.100"
            py="20px"
            px="30px"
            borderTopWidth={0}
            borderRadius={15}
          >
            <Button form="org-form" type="submit" minW="165px" leftIcon={<CheckIcon />} isLoading={isLoading}>
              {isEditMode ? "Сохранить" : "Добавить"}
            </Button>
            <Button
              form="org-form"
              type="reset"
              minW="165px"
              isDisabled={isLoading}
              leftIcon={<CrossIcon />}
              onClick={onClose}
            >
              Отмена
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
