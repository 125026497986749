import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const PencilIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M12.031 11.93H1.97a.437.437 0 0 0-.438.437v.492c0 .06.05.11.11.11h10.718c.06 0 .11-.05.11-.11v-.492a.437.437 0 0 0-.438-.437ZM3.523 10.78a.553.553 0 0 0 .082-.007l2.3-.403a.134.134 0 0 0 .072-.038l5.796-5.796a.136.136 0 0 0 0-.192L9.5 2.07a.135.135 0 0 0-.097-.04.135.135 0 0 0-.098.04L3.511 7.866a.139.139 0 0 0-.038.073l-.404 2.3a.458.458 0 0 0 .129.407c.09.087.204.135.325.135Z"
      />
    </Icon>
  );
};
