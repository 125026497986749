import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const TrashIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M16.25 5.001H12.5V3.749A1.815 1.815 0 0 0 10.625 2h-2.25A1.815 1.815 0 0 0 6.5 3.749V5H2.75a.75.75 0 0 0 0 1.5h.75v8.25a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-8.25h.75a.75.75 0 1 0 0-1.5ZM8 3.749c0-.12.158-.248.375-.248h2.25c.217 0 .375.128.375.248V5H8V3.749Z"
      />
    </Icon>
  );
};
