import { UseFieldConfig, useField } from "react-final-form";
import isEqual from "lodash/isEqual";

import { TripleSwitcher, TripleSwitcherProps } from "shared/form/triple-switcher";
import { SWITCHER_STAT_ENTITY_NAMES, SWITCHER_STAT_ENTITY_NAMES_STRING } from "features/stats/api";
import { Switcher } from "shared/form/switch";
import { StateThreeIcon, PersonPlusIcon, StateOneIcon, StateTwoIcon, PersonIcon } from "shared/ui/icons";
import { DoubleSwitcher } from "shared/form/double-switcher";
import { useAccessController } from "config/routes/router-provider";
import { ROLES } from "shared/constants";

export type DefineType = { stats: TripleSwitcherProps["value"]; person: boolean };
const defineTypeSettings: Record<SWITCHER_STAT_ENTITY_NAMES, DefineType> = {
  org: { stats: 3, person: false },
  org_relative: { stats: 3, person: true },
  oiv: { stats: 2, person: false },
  oiv_relative: { stats: 2, person: true },
  sphere_relative: { stats: 1, person: true },
  sphere: { stats: 1, person: false },
};

interface Props {
  name: string;
  isEmployee: boolean;
  fieldConfig?: UseFieldConfig<SWITCHER_STAT_ENTITY_NAMES, SWITCHER_STAT_ENTITY_NAMES>;
  onChange?: (v: SWITCHER_STAT_ENTITY_NAMES, data: DefineType) => void;
}

export function DefineTypeSwitchGroupFiled({ name, isEmployee, fieldConfig, onChange }: Props) {
  const { access } = useAccessController();
  const currentUserRoleId = access?.roles?.[0].id;
  const isDTOIVRole = currentUserRoleId === ROLES.DTOIV;
  const isSphereRole = currentUserRoleId === ROLES.SPHERE;

  const { input } = useField<SWITCHER_STAT_ENTITY_NAMES, HTMLElement>(name, fieldConfig);

  const handleOnChange = (key: keyof DefineType, value: unknown) => {
    const nextValues = { ...defineTypeSettings[input.value], [key]: value };
    const validate = (name: SWITCHER_STAT_ENTITY_NAMES_STRING) => {
      const prevValues = defineTypeSettings[SWITCHER_STAT_ENTITY_NAMES[name]];
      return isEqual(nextValues, prevValues);
    };

    const getNewValue = () => {
      switch (true) {
        case validate("ORG"):
          return SWITCHER_STAT_ENTITY_NAMES.ORG;
        case validate("ORG_RELATIVE"):
          return SWITCHER_STAT_ENTITY_NAMES.ORG_RELATIVE;
        case validate("OIV"):
          return SWITCHER_STAT_ENTITY_NAMES.OIV;
        case validate("OIV_RELATIVE"):
          return SWITCHER_STAT_ENTITY_NAMES.OIV_RELATIVE;
        case validate("SPHERE"):
          return SWITCHER_STAT_ENTITY_NAMES.SPHERE;
        case validate("SPHERE_RELATIVE"):
          return SWITCHER_STAT_ENTITY_NAMES.SPHERE_RELATIVE;
        default:
          return SWITCHER_STAT_ENTITY_NAMES.SPHERE;
      }
    };

    const newValue = getNewValue();
    input.onChange(newValue);
    onChange?.(newValue, nextValues);
  };

  return (
    <>
      {isDTOIVRole || isSphereRole ? (
        <TripleSwitcher
          name="stats"
          ariaLabel="state"
          firstIcon={StateOneIcon}
          secondIcon={StateTwoIcon}
          thirdIcon={StateThreeIcon}
          firstTip='Разрез статистики "Уровень 1"'
          secondTip='Разрез статистики "Уровень 2"'
          thirdTip='Разрез статистики "Уровень 3"'
          iconColor="custom.purple.200"
          value={defineTypeSettings[input.value]["stats"]}
          onChange={(v) => handleOnChange("stats", v)}
        />
      ) : (
        // это костыльный компонент, аналог TripleSwitcher, но переключается между value 2 и 3
        <DoubleSwitcher
          name="stats"
          ariaLabel="state"
          firstIcon={StateTwoIcon}
          secondIcon={StateThreeIcon}
          firstTip='Разрез статистики "Уровень 2"'
          secondTip='Разрез статистики "Уровень 3"'
          iconColor="custom.purple.200"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={defineTypeSettings[input.value]["stats"]}
          onChange={(v) => handleOnChange("stats", v)}
        />
      )}

      <Switcher
        name="person"
        trueIcon={PersonPlusIcon}
        falseIcon={PersonIcon}
        trueTip="Дополнительные телефоны"
        falseTip="Сотрудники"
        iconColor="custom.purple.200"
        value={defineTypeSettings[input.value]["person"]}
        onChange={(v) => handleOnChange("person", v)}
        isDisabled={isEmployee}
      />
    </>
  );
}
