const PHONE_MAX_LENGTH = 11;
const COUNTRY_CODE_RUSSIA = "7";
const INTERNAL_CODE_RUSSIA = "8";

export const parsePhone = (value: string) => {
  if (value.length === PHONE_MAX_LENGTH && value.startsWith(INTERNAL_CODE_RUSSIA)) {
    return `${COUNTRY_CODE_RUSSIA}${value.slice(1)}`;
  }
  if (value.length === PHONE_MAX_LENGTH) {
    return value.slice(1);
  }
  return value;
};

export const formatPhone = (value: string) => {
  if (value.length === PHONE_MAX_LENGTH && value.startsWith(INTERNAL_CODE_RUSSIA)) {
    return `${COUNTRY_CODE_RUSSIA}${value.slice(1)}`;
  }
  return value;
};
