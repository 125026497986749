import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const AuthorizeIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#19A7D4" rx="2" />
      <path
        fill="#fff"
        d="M11.308 4.938c.367.366.367.962 0 1.283l-1.283 1.283L6.45 3.93l1.283-1.283c.367-.367.963-.367 1.284 0l.825.825 1.375-1.375.641.642-1.375 1.375.825.825ZM8.65 7.596l-.642-.642-1.283 1.284-.962-.963 1.283-1.283-.642-.642-1.283 1.283-.688-.641L3.15 7.275c-.367.367-.367.963 0 1.283l.825.825-1.833 1.834.641.641 1.834-1.833.825.825c.366.367.962.367 1.283 0l1.283-1.283-.641-.642 1.283-1.33Z"
      />
    </Icon>
  );
};
