import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const ExpandArrowsIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M9.725 2.644a.333.333 0 0 1 .548 0l3 4.333A.333.333 0 0 1 13 7.5H7a.334.334 0 0 1-.273-.523l3-4.333ZM9.725 18.356a.333.333 0 0 0 .548 0l3-4.333A.334.334 0 0 0 13 13.5H7a.334.334 0 0 0-.273.523l3 4.333Z"
      />
    </Icon>
  );
};
