import { Navigate, RouteObject } from "react-router-dom";

import { ErrorPage } from "features/errors/pages";
import { LoginPage } from "features/login/pages";
import { StatsPage } from "features/stats/pages";
import { UsersPage } from "features/users/pages";
import { OrgsAdminPage } from "features/admin/pages/organizations";
import { UsersAdminPage } from "features/admin/pages/users";
import { Layout } from "features/layout";
import { ROLES } from "shared/constants";

import { ProtectedRoute } from "./protected-route";
import { routePaths } from "./routePaths";

const permittedRoutes = [
  {
    path: routePaths.statistics(),
    element: <StatsPage />,
    handle: { permissions: [ROLES.DTOIV, ROLES.SPHERE, ROLES.OIV] },
  },
  {
    path: routePaths.users(),
    element: <UsersPage />,
    handle: { permissions: [ROLES.DTOIV, ROLES.SPHERE, ROLES.OIV, ROLES.ORG] },
  },
  {
    path: routePaths.adminOrganizations(),
    element: <OrgsAdminPage />,
    handle: { permissions: [ROLES.DTOIV] },
  },
  {
    path: routePaths.adminUsers(),
    element: <UsersAdminPage />,
    handle: { permissions: [ROLES.DTOIV] },
  },
];

export const getRoleRoutes = (userRole: ROLES = ROLES.ORG): RouteObject[] => {
  const routes = getPermittedRoutes(userRole);
  // Common routes
  return [
    {
      path: routePaths.login(),
      element: <LoginPage />,
      handle: { title: "" },
    },
    {
      path: routePaths.main(),
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        ...routes,
        {
          path: "*",
          element: <Navigate to={routePaths.main()} />,
        },
        {
          index: true,
          element: <UsersPage />,
        },
      ],
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ];
};

function getPermittedRoutes(userRole: ROLES) {
  const filterRoutes = permittedRoutes.filter((route) => {
    const permissions = route.handle.permissions;
    return permissions.some((perm) => perm === userRole) || permissions.length === 0 || !permissions;
  });
  return filterRoutes;
}
