import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const ReportsIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill="currentColor" d="M14 21v-8h8v8h-8ZM2 18v-2h9v2H2Zm12-7V3h8v8h-8ZM2 8V6h9v2H2Z" />
    </Icon>
  );
};
