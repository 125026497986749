import qs from "qs";

import { statInstance } from "api/client";
import { APIResponse } from "api/types";

import { EmployeeQuery, STAT_TYPE, StatParams, StatQuery } from "./types";

export const getStat = async ({ name, ...params }: StatParams) => {
  const url = `/stat/${name}`;
  const { data } = await statInstance.post<StatQuery>(url, params, { withCredentials: true });

  return data.result;
};

export const exportEmployeesStat = async ({ type }: { type: STAT_TYPE }) => {
  const url = `/stat/${type}/download`;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const blob = await statInstance
    .get(url, { responseType: "blob" })
    .then((response) => {
      const blob = response.data;
      const headerType = response.headers["content-type"] as string;

      return new Blob([blob], { type: headerType });
    })
    .catch(async (error) => {
      const jsonResponse = JSON.parse(await error.response.data.text()) as APIResponse;
      return jsonResponse.error;
    });

  return blob;
};

export const getResouceStat = async ({ name, ...params }: StatParams) => {
  const url = `/resource_stat/${name}`;
  const { data } = await statInstance.post<EmployeeQuery>(url, params, { withCredentials: true });

  return data.result;
};
