import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const DownloadIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M11.438 5.516V1H4.796A.795.795 0 0 0 4 1.797v15.406c0 .442.355.797.797.797h11.156a.795.795 0 0 0 .797-.797V6.313h-4.516a.8.8 0 0 1-.796-.797Zm2.538 7.017-3.202 3.178a.566.566 0 0 1-.798 0l-3.201-3.178a.531.531 0 0 1 .373-.908h2.165V8.969c0-.294.237-.531.53-.531h1.063c.294 0 .531.237.531.53v2.657h2.165c.474 0 .71.574.374.908Zm2.542-8.047-3.251-3.254A.796.796 0 0 0 12.703 1H12.5v4.25h4.25v-.203a.794.794 0 0 0-.232-.56Z"
      />
    </Icon>
  );
};
