import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const ContactsIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="16px" height="17px" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M3.2 15.5v-1.273h9.6V15.5H3.2Zm0-12.727V1.5h9.6v1.273H3.2ZM8 9.136c.5 0 .925-.185 1.275-.556A1.9 1.9 0 0 0 9.8 7.227a1.9 1.9 0 0 0-.525-1.352A1.687 1.687 0 0 0 8 5.318c-.5 0-.925.186-1.275.557A1.9 1.9 0 0 0 6.2 7.227c0 .53.175.981.525 1.353.35.37.775.556 1.275.556Zm-4.8 4.455c-.33 0-.612-.125-.847-.374A1.264 1.264 0 0 1 2 12.318V4.682c0-.35.118-.65.353-.899.235-.25.517-.374.847-.374h9.6c.33 0 .613.125.848.374.235.25.352.549.352.899v7.636c0 .35-.117.65-.352.9a1.125 1.125 0 0 1-.848.373H3.2Zm1.05-1.273h7.5a4.932 4.932 0 0 0-1.635-1.4A4.443 4.443 0 0 0 8 10.41c-.77 0-1.475.17-2.115.51-.64.339-1.185.805-1.635 1.4Z"
      />
    </Icon>
  );
};
