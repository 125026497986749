import { components, ValueContainerProps } from "react-select";
import { Flex, Text } from "@chakra-ui/react";

import { SelectOption } from "./types";

export const ValueContainer = ({ children, ...props }: ValueContainerProps<SelectOption>) => {
  const { valueLabel } = props.selectProps;

  // Value контейнер всегда в children содержит в себе values и input. Деструктурируем, но ts-игнорим
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [values, input] = children;
  let customValue = "";
  if (values && values.length > 0) {
    customValue = `Выбрано ${valueLabel || ""} - ${values.length}`;
  }

  return (
    <components.ValueContainer {...props}>
      <Flex justifyContent="start" alignItems="center" gap="4px">
        <Text as="span" textStyle="text" color="custom.purple.200">
          {/* при отсутствии values выводим таким образом placeholder */}
          {values && values.length ? customValue : values}
        </Text>
        {input}
      </Flex>
    </components.ValueContainer>
  );
};
