import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

const M: FC<IconProps> = (props) => {
  return (
    <Icon width="15px" height="15px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        className="dropdown-toggler"
        fill="currentColor"
        d="M3.64 2.77 7.5 6.63l3.84-3.84A.92.92 0 0 1 12 2.5a1 1 0 0 1 .933 1.358.9.9 0 0 1-.203.302L8.84 8l3.89 3.89a.9.9 0 0 1 .27.61 1 1 0 0 1-1 1 .919.919 0 0 1-.69-.27L7.5 9.37l-3.85 3.85a.92.92 0 0 1-.65.28 1 1 0 0 1-.933-1.358.9.9 0 0 1 .203-.302L6.16 8 2.27 4.11A.9.9 0 0 1 2 3.5a1 1 0 0 1 1-1c.24.003.47.1.64.27Z"
      />
    </Icon>
  );
};

const S: FC<IconProps> = (props) => {
  return (
    <Icon width="10px" height="10px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        className="dropdown-toggler"
        fill="currentColor"
        d="M7.774 1.4A.938.938 0 1 1 9.1 2.725L6.353 5.472a.039.039 0 0 0 0 .055L9.1 8.274A.938.938 0 1 1 7.774 9.6L5.027 6.853a.039.039 0 0 0-.055 0L2.225 9.6A.938.938 0 1 1 .899 8.274l2.747-2.746a.039.039 0 0 0 0-.056L.899 2.725A.938.938 0 0 1 2.225 1.4l2.747 2.747a.04.04 0 0 0 .055 0L7.774 1.4Z"
      />
    </Icon>
  );
};

export const CrossIcon = Object.assign(M, { S, M });
