import { useMemo } from "react";
import { Form } from "react-final-form";
import {
  PopoverContent,
  PopoverTrigger,
  PopoverFooter,
  PopoverHeader,
  useDisclosure,
  PopoverBody,
  Popover,
  Portal,
  Button,
  Flex,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { RowSelectionState } from "@tanstack/react-table";
import { omit, uniqBy } from "lodash";

import { SelectDictSphereStatField } from "features/dictionaries/components/fields/select-dict-sphere-stat-field";
import { SelectDictOivStatField } from "features/dictionaries/components/fields/select-dict-oiv-stat-field";
import { SelectOrgField } from "features/dictionaries/components/fields/select-org-field";
import { CrossIcon, CheckIcon, FilterIcon, PlusIcon, LockIcon, UnlockIcon } from "shared/ui/icons";
import { SelectOption } from "shared/form/select-v2";
import { DictStatData, useReadOnlyStatus } from "features/dictionaries/api";
import { useSetReadOnlyStatus } from "features/dictionaries/api/mutations";
import { toast } from "shared/ui/toast";

import { Filter } from "../pages/organizations";

import { RemoveOrgsModalIconButton } from "./buttons/remove-orgs-modal-icon-button";

export type ExtendedDictStat = SelectOption &
  Omit<
    DictStatData,
    | "arch_attracted"
    | "relative"
    | "attracted_no_voted"
    | "attracted_voted"
    | "arch_staff"
    | "staff"
    | "staff_no_voted"
    | "staff_voted"
    | "arch_cnt_all"
    | "cnt_all"
    | "cnt_all_no_voted"
    | "cnt_voted"
  >;

export type FormPayload = {
  sphere?: Array<ExtendedDictStat>;
  oiv?: Array<ExtendedDictStat>;
  org?: Array<ExtendedDictStat>;
};

interface Props {
  filter: Filter;
  rowSelection: RowSelectionState;
  setRowSelection: (rows: RowSelectionState) => void;
  onFilterChange: (filter: Filter) => void;
  orgModalOnOpen: () => void;
}

export const FilterStatsPanel = ({ filter, rowSelection, setRowSelection, onFilterChange, orgModalOnOpen }: Props) => {
  const popoverDisclosure = useDisclosure();
  const { data: readOnlyStatus } = useReadOnlyStatus();
  const { mutateAsync: setReadOnlyStatus } = useSetReadOnlyStatus();

  const initialValues = useMemo<FormPayload>(() => ({ sphere: [], oiv: [], org: [] }), []);

  const handleOnSubmit = ({ sphere = [], oiv = [], org = [] }: FormPayload) => {
    const sphere_ids = sphere?.map((o) => +o.value) || [];
    const org_ids = org?.map((o) => +o.value) || [];
    const oiv_ids = oiv?.map((o) => +o.value) || [];
    const newValue: Filter = { sphere_ids, org_ids, oiv_ids };
    onFilterChange(newValue);
    popoverDisclosure.onClose();
  };

  const handleReadOnlyMode = () => {
    setReadOnlyStatus(!readOnlyStatus, {
      onSuccess: () => {
        toast.success(`Режим блокировки ${!readOnlyStatus ? "включен" : "выключен"}`, { isClosable: true });
      },
    });
  };

  return (
    <Form<FormPayload>
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit: submit, form, dirty, values }) => {
        return (
          <Flex
            as="form"
            id="filter-stat-form"
            onSubmit={submit}
            minH="50px"
            px="20px"
            borderBottomWidth={1}
            borderBottomStyle="solid"
            borderBottomColor="custom.purple.100"
            alignItems="center"
            gap="6px"
          >
            <IconButton
              variant="rounded"
              size="sm"
              icon={readOnlyStatus ? <LockIcon color="white" /> : <UnlockIcon color="white" />}
              aria-label="Блокировка редактирования"
              title="Блокировка редактирования"
              onClick={handleReadOnlyMode}
              bg={readOnlyStatus ? "custom.purple.200" : "custom.purple.100"}
            />
            <Flex flex={1}></Flex>
            <Flex gap="10px" mr="26px">
              <Text textStyle="textLight" color="custom.purple.200">
                уровень 1 выбрано:{" "}
                <Text as="span" pr="6px">
                  {filter.sphere_ids?.length || 0}
                </Text>
              </Text>
              <Text textStyle="textLight" color="custom.purple.200">
                уровень 2 выбрано:{" "}
                <Text as="span" pr="6px">
                  {filter.oiv_ids?.length || 0}
                </Text>
              </Text>
              <Text textStyle="textLight" color="custom.purple.200">
                уровень 3 выбрано:{" "}
                <Text as="span" pr="6px">
                  {filter.org_ids?.length || 0}
                </Text>
              </Text>
            </Flex>
            <Popover isLazy placement="bottom-start" {...popoverDisclosure} closeOnBlur>
              <PopoverTrigger>
                <IconButton
                  variant="rounded"
                  size="sm"
                  icon={<FilterIcon color="white" />}
                  aria-label="Фильтр"
                  title="Фильтр"
                  bg={popoverDisclosure.isOpen ? "custom.purple.200" : "custom.purple.100"}
                />
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  w="510px"
                  borderRadius={10}
                  borderWidth={1}
                  borderStyle="solid"
                  borderColor="custom.purple.light.200"
                  boxShadow="shadows.default"
                  px="50px"
                  pt="30px"
                  pb="40px"
                >
                  <PopoverHeader borderBottomWidth={0}>
                    <Text textStyle="h1" color="custom.grey.dark.100">
                      Фильтр
                    </Text>
                  </PopoverHeader>
                  <PopoverBody>
                    <Flex flexDir="column" gap="20px">
                      <SelectDictSphereStatField
                        selectProps={{
                          allowSelectAll: true,
                        }}
                        onChange={() => {
                          form.initialize((values) => omit(values, ["oiv_id", "org_id"]));
                        }}
                      />
                      <SelectDictOivStatField
                        selectProps={{
                          allowSelectAll: true,
                        }}
                        onChange={(v) => {
                          const value = v.length ? v : values.sphere ?? [];
                          const sphere = uniqBy(
                            value.map((o) => ({
                              value: String(o?.sphere_id),
                              sphere_id: Number(o?.sphere_id),
                              label: "",
                            })),
                            "sphere_id",
                          );
                          form.initialize((values) => ({
                            ...omit(values, ["org_id"]),
                            sphere,
                          }));
                        }}
                      />
                      <SelectOrgField
                        name="org"
                        selectProps={{
                          closeMenuOnSelect: false,
                          allowSelectAll: true,
                          isMulti: true,
                        }}
                        onChange={(v) => {
                          const value = v.length ? v : values.oiv ?? [];
                          const sphere = uniqBy(
                            value.map((o) => ({
                              value: String(o?.sphere_id),
                              sphere_id: Number(o?.sphere_id),
                              label: "",
                              title: "",
                            })),
                            "sphere_id",
                          );
                          const oiv = uniqBy(
                            value.map((o) => ({
                              value: String(o?.oiv_id),
                              oiv_id: Number(o?.oiv_id),
                              sphere_id: o?.sphere_id,
                              label: "",
                            })),
                            "oiv_id",
                          );
                          form.initialize((values) => ({
                            ...values,
                            sphere,
                            oiv,
                          }));
                        }}
                      />
                    </Flex>
                  </PopoverBody>
                  <PopoverFooter
                    bgColor="custom.grey.light.100"
                    mt="20px"
                    py="20px"
                    borderRadius={15}
                    borderTopWidth={0}
                  >
                    <Flex justifyContent="center" gap="30px">
                      <Button w="full" leftIcon={<CheckIcon />} onClick={form.submit} isDisabled={!dirty}>
                        ОК
                      </Button>
                      <Button
                        w="full"
                        leftIcon={<CrossIcon />}
                        onClick={() => {
                          //form.reset() и form.restart() не отрабатывали корректно, поэтому вручную очищаем
                          form.change("sphere", []);
                          form.change("oiv", []);
                          form.change("org", []);
                          onFilterChange({});
                          popoverDisclosure.onClose();
                        }}
                      >
                        Отмена
                      </Button>
                    </Flex>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </Popover>
            <IconButton
              variant="rounded"
              size="sm"
              icon={<PlusIcon color="white" />}
              aria-label="Добавить"
              title="Добавить"
              onClick={orgModalOnOpen}
              bg={popoverDisclosure.isOpen ? "custom.purple.200" : "custom.purple.100"}
            />
            <RemoveOrgsModalIconButton rowSelection={rowSelection} setRowSelection={setRowSelection} />
          </Flex>
        );
      }}
    />
  );
};
