import { Box } from "@chakra-ui/react";

import { VerificationIcon } from "shared/ui/icons/status";
import { DictStatData } from "features/dictionaries/api";

import { statusesIcons, statusesText } from "./constants";
import { UserFilter } from "./components/filter-panel/popover";
import { OblbuhData } from "./api";

export const getStatuses = (status?: number) => {
  if (status) {
    const binaryStatus = status
      .toString(2)
      .split("")
      .reverse()
      .map((status) => Boolean(Number(status)));

    return binaryStatus.map((el, i) => (el ? statusesIcons[i] : null));
  }

  if (status === 0) {
    return (
      <Box as="span" key="0" lineHeight={0}>
        <VerificationIcon boxSize="16px" role="img" />
      </Box>
    );
  }

  return null;
};

export const getTextStatuses = (status?: number) => {
  if (status) {
    const binaryStatus = status
      .toString(2)
      .split("")
      .reverse()
      .map((status) => Boolean(Number(status)));

    return binaryStatus.map((el, i) => (el ? statusesText[i] : null));
  }

  if (status === 0) {
    return [""];
  }

  return [""];
};

export type CurrentValues = {
  cnt_all: number;
  relative: number;
  staff: number;
  arch_cnt_all?: number;
  arch_attracted?: number;
  arch_staff?: number;
};

export const getCurrentValues = ({ filter, values }: { filter: UserFilter; values: DictStatData }): CurrentValues => {
  if (filter.statuses?.includes(4096))
    return { cnt_all: values.cnt_all_no_voted, staff: values.staff_no_voted, relative: values.attracted_no_voted };
  if (filter.statuses?.includes(8192))
    return { cnt_all: values.cnt_voted, staff: values.staff_voted, relative: values.attracted_voted };
  return {
    cnt_all: values.cnt_all,
    staff: values.staff,
    relative: values.relative,
    arch_cnt_all: values.arch_cnt_all,
    arch_staff: values.arch_staff,
    arch_attracted: values.arch_attracted,
  };
};

export const getCurrentAttractedValues = ({ filter, values }: { filter?: UserFilter; values?: OblbuhData }): number => {
  if (filter?.statuses?.includes(4096)) return values?.count_relatives_reestr || 0;
  if (filter?.statuses?.includes(8192)) return values?.count_relatives_voted || 0;
  return values?.count_relatives || 0;
};
