import { Accordion, AccordionPanel, AccordionPanelProps, Progress } from "@chakra-ui/react";

import { DICT_ENTITY_NAMES, useDictOivStatInfiniteQuery } from "features/dictionaries/api";
import { userFilterAdapter } from "features/users/lib";

import { UserFilter } from "../filter-panel/popover";

import { OivAccordionItem } from "./oiv-accordion-item";

interface Props extends AccordionPanelProps {
  userFilter: UserFilter;
  isExpanded?: boolean;
  sphereId: number;
  handleClickAddButton: ({ title, orgId }: { title: string; orgId: number }) => void;
}

export function OivAccordionPanel({ userFilter, isExpanded, sphereId, handleClickAddButton, ...args }: Props) {
  const oivQuery = useDictOivStatInfiniteQuery(
    {
      ...userFilterAdapter(userFilter, ["oiv_ids", "statuses"]),
      sphere_ids: [sphereId],
      limit: 100,
      order_by: "oiv",
      order_type: "asc",
    },
    { enabled: isExpanded },
  );
  const oiv = oivQuery.data?.pages.flatMap((page) => page.data || []) ?? [];

  return (
    <>
      <Progress h="2px" mt="0" isIndeterminate={(isExpanded && oivQuery.isLoading) || oivQuery.isFetchingNextPage} />
      <AccordionPanel {...args}>
        <Accordion allowMultiple>
          {oiv?.map((o) => (
            <OivAccordionItem
              key={`${DICT_ENTITY_NAMES.OIV}_${o.oiv_id}`}
              sphereId={sphereId}
              oiv={o}
              userFilter={userFilter}
              handleClickAddButton={handleClickAddButton}
              lastItem={false}
            />
          ))}
        </Accordion>
      </AccordionPanel>
    </>
  );
}
