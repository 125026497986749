import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const PassportDateIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="#0A0077"
        d="M8.227 9.465c.547 0 1.036-.423 1.036-1.032 0-.728-.461-1.076-1.015-1.076-.513 0-1.022.338-1.022 1.058 0 .715.475 1.05 1 1.05Z"
      />
      <path
        fill="#0A0077"
        d="M3.063.5A.438.438 0 0 1 3.5.938v.437h7V.937a.438.438 0 0 1 .875 0v.438h.875A1.75 1.75 0 0 1 14 3.125v9.625a1.75 1.75 0 0 1-1.75 1.75H1.75A1.75 1.75 0 0 1 0 12.75V3.125a1.75 1.75 0 0 1 1.75-1.75h.875V.937A.437.437 0 0 1 3.063.5Zm8.71 2.625H2.226c-.263 0-.477.196-.477.438v.874c0 .242.214.438.477.438h9.546c.263 0 .477-.196.477-.438v-.875c0-.241-.213-.437-.478-.437Zm-3.604 8.566c1.1 0 1.75-.933 1.75-2.513 0-1.692-.683-2.334-1.709-2.334-.81 0-1.572.588-1.572 1.583 0 1.015.72 1.548 1.466 1.548.653 0 1.077-.329 1.21-.69h.024c-.003 1.15-.403 1.893-1.142 1.893-.58 0-.882-.394-.919-.718H6.68c.041.56.52 1.23 1.49 1.23v.001ZM5.64 6.936h-.554c-.397.21-.78.446-1.149.704v.608c.329-.225.848-.542 1.101-.68h.01v4.034h.591V6.935h.001Z"
      />
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 .5h14v14H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
};
