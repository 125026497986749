import { Outlet } from "react-router-dom";
import {
  MenuItemProps,
  MenuButton,
  IconButton,
  MenuItem,
  MenuList,
  GridItem,
  Flex,
  Grid,
  Menu,
  Text,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

import { BurgerMenuIcon, ContactsIcon, LogoutIcon, Pencil2Icon, ReportsIcon } from "shared/ui/icons";
import { useProfileQuery } from "features/profile/api";
import { useSignOutMutation } from "features/auth/api";
import { routePaths } from "config/routes/routePaths";
import { WithPermission } from "features/permissions";
import { ErrorPage } from "features/errors/pages";
import { NavLink } from "shared/ui/link";
import { useAccessController } from "config/routes/router-provider";
import { ROLES } from "shared/constants";
import { DICT_ENTITY_NAMES, useDictQuery } from "features/dictionaries/api";
import { creatingReport, syncUsers } from "features/users/atoms";
import { connectWS, useDownloadOblbuhXls } from "features/users/api";
import { getLocalStorageData } from "features/auth/helpers";
import { SignInQuery } from "features/auth/api/types";

export const Layout = () => {
  const { mutateAsync: signOut, isLoading } = useSignOutMutation();
  const handleOnSingOutClick = async () => signOut();

  const profileQuery = useProfileQuery();
  const profile = profileQuery.data;

  const title = useHeaderTitle();

  const [, setIsSyncing] = useAtom(syncUsers);
  const [report, setReport] = useAtom(creatingReport);

  const { mutateAsync: downloadOblbuhXls } = useDownloadOblbuhXls({ filename: report.filename });
  const accessStorage = getLocalStorageData<SignInQuery>("ACCESS");
  const queryClient = useQueryClient();

  const shouldExecute = useRef(true);

  useEffect(() => {
    if (shouldExecute.current)
      if (accessStorage?.token) {
        connectWS({
          token: accessStorage.token,
          queryClient,
          setReport,
          setIsSyncing,
          downloadOblbuhXls,
          signOut,
        });
        shouldExecute.current = false;
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessStorage?.token, downloadOblbuhXls]);

  if (profileQuery.isFetching) return null;
  if (profileQuery.isError || !profile) return <ErrorPage />;

  return (
    <Grid
      h="100vh"
      minW="1280px"
      gridTemplateColumns="100%"
      gridTemplateRows="70px calc(100% - 70px)"
      gridTemplateAreas="'header' 'main'"
    >
      <GridItem area="header" pos="relative" bgColor="custom.purple.200" boxShadow="shadows.default">
        <Flex py="15px" px="20px" gap="18px">
          <Flex flexDir="column">
            <Flex gap="7px">
              <ContactsIcon color="white" />
              <Text textStyle="header" color="white" textTransform="uppercase">
                Мониторинг кадров
              </Text>
            </Flex>
            <Text textStyle="textLight" color="white" pl="24px" letterSpacing="2px">
              {new Date().getFullYear()}
            </Text>
          </Flex>
          <Flex flexGrow={1}>
            <Flex flexDir="column" gap="2px">
              <Text textStyle="textBold" color="white">
                {title.main}
              </Text>
              <Text textStyle="textBold" color="white" textTransform="uppercase">
                {title.sub}
              </Text>
            </Flex>
          </Flex>
          <Flex>
            <Flex gap="10px" alignItems="center">
              <Text textStyle="textBold" color="white">
                {profile.name}
              </Text>
              <Menu isLazy>
                <MenuButton
                  as={IconButton}
                  icon={<BurgerMenuIcon color="custom.purple.200" />}
                  size="sm"
                  boxSize="40px"
                  bg="white"
                  aria-label="Меню"
                  borderRadius="50%"
                  _hover={{ bg: "custom.purple.light.200" }}
                  _active={{ bg: "custom.purple.light.200" }}
                />
                <MenuList zIndex={5} p={0} borderRadius={0}>
                  <CustomMenuItem as={NavLink} to={routePaths.users()} icon={<Pencil2Icon />}>
                    Перейти в режим редактирования
                  </CustomMenuItem>
                  <WithPermission permission={["DTOIV", "OIV", "SPHERE"]}>
                    <CustomMenuItem as={NavLink} to={routePaths.statistics()} icon={<ReportsIcon />}>
                      Перейти в режим отчетов
                    </CustomMenuItem>
                  </WithPermission>
                  <WithPermission permission={["DTOIV"]}>
                    <CustomMenuItem as={NavLink} to={routePaths.adminOrganizations()} icon={<ReportsIcon />}>
                      Перейти в режим администрирования
                    </CustomMenuItem>
                  </WithPermission>
                  <CustomMenuItem isDisabled={isLoading} onClick={() => handleOnSingOutClick()} icon={<LogoutIcon />}>
                    Выйти из учетной записи
                  </CustomMenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem area="main" bg="white">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

const useHeaderTitle = () => {
  const { access } = useAccessController();
  const currentUserRoleId = access?.roles?.[0].id;

  const { data: dataSphere } = useDictQuery(
    { name: DICT_ENTITY_NAMES.SPHERE, limit: 100 },
    { enabled: currentUserRoleId !== ROLES.DTOIV },
  );

  const { data: dataOiv } = useDictQuery(
    { name: DICT_ENTITY_NAMES.OIV, limit: 100 },
    { enabled: currentUserRoleId === ROLES.OIV || currentUserRoleId === ROLES.ORG },
  );

  const sphere = dataSphere?.data?.sort((a, b) => a.sphere!.localeCompare(b.sphere!))[0].sphere || "";
  const oiv = dataOiv?.data?.sort((a, b) => a.oiv!.localeCompare(b.oiv!))[0].oiv || "";

  switch (currentUserRoleId) {
    case ROLES.DTOIV:
      return {
        main: "ДТОИВ",
        sub: "",
      };
    case ROLES.SPHERE:
      return {
        main: sphere,
        sub: "",
      };
    case ROLES.OIV:
      return {
        main: sphere,
        sub: oiv,
      };
    case ROLES.ORG:
      return {
        main: sphere,
        sub: oiv,
      };
    default:
      return {
        main: "",
        sub: "",
      };
  }
};

function CustomMenuItem({ children, icon, ...args }: MenuItemProps & { to?: string }) {
  return (
    <MenuItem
      icon={icon && <icon.type color="custom.purple.200" {...icon.props} />}
      minH="54px"
      borderBottomWidth="1"
      borderBottomStyle="dashed"
      borderBottomColor="custom.purple.200"
      _hover={{ bg: "custom.purple.light.200" }}
      _focus={{ bg: "custom.purple.light.200" }}
      _last={{ borderBottomWidth: "0" }}
      {...args}
      textDecoration="none!important"
    >
      <Text color="custom.grey.dark.200" textStyle="text" textTransform="uppercase">
        {children}
      </Text>
    </MenuItem>
  );
}
