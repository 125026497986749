import { Colors as ChakraColors } from "@chakra-ui/react";

export const colors = {
  brand: {
    50: "#EFEEF6",
    100: "#D2D0E6",
    200: "#B6B2D6",
    300: "#9994C6",
    400: "#7C77B6",
    500: "#6059A6",
    600: "#4D4785",
    700: "#393564",
    800: "#262343",
    900: "#131221",
  },
  progressBg: {
    "less-better": {
      15: "#71b49a",
      30: "#c6e1d6",
      46: "#f3db5c",
      73: "#ae9d41",
      85: "#caa09b",
      100: "#b47872",
    },
    "more-better": {
      15: "#b47872",
      30: "#caa09b",
      46: "#ae9d41",
      73: "#f3db5c",
      85: "#c6e1d6",
      100: "#71b49a",
    },
  },
  custom: {
    bg: {
      100: "#FAFAFF",
      200: "#F1F0F7",
    },
    grey: {
      100: "#B4B4B4",
      dark: {
        100: "#6D6D6D",
        200: "#454444",
      },
      light: {
        100: "#F8F8F8",
        200: "#D5D5D5",
      },
    },
    blue: {
      dark: {
        100: "#0A0077",
        200: "rgba(10, 0, 119, .4)",
      },
    },
    purple: {
      100: "#CAC8EB",
      200: "#7872B4",
      light: {
        100: "#F3F2FF",
        200: "#E9E8FF",
        300: "#F8F8FE",
      },
    },
    red: {
      100: "#F30000",
      dark: {
        100: "#8A0000",
      },
      light: {
        100: "#FFFAFA",
        200: "#FFBABA",
        300: "#F8E9E9",
      },
    },
    yellow: {
      100: "#E3BF00",
      dark: {
        100: "#9C8717",
      },
      light: {
        100: "#FFFEF3",
        200: "#F5DD5D",
        300: "#F4F2DD",
      },
    },
    green: {
      100: "#94D3A6",
      dark: {
        100: "#528661",
      },
      light: {
        100: "#EEFFF3",
      },
    },
  },
};

export type Colors = typeof colors & ChakraColors;
