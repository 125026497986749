import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const UnlockIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M12.625 6.938a2.187 2.187 0 1 1 4.375 0V8.25a.874.874 0 1 0 1.75 0V6.937a3.939 3.939 0 0 0-7.875 0V8.25H4.75C3.785 8.25 3 9.035 3 10v5.25c0 .965.785 1.75 1.75 1.75h8.75c.965 0 1.75-.785 1.75-1.75V10c0-.965-.785-1.75-1.75-1.75h-.875V6.937Z"
      />
    </Icon>
  );
};
