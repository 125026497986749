import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const PhoneIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#9C8717" rx="2" />
      <path
        fill="#FAFAFF"
        d="M10.648 4.248 9.608 3.21a.79.79 0 0 0-1.12 0L7.37 4.329a.79.79 0 0 0 0 1.12l.819.818A3.846 3.846 0 0 1 7.4 7.4a3.86 3.86 0 0 1-1.133.79l-.819-.819a.79.79 0 0 0-1.12 0L3.21 8.49a.79.79 0 0 0 0 1.121l1.038 1.038a1.283 1.283 0 0 0 1.112.358c1.317-.217 2.622-.917 3.676-1.97 1.053-1.052 1.752-2.356 1.972-3.677a1.285 1.285 0 0 0-.359-1.11Z"
      />
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M2 2h10v10H2z" />
        </clipPath>
      </defs>
    </Icon>
  );
};
