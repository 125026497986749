import {
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  IconButton,
  ModalBody,
  Button,
  Modal,
  Flex,
  Text,
} from "@chakra-ui/react";
import { RowSelectionState } from "@tanstack/react-table";

import { useRemoveOrgMutation } from "features/admin/api/mutations";
import { CheckIcon, CrossIcon, TrashIcon } from "shared/ui/icons";

type Props = {
  rowSelection: RowSelectionState;
  setRowSelection: (rows: RowSelectionState) => void;
};

export function RemoveOrgsModalIconButton({ rowSelection, setRowSelection }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, mutateAsync: removeOrgs } = useRemoveOrgMutation();

  const orgSelectedIds = Object.keys(rowSelection).map((id) => Number(id));

  const handleOnRemoveClick = async () => {
    setRowSelection({});
    removeOrgs(
      { ids: orgSelectedIds },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <>
      <IconButton
        variant="rounded"
        size="sm"
        icon={<TrashIcon color="white" />}
        aria-label="Удалить"
        title="Удалить"
        isDisabled={orgSelectedIds.length === 0}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent py="30px" px="50px" minW="540px" gap="20px">
          <ModalBody p="unset">
            <Flex flexDir="column" align="center" textTransform="uppercase" gap="20px">
              <Text textAlign="center" color="custom.grey.dark.100" textStyle="h1">
                Удаление организаций
              </Text>
              <Text textAlign="center" color="custom.grey.dark.100" textStyle="textLight">
                Вы действительно хотите удалить выбранные организации?
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter p="unset">
            <Flex
              w="full"
              justifyContent="center"
              gap="20px"
              bgColor="custom.grey.light.100"
              py="20px"
              px="30px"
              borderTopWidth={0}
              borderRadius={15}
            >
              <Button
                minW="165px"
                leftIcon={<CheckIcon />}
                onClick={handleOnRemoveClick}
                isDisabled={orgSelectedIds.length === 0}
                isLoading={isLoading}
              >
                Удалить
              </Button>
              <Button minW="165px" isDisabled={isLoading} leftIcon={<CrossIcon />} onClick={onClose}>
                Отмена
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
