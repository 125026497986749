import { OblbuhParams } from "./api";
import { UserFilter } from "./components/filter-panel/popover";

export function userFilterAdapter(v: UserFilter, include?: (keyof OblbuhParams)[]) {
  const hasInclude = (name: keyof OblbuhParams) => !include?.length || include?.some((e) => e === name);
  const newFilter: Omit<OblbuhParams, "limit"> = {};
  if (hasInclude("sphere_ids") && v.sphere?.length) newFilter.sphere_ids = v.sphere.map((s) => +s.value);
  if (hasInclude("oiv_ids") && v.oiv?.length) newFilter.oiv_ids = v.oiv.map((s) => +s.value);
  if (hasInclude("org_ids") && v.org?.length) newFilter.org_ids = v.org.map((s) => +s.value);
  if (hasInclude("statuses") && v.statuses?.length) newFilter.statuses = v.statuses;
  if (hasInclude("phone") && v.phone) newFilter.phone = v.phone.phone;

  return newFilter;
}
