import React, { useState } from "react";
import { RowSelectionState } from "@tanstack/react-table";
import { Flex, Text } from "@chakra-ui/react";

import { Page } from "shared/ui/layout/page";
import { useAccessController } from "config/routes/router-provider";
import { ROLES } from "shared/constants";

import { DefineType } from "../components/fields/define-type-switch-group-field";
import {
  STAT_ENTITY_NAMES,
  SWITCHER_STAT_ENTITY_NAMES,
  StatParams,
  useResourceStatInfiniteQuery,
  useStatInfiniteQuery,
} from "../api";
import { FilterStatsPanel } from "../components/filter-panel";
import { StatsTable } from "../components/table";
import { fetchNextPage, getHasNextPage, getStatEntityNames, getTableData, getTableEmployeeData } from "../helpers";
import { EmployeeTable } from "../components/employee-table";

export type Filter = Pick<StatParams, "name" | "oiv_ids" | "org_ids" | "sphere_ids">;

export const StatsPage = () => {
  const { access } = useAccessController();
  const currentUserRoleId = access?.roles?.[0].id;
  const isDTOIVRole = currentUserRoleId === ROLES.DTOIV;
  const isSphereRole = currentUserRoleId === ROLES.SPHERE;

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [switchGroup, setSwitchGroup] = useState<Partial<DefineType>>({});
  const [voting, setVoting] = useState<1 | 2 | 3>(1);
  const isEmployee = voting === 3;

  const [filter, setFilter] = useState<Filter>({
    name: isDTOIVRole || isSphereRole ? SWITCHER_STAT_ENTITY_NAMES.SPHERE : SWITCHER_STAT_ENTITY_NAMES.OIV,
  });

  const handleOnFiltersChange = (v: Filter) => {
    setFilter(v);
  };

  const {
    data: sphereStat,
    fetchNextPage: fetchNextPageSphere,
    hasNextPage: hasNextPageSphere,
  } = useStatInfiniteQuery({
    ...filter,
    name: getStatEntityNames(voting, switchGroup, SWITCHER_STAT_ENTITY_NAMES.SPHERE),
    limit: 100,
  });

  const {
    data: oivStat,
    fetchNextPage: fetchNextPageOiv,
    hasNextPage: hasNextPageOiv,
  } = useStatInfiniteQuery({
    ...filter,
    name: getStatEntityNames(voting, switchGroup, SWITCHER_STAT_ENTITY_NAMES.OIV),
    limit: 100,
  });

  const {
    data: orgStat,
    fetchNextPage: fetchNextPageOrg,
    hasNextPage: hasNextPageOrg,
  } = useStatInfiniteQuery({
    ...filter,
    name: getStatEntityNames(voting, switchGroup, SWITCHER_STAT_ENTITY_NAMES.ORG),
    limit: 100,
  });

  const {
    data: sphereRecourceStat,
    fetchNextPage: fetchNextPageSphereResourseStat,
    hasNextPage: hasNextPageSphereResourceStat,
  } = useResourceStatInfiniteQuery(
    {
      ...filter,
      name: SWITCHER_STAT_ENTITY_NAMES.SPHERE,
      limit: 100,
    },
    {
      enabled: isEmployee,
    },
  );

  const {
    data: oivRecourceStat,
    fetchNextPage: fetchNextPageOivResourseStat,
    hasNextPage: hasNextPageOivResourceStat,
  } = useResourceStatInfiniteQuery(
    {
      ...filter,
      name: SWITCHER_STAT_ENTITY_NAMES.OIV,
      limit: 100,
    },
    {
      enabled: isEmployee,
    },
  );

  const {
    data: orgRecourceStat,
    fetchNextPage: fetchNextPageOrgRecourseStat,
    hasNextPage: hasNextPageOrgResourceStat,
  } = useResourceStatInfiniteQuery(
    {
      ...filter,
      name: SWITCHER_STAT_ENTITY_NAMES.ORG,
      limit: 100,
    },
    {
      enabled: isEmployee,
    },
  );

  const tableData = getTableData({ name: filter.name, sphereStat, oivStat, orgStat })?.pages.flatMap(
    (page) => page.data,
  );
  const summaryStat = getTableData({ name: filter.name, sphereStat, oivStat, orgStat })?.pages?.[0].total;
  const countsStat = {
    sphere_count: sphereStat?.pages?.[0].count || 0,
    oiv_count: oivStat?.pages?.[0].count || 0,
    org_count: orgStat?.pages?.[0].count || 0,
  };

  const hasNextPage = getHasNextPage({ name: filter.name, hasNextPageSphere, hasNextPageOiv, hasNextPageOrg });

  const tableEmployeeData = getTableEmployeeData({
    name: filter.name,
    sphereStat: sphereRecourceStat,
    oivStat: oivRecourceStat,
    orgStat: orgRecourceStat,
  })?.pages.flatMap((page) => page.data);

  const summaryEmployee = getTableEmployeeData({
    name: filter.name,
    sphereStat: sphereRecourceStat,
    oivStat: oivRecourceStat,
    orgStat: orgRecourceStat,
  })?.pages?.[0].total;

  const countsEmployee = {
    sphere_count: sphereRecourceStat?.pages?.[0].count || 0,
    oiv_count: oivRecourceStat?.pages?.[0].count || 0,
    org_count: orgRecourceStat?.pages?.[0].count || 0,
  };

  const hasNextPageEmployee = getHasNextPage({
    name: filter.name,
    hasNextPageSphere: hasNextPageSphereResourceStat,
    hasNextPageOiv: hasNextPageOivResourceStat,
    hasNextPageOrg: hasNextPageOrgResourceStat,
  });

  return (
    <Page>
      <FilterStatsPanel
        filter={filter}
        switchGroup={switchGroup}
        counts={isEmployee ? countsEmployee : countsStat}
        voting={voting}
        onFilterChange={handleOnFiltersChange}
        onSwitchGroupChange={setSwitchGroup}
        onVotingChange={setVoting}
      />
      <Flex
        w="full"
        minH="50px"
        bg="custom.purple.light.100"
        justify="center"
        align="center"
        borderWidth="1"
        borderStyle="solid"
        borderColor="custom.purple.light.200"
        color="custom.purple.200"
      >
        <Text textStyle="textBold" textTransform="uppercase">
          {switchGroup.person ? "Дополнительные телефоны" : "Сотрудники"}
        </Text>
      </Flex>
      {isEmployee ? (
        <EmployeeTable
          data={tableEmployeeData}
          rowSelection={rowSelection}
          handleRowSelection={setRowSelection}
          hasNextPage={hasNextPageEmployee}
          onNextPage={fetchNextPage({
            name: filter.name,
            fetchNextPageSphere: fetchNextPageSphereResourseStat,
            fetchNextPageOiv: fetchNextPageOivResourseStat,
            fetchNextPageOrg: fetchNextPageOrgRecourseStat,
          })}
          tableMeta={summaryEmployee}
          voting={voting}
        />
      ) : (
        <StatsTable
          data={tableData}
          rowSelection={rowSelection}
          handleRowSelection={setRowSelection}
          hasNextPage={hasNextPage}
          onNextPage={fetchNextPage({ name: filter.name, fetchNextPageSphere, fetchNextPageOiv, fetchNextPageOrg })}
          tableMeta={summaryStat}
          voting={voting}
        />
      )}
    </Page>
  );
};
