import { ComponentStyleConfig } from "@chakra-ui/react";

import { textStyles } from "../text-styles";

export const FormError: ComponentStyleConfig = {
  parts: ["text"],
  baseStyle: {
    text: {
      mt: "4px",
      color: "custom.red.dark.100",
      ...textStyles.description,
    },
  },
};
