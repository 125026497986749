import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const LockIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M7.5 5.676v1.5h5v-1.5a2.5 2.5 0 1 0-5 0Zm-2 1.5v-1.5a4.501 4.501 0 0 1 9 0v1.5h.5c1.103 0 2 .897 2 2v6c0 1.103-.897 2-2 2H5c-1.103 0-2-.897-2-2v-6c0-1.103.897-2 2-2h.5Z"
      />
    </Icon>
  );
};
