import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const DateBirthIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="#9C8717"
        d="M3.5.438a.437.437 0 1 0-.875 0v.437H1.75A1.75 1.75 0 0 0 0 2.625V3.5h14v-.875a1.75 1.75 0 0 0-1.75-1.75h-.875V.437a.438.438 0 0 0-.875 0v.438h-7V.437Zm4.727 8.527c.547 0 1.036-.423 1.036-1.032 0-.728-.461-1.077-1.015-1.077-.513 0-1.022.34-1.022 1.06 0 .714.475 1.05 1 1.05Z"
      />
      <path fill="#9C8717" d="M14 12.25V3.208H0v9.042A1.75 1.75 0 0 0 1.75 14h10.5A1.75 1.75 0 0 0 14 12.25Z" />
      <path
        fill="#fff"
        d="M5.195 3.842V10.5H4.097V5.145l-1.627.552V4.79l2.593-.948h.132ZM8.385 9.634h.092c.419 0 .768-.055 1.048-.164.282-.112.507-.267.674-.465.167-.197.287-.43.36-.697a3.3 3.3 0 0 0 .11-.87V6.293c0-.27-.03-.508-.087-.711a1.47 1.47 0 0 0-.237-.515c-.1-.14-.217-.245-.35-.315a.885.885 0 0 0-.425-.105.897.897 0 0 0-.45.114.995.995 0 0 0-.333.306 1.502 1.502 0 0 0-.206.45c-.045.171-.068.353-.068.548 0 .182.021.358.064.528.045.167.112.316.2.447.088.13.2.234.333.31.134.076.29.114.47.114.17 0 .326-.032.469-.096.143-.067.267-.156.374-.269.106-.112.19-.238.25-.378.061-.14.094-.283.1-.428l.42.127c0 .231-.049.459-.146.684a2.13 2.13 0 0 1-.397.61 1.942 1.942 0 0 1-.588.438c-.221.11-.463.164-.724.164a1.89 1.89 0 0 1-.839-.178 1.76 1.76 0 0 1-.601-.492 2.17 2.17 0 0 1-.356-.71 2.96 2.96 0 0 1 .028-1.742c.097-.28.238-.525.424-.738.185-.216.41-.383.674-.501.267-.122.57-.183.907-.183.358 0 .673.07.943.21.27.14.498.333.684.579.185.246.325.532.42.857.093.325.14.674.14 1.048v.387c0 .392-.035.77-.104 1.135-.07.361-.184.697-.342 1.007-.155.307-.36.577-.615.811a2.69 2.69 0 0 1-.93.543c-.365.127-.793.191-1.285.191h-.1v-.907Z"
      />
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
};
