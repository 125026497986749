import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const ReestrIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#19A7D4" rx="2" />
      <path
        fill="#fff"
        stroke="#19A7D4"
        d="M11.5 9c0 .584-.397 1.198-1.222 1.693-.814.488-1.972.807-3.278.807s-2.464-.319-3.278-.807C2.897 10.198 2.5 9.583 2.5 9c0-.584.397-1.198 1.222-1.693C4.536 6.82 5.694 6.5 7 6.5s2.464.319 3.278.807C11.103 7.802 11.5 8.417 11.5 9Z"
      />
      <path
        fill="#fff"
        stroke="#19A7D4"
        d="M11.5 7c0 .584-.397 1.198-1.222 1.693C9.464 9.18 8.306 9.5 7 9.5s-2.464-.319-3.278-.807C2.897 8.198 2.5 7.583 2.5 7c0-.584.397-1.198 1.222-1.693C4.536 4.82 5.694 4.5 7 4.5s2.464.319 3.278.807C11.103 5.802 11.5 6.417 11.5 7Z"
      />
      <path
        fill="#fff"
        stroke="#19A7D4"
        d="M11.5 5c0 .584-.397 1.198-1.222 1.693C9.464 7.18 8.306 7.5 7 7.5s-2.464-.319-3.278-.807C2.897 6.198 2.5 5.583 2.5 5c0-.584.397-1.198 1.222-1.693C4.536 2.82 5.694 2.5 7 2.5s2.464.319 3.278.807C11.103 3.802 11.5 4.417 11.5 5Z"
      />
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M2 2h10v10H2z" />
        </clipPath>
      </defs>
    </Icon>
  );
};
