import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const PlusIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M16.428 10.803c0 .148-.12.268-.267.268h-5.09v5.09c0 .147-.12.267-.268.267H9.196a.269.269 0 0 1-.268-.267v-5.09H3.84a.269.269 0 0 1-.268-.268V9.196c0-.147.12-.268.268-.268h5.09V3.84c0-.147.12-.268.267-.268h1.607c.148 0 .268.12.268.268v5.09h5.09c.147 0 .267.12.267.267v1.607Z"
      />
    </Icon>
  );
};
