import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryError } from "api/types";
import { toast } from "shared/ui/toast";
import { DICT_QUERY_KEYS } from "features/dictionaries/api/keys";
import { DICT_ENTITY_NAMES } from "features/dictionaries/api";

import { addOrg, removeOrgs, updateOrg } from "./api";
import { AddOrgParams, RemoveOrgsParams, UpdateOrgParams } from "./types";

export function useAddOrgMutation() {
  const queryClient = useQueryClient();
  return useMutation<boolean, QueryError, AddOrgParams>(addOrg, {
    onSuccess: async (data, params) => {
      toast.success("Организация добавлена");
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_INFINITE}_${DICT_ENTITY_NAMES.SPHERE_OIV_ORG}`]);
    },
    onError: (error) => {
      toast.error(error.response?.data.error ?? "Ошибка!");
    },
  });
}

export function useUpdateOrgMutation() {
  const queryClient = useQueryClient();
  return useMutation<boolean, QueryError, UpdateOrgParams>(updateOrg, {
    onSuccess: async (data, params) => {
      toast.success("Организация изменена");
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_INFINITE}_${DICT_ENTITY_NAMES.SPHERE_OIV_ORG}`]);
    },
    onError: (error) => {
      toast.error(error.response?.data.error ?? "Ошибка!");
    },
  });
}

export function useRemoveOrgMutation() {
  const queryClient = useQueryClient();
  return useMutation<boolean, QueryError, RemoveOrgsParams>(removeOrgs, {
    onSuccess: () => {
      queryClient.invalidateQueries([`${DICT_QUERY_KEYS.DICT_INFINITE}_${DICT_ENTITY_NAMES.SPHERE_OIV_ORG}`]);
      toast.success("Удаление выполнено");
    },
    onError: (error) => {
      if (error.response?.data.error === "conflict") {
        toast.error(
          "У организации есть сотрудники. Для удаления организации сначала необходимо удалить всех сотрудников",
        );
      } else {
        toast.error(error.response?.data.error ?? "Ошибка!");
      }
    },
  });
}
