import { Flex, Text } from "@chakra-ui/react";

import { getBgColor } from "./percent-indicator";

export type PercentileIndicatorProps = {
  value: number;
  type?: "less-better" | "more-better";
  sortedData: number[];
  showConsole?: boolean;
};

export const PercentileIndicator = ({ value, sortedData, type, showConsole }: PercentileIndicatorProps) => {
  return (
    <Flex
      position="absolute"
      right="0"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      w="50px"
      h="21px"
      bgColor={getPercentileColor({ value, sortedData, type, showConsole })}
    >
      <Text textStyle="description" color="custom.blue.dark.100">
        {value}%
      </Text>
    </Flex>
  );
};

const getPercentileColor = ({
  value,
  sortedData,
  type = "less-better",
  showConsole = false,
}: PercentileIndicatorProps) => {
  const lessBetterType = type === "less-better";
  const findedIndex = sortedData.findIndex((el) => el === value);

  const arrIndexLength = sortedData.length - 1;
  const isSmallRowsCount = sortedData.length <= 3;
  const quarterLength = Math.floor(sortedData.length / (isSmallRowsCount ? 3 : 4)); // делим на 4 зоны, по четверти на красную и зеленую, 2 четверти на желтую

  const startQuarter = sortedData.slice(0, quarterLength);
  const endQuarter = sortedData.slice(quarterLength * 3, sortedData.length);
  const middleQuarters = sortedData.slice(quarterLength, quarterLength * 3);

  if (arrIndexLength === 0) {
    return getBgColor({ value: sortedData[0], type });
  }

  if (findedIndex === arrIndexLength && arrIndexLength > 0) {
    return lessBetterType ? "rgb(180, 120, 114)" : "rgb(113, 180, 154)";
  }

  if (findedIndex === 0) {
    return lessBetterType ? "rgb(113, 180, 154)" : "rgb(180, 120, 114)";
  }

  if (findedIndex > arrIndexLength - quarterLength && findedIndex <= arrIndexLength) {
    const quarterFindedIndex = endQuarter.findIndex((el) => el === value);
    const alphaPercentile = +(0.4 + Number((0.5 / endQuarter.length).toFixed(6)) * (quarterFindedIndex + 1)).toFixed(6);

    return lessBetterType ? `rgb(180, 120, 114, ${alphaPercentile})` : `rgb(113, 180, 154, ${alphaPercentile})`;
  }

  if (findedIndex >= 0 && findedIndex < quarterLength) {
    const quarterFindedIndex = startQuarter.findIndex((el) => el === value);
    const alphaPercentile = +(0.4 + Number((0.5 / startQuarter.length).toFixed(6)) * (quarterFindedIndex + 1)).toFixed(
      6,
    );

    return lessBetterType
      ? `rgb(113, 180, 154, ${1.4 - alphaPercentile})`
      : `rgb(180, 120, 114, ${1.4 - alphaPercentile})`;
  }

  const quarterFindedIndex = middleQuarters.findIndex((el) => el === value);
  const alphaPercentile = +(0.4 + Number((0.6 / middleQuarters.length).toFixed(6)) * (quarterFindedIndex + 1)).toFixed(
    6,
  );

  return lessBetterType ? `rgb(243, 219, 92, ${alphaPercentile})` : `rgb(243, 219, 92, ${1.4 - alphaPercentile})`;
};
