import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

const M: FC<IconProps> = (props) => {
  return (
    <Icon width="19px" height="19px" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        className="dropdown-toggler"
        fill="currentColor"
        d="m16.383 5.163-8.767 10a.824.824 0 0 1-.267.204.735.735 0 0 1-.63 0 .824.824 0 0 1-.267-.204l-3.836-4.375a.959.959 0 0 1-.178-.305 1.055 1.055 0 0 1 0-.718.957.957 0 0 1 .178-.305.825.825 0 0 1 .267-.204.736.736 0 0 1 .63 0c.1.048.191.117.267.204l3.254 3.712 8.186-9.335a.775.775 0 0 1 .582-.275c.218 0 .428.098.582.275.154.176.241.415.241.664 0 .249-.087.488-.241.664l-.002-.002Z"
      />
    </Icon>
  );
};

const S: FC<IconProps> = (props) => {
  return (
    <Icon width="10px" height="9px" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        className="dropdown-toggler"
        fill="currentColor"
        d="M8.17 1.073a.846.846 0 0 1 1.207 1.185L4.875 7.89a.846.846 0 0 1-1.22.022L.67 4.926A.846.846 0 1 1 1.866 3.73l2.363 2.362 3.92-4.994a.3.3 0 0 1 .022-.025H8.17Z"
      />
    </Icon>
  );
};

export const CheckIcon = Object.assign(M, { S, M });
