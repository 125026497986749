import {
  createStandaloneToast,
  AlertDescription,
  CloseButton,
  AlertTitle,
  ToastProps,
  chakra,
  Alert,
} from "@chakra-ui/react";

import { theme } from "config/theme";

const { ToastContainer, toast: standaloneToast } = createStandaloneToast({
  defaultOptions: {
    position: "top",
    variant: "left-accent",
    isClosable: true,
    duration: 5000,
    containerStyle: {
      minWidth: "260px",
      mt: 4,
      mx: 4,
    },
  },
  theme,
});

type Options = Omit<ToastProps, "title">;

const toast = (title: ToastProps["title"], options: ToastProps = {}) => {
  const status = options.status ?? "success";
  return standaloneToast({
    ...options,
    status,
    render: (props) => <Toast textStyle="textLight" {...props} {...options} status={status} title={title} />,
  });
};

toast.default = toast;
toast.success = toast;

toast.error = (title: ToastProps["title"], options: Options = {}) => toast(title, { ...options, status: "error" });
toast.warning = (title: ToastProps["title"], options: Options = {}) => toast(title, { ...options, status: "warning" });
toast.info = (title: ToastProps["title"], options: Options = {}) => toast(title, { ...options, status: "info" });

export { ToastContainer, toast };

function Toast(props: ToastProps) {
  const { status, variant, id, title, isClosable, onClose, description } = props;
  const alertTitleId = `toast-${id}-title`;
  return (
    <Alert
      status={status}
      variant={variant}
      id={alertTitleId}
      alignItems="start"
      borderRadius="md"
      boxShadow="lg"
      paddingEnd={8}
      textAlign="start"
      width="auto"
      aria-labelledby={alertTitleId}
    >
      <chakra.div flex="1" maxWidth="100%">
        <AlertTitle textStyle="textLight" fontWeight="light" hidden={!title} id={alertTitleId}>
          {title}
        </AlertTitle>
        <AlertDescription hidden={!description} display="block">
          {description}
        </AlertDescription>
      </chakra.div>
      <CloseButton hidden={!isClosable} size="sm" onClick={onClose} position="absolute" insetEnd={1} top={1} />
    </Alert>
  );
}
