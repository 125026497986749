import { Flex, IconButton } from "@chakra-ui/react";
import { useAtomValue } from "jotai";

import { readOnlyModeAtom } from "features/layout/atoms";
import { SortDownIcon } from "shared/ui/icons";
import { PlusIcon } from "shared/ui/icons";

interface Props {
  isOpen: boolean;
  hasAttracted: boolean;
  onToggle: () => void;
  isAttractStaffDisabled?: boolean;
  onAttractStaffClick: () => void;
  isLoading?: boolean;
}

export const PanelActions = ({
  isAttractStaffDisabled,
  onAttractStaffClick,
  hasAttracted,
  isLoading,
  onToggle,
  isOpen,
}: Props) => {
  const readOnlyMode = useAtomValue(readOnlyModeAtom);

  return (
    <Flex alignItems="center" gap="4px">
      <IconButton
        aria-label="Добавить подчиненного"
        variant="rounded"
        size="sm"
        icon={<PlusIcon />}
        color="custom.purple.200"
        bg="white"
        _hover={{
          bg: "custom.purple.100",
          color: "custom.purple.200",
        }}
        {...((readOnlyMode || isAttractStaffDisabled) && {
          isDisabled: readOnlyMode || isAttractStaffDisabled,
        })}
        onClick={onAttractStaffClick}
      />

      {!hasAttracted ? null : (
        <IconButton
          onClick={onToggle}
          aria-label="down"
          variant="link"
          size="sm"
          isLoading={isLoading}
          icon={
            <SortDownIcon
              transform="auto"
              color="custom.purple.200"
              rotate={isOpen ? "180deg" : "0deg"}
              transitionProperty="transform"
              transitionDuration="normal"
              transitionTimingFunction="ease-out"
            />
          }
        />
      )}
    </Flex>
  );
};
