import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Text } from "@chakra-ui/react";

import { StringWithPercentagesFooter } from "shared/ui/table/footer/string-with-percentages";
import { StringWithPercentagesCell } from "shared/ui/table/cells/string-with-percentages";
import { StringCell } from "shared/ui/table/cells/string";

import { ColumnHeader } from "../components/column-header";
import { StatData } from "../api/types";

export type UseColumnsProps = {
  sortedData: {
    cab: number[];
    noCab: number[];
    deg: number[];
    edit: number[];
    noMos: number[];
    voted: number[];
    reestr: number[];
  };
};

export const useColumns = ({ sortedData }: UseColumnsProps) =>
  useMemo<ColumnDef<StatData>[]>(
    () => [
      {
        accessorKey: "sphere",
        size: 100,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">уровень 1</ColumnHeader>,
        cell: StringCell,
        footer: () => (
          <Text textStyle="textBold" textAlign="left" color="custom.blue.dark.100">
            Итого
          </Text>
        ),
      },
      {
        accessorKey: "oiv",
        size: 180,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">уровень 2</ColumnHeader>,
        cell: StringCell,
      },
      {
        accessorKey: "org",
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">уровень 3</ColumnHeader>,
        cell: StringCell,
      },
      {
        accessorKey: "msk_staff",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader>всего</ColumnHeader>,
        cell: (cell) => <StringCell {...cell} textAlign="center" ml="-30px" />,
        footer: (cell) => (
          <StringWithPercentagesFooter cell={cell} isPercent={false} metaKey="msk_staff" type="more-better" />
        ),
      },
      {
        accessorKey: "reestr_perc",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader>в реестре</ColumnHeader>,
        cell: (cell) => (
          <StringWithPercentagesCell
            cell={cell}
            accessorKey="reestr"
            sortedData={sortedData.reestr}
            type="more-better"
          />
        ),
        footer: (cell) => <StringWithPercentagesFooter cell={cell} metaKey="reestr" type="more-better" />,
      },
      {
        accessorKey: "voted_perc",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader>проголосовало</ColumnHeader>,
        cell: (cell) => (
          <StringWithPercentagesCell cell={cell} accessorKey="voted" sortedData={sortedData.voted} type="more-better" />
        ),
        footer: (cell) => <StringWithPercentagesFooter cell={cell} metaKey="voted" type="more-better" />,
      },
      {
        accessorKey: "no_cab_perc",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => (
          <ColumnHeader>
            нет учётной
            <br />
            записи
          </ColumnHeader>
        ),
        cell: (cell) => (
          <StringWithPercentagesCell
            cell={cell}
            accessorKey="no_cab"
            sortedData={sortedData.noCab}
            type="less-better"
          />
        ),
        footer: (cell) => <StringWithPercentagesFooter cell={cell} metaKey="no_cab" type="less-better" />,
      },
      {
        accessorKey: "cab_perc",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => (
          <ColumnHeader>
            всего учетных
            <br />
            записей
          </ColumnHeader>
        ),
        cell: (cell) => (
          <StringWithPercentagesCell
            cell={cell}
            accessorKey="cab"
            sortedData={sortedData.cab}
            type="more-better"
            showConsole
          />
        ),
        footer: (cell) => <StringWithPercentagesFooter cell={cell} metaKey="cab" type="more-better" />,
      },
      {
        accessorKey: "deg_perc",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader>готовность</ColumnHeader>,
        cell: (cell) => (
          <StringWithPercentagesCell
            cell={cell}
            accessorKey="deg"
            sortedData={sortedData.deg}
            type="more-better"
            showConsole
          />
        ),
        footer: (cell) => <StringWithPercentagesFooter cell={cell} metaKey="deg" type="more-better" />,
      },
      {
        accessorKey: "edit_perc",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => (
          <ColumnHeader>
            актуализировать
            <br />
            данные
          </ColumnHeader>
        ),
        cell: (cell) => (
          <StringWithPercentagesCell cell={cell} accessorKey="edit" sortedData={sortedData.edit} type="less-better" />
        ),
        footer: (cell) => <StringWithPercentagesFooter cell={cell} metaKey="edit" type="less-better" />,
      },
      {
        accessorKey: "no_mos_perc",
        size: 136,
        enableSorting: true,
        sortDescFirst: true,
        header: () => (
          <ColumnHeader>
            нет
            <br />
            регистрации
          </ColumnHeader>
        ),
        cell: (cell) => (
          <StringWithPercentagesCell
            cell={cell}
            accessorKey="no_mos"
            sortedData={sortedData.noMos}
            type="less-better"
          />
        ),
        footer: (cell) => <StringWithPercentagesFooter cell={cell} metaKey="no_mos" type="less-better" />,
      },
    ],
    [
      sortedData.cab,
      sortedData.deg,
      sortedData.edit,
      sortedData.noCab,
      sortedData.noMos,
      sortedData.reestr,
      sortedData.voted,
    ],
  );
