import {
  InputGroup,
  InputProps as CUIInputProps,
  Input as CUIInput,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import { FC } from "react";

import { EyeOutlinedIcon, NoEyeOutlinedIcon } from "shared/ui/icons";

type InputProps = Omit<CUIInputProps, "type">;

export const PasswordInput: FC<InputProps> = (props) => {
  const [show, setShow] = useState<boolean>(false);

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <InputGroup>
      <CUIInput {...props} type={show ? "text" : "password"} />
      <InputRightElement>
        <Button onClick={handleClick} isDisabled={props.isDisabled} variant="clickableIcon" type="button">
          {show ? <EyeOutlinedIcon color="custom.grey.100" /> : <NoEyeOutlinedIcon color="custom.grey.100" />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
