import { useQuery } from "@tanstack/react-query";

import { useAccessStorageStore } from "shared/store/use-access-storage-store";
import { QueryError } from "api/types";

import { PROFILE_QUERY_KEYS } from "./keys";
import { ProfileQuery } from "./types";
import { getProfile } from "./api";

const useAccessController = () => useAccessStorageStore();

export function useProfileQuery() {
  const { removeAccess, access } = useAccessController();
  return useQuery<ProfileQuery, QueryError>([PROFILE_QUERY_KEYS.PROFILE], getProfile, {
    staleTime: Infinity,
    enabled: !!access,
    onError: removeAccess,
  });
}
