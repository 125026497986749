import { DictStatData } from "features/dictionaries/api";

export type AdminParams = {
  name: ADMIN_ENTITY_NAMES;
  limit: number;
  offset?: number;
  order_by?: string;
  order_type?: "asc" | "desc";
  oiv?: string[];
  oiv_ids?: number[];
  org?: string[];
  org_ids?: number[];
  sphere?: string;
  sphere_ids?: number[];
};

export type UserAdminParams = {
  limit: number;
  offset?: number;
  order_by?: string;
  order_type?: "asc" | "desc";
};

export type AdminQuery = {
  data: Array<DictStatData>;
};

export enum ADMIN_ENTITY_NAMES {
  USER = "user",
  ORG = "org",
}

export type ADMIN_ENTITY_NAMES_STRING = keyof typeof ADMIN_ENTITY_NAMES;

export type RemoveOrgsParams = {
  ids: number[];
};

export type AddOrgParams = {
  inn: string;
  oiv_id: number;
  title: string;
};

export type UpdateOrgParams = {
  id: number;
  inn?: string;
  oiv_id?: number;
  title?: string;
};
