import { create } from "zustand";

import { SignInQuery } from "features/auth/api/types";
import { getLocalStorageData, removeLocalStorageData, setLocalStorageData } from "features/auth/helpers";

interface AccessStore {
  access: SignInQuery | null;
  setAccess: (data?: SignInQuery) => void;
  removeAccess: () => void;
}

export const useAccessStorageStore = create<AccessStore>((set) => ({
  access: getLocalStorageData("ACCESS"),
  setAccess: (data?: SignInQuery) => {
    setLocalStorageData("ACCESS", data);
    set({ access: data });
  },
  removeAccess: () => {
    removeLocalStorageData("ACCESS");
    set({ access: null });
  },
}));
