import { RecursiveObject } from "@chakra-ui/react";

export const shadows: RecursiveObject<string> = {
  shadows: {
    default:
      "0px 33px 80px rgba(0, 0, 0, 0.07), 0px 13.7866px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 7.37098px 17.869px rgba(0, 0, 0, 0.0417275), 0px 4.13211px 10.0172px rgba(0, 0, 0, 0.035), 0px 2.19453px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.913195px 2.21381px rgba(0, 0, 0, 0.0196802)",
    inner: "inset 0px 1px 2px rgba(0, 0, 0, 0.25)",
    focusInput: "0 0 0 1px #6059A6",
  },
};
