import { AccordionItem } from "@chakra-ui/react";

import { useOrgSelectedAtoms } from "features/users/atoms";
import { DictStatData } from "features/dictionaries/api";
import { getCurrentValues } from "features/users/helpers";

import { UserFilter } from "../filter-panel/popover";

import { UserAccordionPanel } from "./user-accordion-panel";
import { CustomAccordionButton } from "./button";

interface OrgAccordionItemProps {
  userFilter: UserFilter;
  org: DictStatData;
  lastItem: boolean;
  handleClickAddButton: ({ title, orgId }: { title: string; orgId: number }) => void;
}

export function OrgAccordionItem({ userFilter, org, lastItem, handleClickAddButton }: OrgAccordionItemProps) {
  const { isOrgSelected, onOrgSelected } = useOrgSelectedAtoms();
  const currentValues = getCurrentValues({ filter: userFilter, values: org });
  return (
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <CustomAccordionButton
            title={`${org?.org}`}
            orgId={org?.org_id}
            summary={currentValues}
            isExpanded={isExpanded}
            isOrganisation
            pl="14px"
            pr="20px"
            lastItem={lastItem}
            handleClickAddButton={handleClickAddButton}
            checkboxProps={{
              isChecked: isOrgSelected(org.org_id),
              /* eslint-disable @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              onChange: () => onOrgSelected(org.org_id),
            }}
          />
          {currentValues.cnt_all > 0 ? (
            <UserAccordionPanel isExpanded={isExpanded} userFilter={userFilter} org={org} />
          ) : null}
        </>
      )}
    </AccordionItem>
  );
}
