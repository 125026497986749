import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const EyeFilledIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M7.734 10a2.187 2.187 0 1 0 4.375 0 2.187 2.187 0 0 0-4.375 0Zm10.668-.504c-1.851-3.9-4.65-5.863-8.402-5.863-3.754 0-6.55 1.963-8.402 5.865a1.178 1.178 0 0 0 0 1.006c1.851 3.9 4.65 5.863 8.402 5.863 3.754 0 6.55-1.963 8.402-5.865.15-.317.15-.684 0-1.006Zm-8.48 3.941a3.438 3.438 0 1 1 0-6.875 3.438 3.438 0 0 1 0 6.875Z"
      />
    </Icon>
  );
};
