export enum OBLBUH_TYPE {
  /** Весь список сотрудников/дополнительных телефонов */
  ALL = 0,
  /** Сотрудники */
  STAFF = 1,
  /** Дополнительные телефоны */
  RELATIVE = 2,
}

export type OblbuhParams = {
  limit: number;
  offset?: number;
  order_by?: string;
  order_type?: "asc" | "desc";
  staff_id?: null | string;
  type?: OBLBUH_TYPE;
  phone?: string;
  sphere_ids?: number[];
  org_ids?: number[];
  oiv_ids?: number[];
  statuses?: number[];
  are_ignored_user_orgs?: boolean;
};

export type OblbuhData = {
  id: string;
  parent_id: null | string;
  parent_phone: null | string;
  phone: string;
  oiv_id: number;
  org_id: number;
  status: number;
  user_id: number;
  is_auth: number;
  sphere_id: number;
  comment?: string;
  count_relatives: number;
  count_relatives_reestr: number;
  count_relatives_voted: number;
  count_relatives_no_reestr: number;
  count_relatives_no_voted: number;
  created: string;
  updated: string;
};

export type TotalCount = {
  staff: number;
  attracted: number;
  people: number;
};

export type OblbuhQuery = {
  data: OblbuhData[];
  count: number;
  total_count: TotalCount;
};

export type CreateOblbuhParams = {
  org_id?: number;
  parent_id?: string;
  phone?: string;
  status?: number;
};

export type RemoveOblbuhParams = {
  ids: string[];
  org_ids?: number[];
};

export type UpdateOblbuhParams = {
  id: string;
  org_id?: number;
  parent_id?: string;
  phone?: string;
  status?: number;
};

export type UpdateCommentOblbuhParams = {
  id: string;
  comment?: number;
};

export type MoveOblbuhParams = {
  new_org_id: number;
  ids?: string[];
  old_org_ids?: number[];
};

export type ExportOblbuhParams = {
  order_by?: string;
  order_type?: "asc" | "desc";
  status?: number[];
};

export type BatchOblbuhParams = {
  org_id: number;
  data: {
    staff: { order: number; phone: number };
    attracted: { order: number; phone: number };
  }[];
};

export type SheetOblbuhPayload = {
  staff: { phone: string; status: number; already_exists_in_org: number; error: string };
  attracteds: { phone: string; status: number; already_exists_in_org: number; error: string }[] | null;
}[];

export type UploadOblbuhParams = {
  org_id: string;
  formData: FormData;
};

export type DownloadOblbuhParams = {
  filename: string;
};
