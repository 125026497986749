import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const PersonPlusIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor" clipPath="url(#a)">
        <path d="M1.25 17S0 17 0 15.75s1.25-5 7.5-5 7.5 3.75 7.5 5S13.75 17 13.75 17H1.25ZM7.5 9.5a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" />
        <path
          fillRule="evenodd"
          d="M16.875 5.75a.625.625 0 0 1 .625.625V8.25h1.875a.625.625 0 0 1 0 1.25H17.5v1.875a.625.625 0 1 1-1.25 0V9.5h-1.875a.625.625 0 0 1 0-1.25h1.875V6.375a.625.625 0 0 1 .625-.625Z"
          clipRule="evenodd"
        />
      </g>
    </Icon>
  );
};
