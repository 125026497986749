import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

export const Accordion: ComponentStyleConfig = {
  parts: ["root", "container", "button", "panel", "icon"],
  baseStyle: {
    container: {
      border: "none",
      borderWidth: 0,
    },
    panel: {
      padding: 0,
    },
    button: {
      border: "none",
      bg: "custom.purple.light.100",
      _hover: {
        bg: "custom.purple.light.100",
      },
    },
  },
  variants: {
    form: (props) => ({
      ...theme.components.Input.variants?.outline(props),
    }),
  },
};
