import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const BarsIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M3.333 6.5h3.334v9.167H3.333V6.5Zm10 3.333h3.334v5.834h-3.334V9.833Zm-5-7.5h3.334v13.334H8.333V2.333Z"
      />
    </Icon>
  );
};
