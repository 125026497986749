import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const FilterIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M8.976 16.34c-.2 0-.37-.068-.505-.204a.683.683 0 0 1-.204-.504v-4.253l-4.11-5.245a.675.675 0 0 1-.08-.744c.123-.26.339-.39.646-.39h9.923c.307 0 .523.13.647.39a.674.674 0 0 1-.08.744l-4.11 5.245v4.253c0 .2-.069.369-.205.505a.683.683 0 0 1-.504.203H8.976Z"
      />
    </Icon>
  );
};
