import { useRef, useState } from "react";
import { Box, Flex, Progress, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useVirtualizer } from "@tanstack/react-virtual";
import last from "lodash/last";
import {
  getPaginationRowModel,
  RowSelectionState,
  getSortedRowModel,
  getCoreRowModel,
  useReactTable,
  SortingState,
  flexRender,
  OnChangeFn,
  Row,
} from "@tanstack/react-table";

import { NoSortIcon, SortDownIcon } from "shared/ui/icons";
import { DictAdminData } from "features/dictionaries/api";

import { useColumns } from "../hooks";

import { TableContent } from "./table-content";

interface UsersTableProps<TMeta = unknown> {
  data?: DictAdminData[];
  rowSelection: RowSelectionState;
  handleRowSelection: OnChangeFn<RowSelectionState>;
  hasNextPage?: boolean;
  onNextPage: () => void;
  handleEditOrg?: (row: Row<DictAdminData>) => void;
  handleRemoveOrg?: (row: Row<DictAdminData>) => void;
}

export function AdminTable<TMeta = unknown>({
  handleRowSelection,
  onNextPage,
  handleEditOrg,
  handleRemoveOrg,
  rowSelection,
  hasNextPage = false,
  data = [],
}: UsersTableProps<TMeta>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useColumns({
    handleRemoveOrg,
    handleEditOrg,
  });

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const count = data?.length || 0;

  const isEmptyData = count < 1 || data[0] === null;

  const columnVisibility = Object.keys(data?.[0] || {}).reduce<Record<string, boolean>>((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});

  const table = useReactTable<DictAdminData>({
    data,
    columns,
    // getRowId: (row, _, parent) => {
    //   return parent ? [parent.id, row.id].join(".") : row.id;
    // },
    state: {
      sorting,
      rowSelection,
      columnVisibility: { ...columnVisibility },
    },
    enableSorting: true,
    manualPagination: true,
    maxMultiSortColCount: 4,
    isMultiSortEvent: () => true,
    onSortingChange: (fn) => {
      // Костыль позволяющий сортировать только по одному столбцу
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const next = last((fn as any)() as SortingState);
      const prev = sorting.find((s) => s.id === next?.id);
      const newSorting = (prev?.desc === false ? [] : [next]) as SortingState;
      /* eslint-enable @typescript-eslint/no-explicit-any */
      setSorting(newSorting);
    },
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row, _, parent) => {
      return parent && row ? [parent.id, row.org_id].join(".") : String(row?.org_id);
    },
    onRowSelectionChange: handleRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const rowVirtualizer = useVirtualizer({
    count,
    getScrollElement: () => tableContainerRef.current,
    // TODO: поменять при интеграции: https://tanstack.com/virtual/v3/docs/api/virtualizer#estimatesize
    // сейчас указана высота одной строки
    estimateSize: () => 39,
    // TODO: поменять при интеграции: https://tanstack.com/virtual/v3/docs/api/virtualizer#overscan
    overscan: 30,
  });

  const virtualRows = rowVirtualizer?.getVirtualItems() || [];

  if (isEmptyData) return null;

  return (
    <Box ref={tableContainerRef} pr={4} pb={10}>
      <InfiniteScroll
        dataLength={data.length}
        next={onNextPage}
        hasMore={hasNextPage}
        loader={<Progress size="xs" isIndeterminate />}
        style={{ overflow: "unset" }}
      >
        <Table
          height="fit-content"
          style={{
            tableLayout: "fixed",
          }}
        >
          <Thead
            position="sticky"
            display="table-header-group"
            h="50px"
            insetBlockStart={0}
            bg="linear-gradient(to top, var(--chakra-colors-custom-purple-light-200) 1px, white 1px, white 100%)"
            zIndex={1}
          >
            {table?.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    p={2}
                    w={header.column.getSize()}
                    maxW={header.column.getSize()}
                    textTransform="none"
                    _first={{ pl: 4 }}
                  >
                    {header.isPlaceholder ? null : (
                      <Flex
                        alignItems="center"
                        flexWrap="nowrap"
                        onClick={header.column.getToggleSortingHandler()}
                        cursor={header.column.getCanSort() ? "pointer" : "select-none"}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() ? (
                          <>
                            {{
                              asc: <SortDownIcon color="custom.purple.200" ml={2} />,
                              desc: (
                                <SortDownIcon color="custom.purple.200" ml={2} transform="rotate(180deg)  scaleX(-1)" />
                              ),
                            }[header.column.getIsSorted() as string] ?? <NoSortIcon color="custom.purple.200" ml={2} />}
                          </>
                        ) : null}
                      </Flex>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody borderRightWidth={1} borderRightStyle="solid" borderRightColor="custom.purple.light.200">
            <TableContent table={table} virtualRows={virtualRows} />
          </Tbody>
        </Table>
      </InfiniteScroll>
    </Box>
  );
}
