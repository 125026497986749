import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const DublicateIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#9C8717" rx="2" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M2.44 6.542h4.37l-1.509-1.51.648-.648 2.292 2.292.324.324-.324.324-2.292 2.292-.648-.648 1.51-1.51H2.438a4.584 4.584 0 1 0 0-.916Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};
