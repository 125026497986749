import React, { useMemo, useRef } from "react";
import pick from "lodash/pick";
import {
  CheckboxProps,
  useDisclosure,
  ButtonGroup,
  ButtonProps,
  IconButton,
  Checkbox,
  Button,
  Flex,
  Input,
  Spinner,
  Text,
  useOutsideClick,
} from "@chakra-ui/react";
import { useAtom, useAtomValue } from "jotai";
import findIndex from "lodash/findIndex";

import { formatPhoneToMask, formatPhoneToMaskOnlyNumbers, parseMaskToPhone } from "shared/lib/string";
import { CheckIcon, CrossIcon, TrashIcon } from "shared/ui/icons";
import { getNoun } from "shared/lib/string";
import { toast } from "shared/ui/toast";
import { readOnlyModeAtom } from "features/layout/atoms";
import { AuthorizeIcon } from "shared/ui/icons/status";

import { getCurrentAttractedValues, getStatuses } from "../helpers";
import {
  useCreateOblbuhMutation,
  useUpdateOblbuhMutation,
  CreateOblbuhParams,
  UpdateOblbuhParams,
  OblbuhData,
  useRemoveOblbuhMutation,
  useUpdateCommentOblbuhMutation,
  UpdateCommentOblbuhParams,
} from "../api";
import { syncUsers } from "../atoms";

import { UserFilter } from "./filter-panel/popover";
import { AlertModal } from "./modals/alert-modal";

type Values = Partial<Pick<OblbuhData, "org_id" | "parent_id" | "phone" | "status" | "comment">>;

interface Props extends CheckboxProps {
  userFilter?: UserFilter;
  onCleanInviteStaff?: () => void;
  onAttractedClick?: () => void;
  onCancelClick?: () => void;
  setShowSpinner?: (bool: boolean) => void;
  isOpenParent?: boolean;
  isNewElement?: boolean;
  isWorker?: boolean;
  item?: OblbuhData;
  showSpinner?: boolean;
}

export const BasicCard = ({
  userFilter,
  onCleanInviteStaff,
  onAttractedClick,
  onCancelClick,
  setShowSpinner,
  isNewElement = false,
  isDisabled,
  isWorker,
  item,
  showSpinner,
  ...args
}: Props) => {
  const [syncUser, setSyncUser] = useAtom(syncUsers);
  const readOnlyMode = useAtomValue(readOnlyModeAtom);

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: isNewElement });
  const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
  const { isOpen: isOpenComment, onOpen: onOpenComment, onClose: onCloseComment } = useDisclosure();

  const inputRef = useRef<HTMLInputElement>(null);
  const inputCommentRef = useRef<HTMLInputElement>(null);

  const count = getCurrentAttractedValues({ filter: userFilter, values: item });
  const prevValues = useMemo(() => pick(item, ["org_id", "parent_id", "phone", "status", "comment"]), [item]);

  const parentOfFindedPhone = Boolean(userFilter?.phone?.parent_phone) && isWorker;
  const noVotedUserFilter = userFilter?.statuses?.includes(4096);
  const noReestrUserFilter = userFilter?.statuses?.includes(8192);
  const notInReestrStatus = item?.status !== 4096 || item?.status !== 4096 + 2; // или тоже самое, но + статус Не москвич
  const notOkStatus = item?.status !== 8192 || item?.status !== 8192 + 2; // или тоже самое, но + статус Не москвич
  const haveNoVotedAttaracted =
    noVotedUserFilter && Boolean(item?.count_relatives_no_voted) && notInReestrStatus && isWorker;
  const haveNoReestrAttaracted =
    noReestrUserFilter && Boolean(item?.count_relatives_no_reestr) && notOkStatus && isWorker;

  const [values, setValues] = React.useState<Values>(prevValues);

  const isNotFilledPhone = values.phone?.length !== 11;

  const handleOnChange = (name: keyof Values, value: string) => {
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleCancel = () => {
    onCleanInviteStaff?.();
    setValues(prevValues);
    onCancelClick?.();
    setShowSpinner?.(true);
    onClose();
  };

  const handleCommentCancel = () => {
    onCleanInviteStaff?.();
    setValues(prevValues);
    onCancelClick?.();
    setShowSpinner?.(true);
    onCloseComment();
  };

  useOutsideClick({
    enabled: isOpen,
    ref: inputRef,
    handler: (event: Event) => {
      const isToggler =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        findIndex(event.target?.classList, (c) => c == "dropdown-toggler") != -1;
      if (!isToggler) handleCancel();
    },
  });

  useOutsideClick({
    enabled: isOpenComment,
    ref: inputCommentRef,
    handler: (event: Event) => {
      const isToggler =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        findIndex(event.target?.classList, (c) => c == "dropdown-toggler") != -1;
      if (!isToggler) handleCommentCancel();
    },
  });

  const create = useCreateOblbuhMutation();
  const update = useUpdateOblbuhMutation();
  const updateComment = useUpdateCommentOblbuhMutation();
  const remove = useRemoveOblbuhMutation();

  const handleOnSubmit = async () => {
    if (!values.phone) return;
    if (values.phone === prevValues.phone) {
      toast.info("Номер не изменился");
      handleCancel();
      return;
    }
    if (!prevValues?.phone)
      await create.mutateAsync(values as CreateOblbuhParams, {
        onSuccess: () => {
          // prevValues.phone = values.phone;
          setSyncUser({ id: "0", sync: true });
          handleCancel();
        },
      });
    else
      await update.mutateAsync({ id: item?.id, ...values } as UpdateOblbuhParams, {
        onSuccess: () => {
          prevValues.phone = values.phone;
          setSyncUser({ id: item?.id !== item?.parent_id ? item?.id || "0" : "0", sync: true });
          handleCancel();
        },
      });
  };

  const handleOnCommentSubmit = async () => {
    if (values.comment === prevValues.comment) {
      toast.info("Комментарий не изменился");
      handleCommentCancel();
      return;
    }
    await updateComment.mutateAsync({ id: item?.id, comment: values.comment } as UpdateCommentOblbuhParams, {
      onSuccess: () => {
        prevValues.comment = values.comment;
        setSyncUser({ id: "0", sync: false });
        handleCommentCancel();
      },
    });
  };

  const handleRemove = async (id: string) => {
    await remove.mutateAsync(
      { ids: [id] },
      {
        onSuccess: () => {
          toast.success("Номер удален");
          setSyncUser({ id, sync: true });
          onCloseModal();
        },
      },
    );
  };

  React.useEffect(() => {
    if (isDisabled && isOpen) handleCancel();
    if (!isDisabled && isOpen) {
      setTimeout(() => inputRef.current?.focus(), 200);
    }
    // eslint-disable-next-line
  }, [isDisabled, isOpen]);

  return (
    <Flex alignItems="center" w="full" h="fit-content" gap="4px" mt="4px">
      <ShowElement show={!isOpen && !isNewElement}>
        {syncUser.id === item?.id && syncUser.sync ? (
          <Flex alignItems="center">
            <Spinner color="custom.purple.200" size="sm" />
          </Flex>
        ) : (
          <Checkbox isDisabled={isDisabled} {...args} />
        )}
      </ShowElement>
      <ShowElement show={isOpen}>
        <Input
          ref={inputRef}
          textStyle="textLight"
          px="4px"
          isDisabled={isDisabled}
          size="sm"
          w="130px"
          value={formatPhoneToMaskOnlyNumbers(values.phone)}
          onChange={(v) => handleOnChange("phone", parseMaskToPhone(v.target.value))}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (isNotFilledPhone) {
                toast.error("Телефон не заполнен");
              } else {
                handleOnSubmit();
              }
            }
            if (event.key === "Escape") {
              handleCancel();
            }
          }}
        />
      </ShowElement>
      <ShowElement show={!isOpen}>
        <Flex gap="10px">
          <CustomButton
            isDisabled={isDisabled}
            onClick={onOpen}
            {...(readOnlyMode && { isDisabled: readOnlyMode })}
            color={parentOfFindedPhone || haveNoVotedAttaracted || haveNoReestrAttaracted ? "brand.200" : undefined}
            _disabled={{ opacity: 1, pointerEvents: "none", cursor: "default" }}
          >
            {formatPhoneToMask(prevValues.phone)}
          </CustomButton>
          <ShowElement show={!!isWorker && count > 0}>
            <CustomButton
              w="210px"
              justifyContent="flex-start"
              onClick={onAttractedClick}
              color={parentOfFindedPhone || haveNoVotedAttaracted || haveNoReestrAttaracted ? "brand.200" : undefined}
            >
              {`+${count}\n${getNoun(
                count,
                "дополнительный телефон",
                "дополнительных телефона",
                "дополнительных телефонов",
              )}`}
            </CustomButton>
          </ShowElement>
        </Flex>
      </ShowElement>
      <ShowElement show={isOpen}>
        <ButtonGroup variant="rounded" size="sm" borderRadius="full">
          <IconButton
            className="dropdown-toggler"
            isDisabled={isDisabled || isNotFilledPhone}
            aria-label="Применить изменения"
            icon={<CheckIcon.S className="dropdown-toggler" />}
            color="custom.purple.200"
            bgColor="white"
            onClick={handleOnSubmit}
            _hover={{
              bgColor: "custom.green.100",
              color: "white",
              _disabled: {
                bg: "custom.grey.light.200",
                color: "custom.grey.dark.100",
              },
            }}
          />
          <IconButton
            className="dropdown-toggler"
            isDisabled={isDisabled}
            aria-label="Отменить изменения"
            icon={<CrossIcon.S className="dropdown-toggler" />}
            color="custom.red.dark.100"
            onClick={handleCancel}
            bgColor="custom.red.light.300"
            _hover={{
              bgColor: "custom.red.light.300",
              color: "custom.red.dark.100",
            }}
          />
        </ButtonGroup>
      </ShowElement>
      <Flex>
        <IconButton
          variant="onlyIcon"
          size="xs"
          mx="4px"
          isDisabled={isDisabled || isNotFilledPhone || readOnlyMode}
          aria-label="Удалить"
          icon={<TrashIcon />}
          onClick={onOpenModal}
          color="custom.red.light.200"
          _hover={
            !readOnlyMode
              ? {
                  color: "custom.red.dark.100",
                }
              : {}
          }
        />
        <Flex w="1px" h="18px" bgColor="custom.purple.200" />
      </Flex>
      <Flex gap="4px" minH="16px" minW="120px" ml="4px">
        {getStatuses(item?.status)}
        {Boolean(item?.is_auth) ? <AuthorizeIcon boxSize="16px" role="img" /> : null}
      </Flex>
      <ShowElement show={isOpenComment && !isNewElement}>
        <Input
          flex={1}
          maxW="800px"
          ref={inputCommentRef}
          textStyle="textLight"
          pr="4px"
          pl="8px"
          isDisabled={isDisabled || readOnlyMode}
          size="sm"
          w="130px"
          value={values.comment}
          color="custom.purple.200"
          onChange={(v) => handleOnChange("comment", v.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleOnCommentSubmit();
            }
            if (event.key === "Escape") {
              handleCommentCancel();
            }
          }}
        />
      </ShowElement>
      <ShowElement show={(!isOpenComment && !isNewElement) || readOnlyMode}>
        <Flex
          borderWidth={1}
          borderStyle="solid"
          borderColor="custom.purple.light.200"
          bgColor="transparent"
          borderRadius="0.125rem"
          flex={1}
          maxW="800px"
          px="10px"
          py="1px"
          mt="3px"
          mb="2px"
          cursor={!readOnlyMode ? "pointer" : "default"}
          onClick={() => {
            if (!readOnlyMode) {
              onOpenComment();
              setTimeout(() => inputCommentRef.current?.focus(), 100);
            }
          }}
        >
          <Text fontSize="14px" color={prevValues.comment ? "brand.300" : "brand.100"}>
            {prevValues.comment || "Комментарий"}
          </Text>
        </Flex>
      </ShowElement>
      <ShowElement show={isOpenComment && !isNewElement}>
        <ButtonGroup variant="rounded" size="sm" borderRadius="full">
          <IconButton
            className="dropdown-toggler"
            isDisabled={isDisabled || isNotFilledPhone}
            aria-label="Применить изменения"
            icon={<CheckIcon.S className="dropdown-toggler" />}
            color="custom.purple.200"
            bgColor="white"
            onClick={handleOnCommentSubmit}
            _hover={{
              bgColor: "custom.green.100",
              color: "white",
              _disabled: {
                bg: "custom.grey.light.200",
                color: "custom.grey.dark.100",
              },
            }}
          />
          <IconButton
            className="dropdown-toggler"
            isDisabled={isDisabled}
            aria-label="Отменить изменения"
            icon={<CrossIcon.S className="dropdown-toggler" />}
            color="custom.red.dark.100"
            onClick={handleCommentCancel}
            bgColor="custom.red.light.300"
            _hover={{
              bgColor: "custom.red.light.300",
              color: "custom.red.dark.100",
            }}
          />
        </ButtonGroup>
      </ShowElement>
      {isOpenModal ? (
        <AlertModal
          isOpen={isOpenModal}
          onClose={onCloseModal}
          handleSubmit={() => {
            if (item?.id) {
              handleRemove(item.id);
            }
          }}
          handleCancel={onCloseModal}
          titleText={`Удалить телефон ${formatPhoneToMask(item?.phone)}?`}
          descText={
            <>
              Удаление телефона влечёт
              <br />
              Удаление всех его дополнительных телефонов
            </>
          }
          submitText="Удалить"
          cancelText="Отмена"
        />
      ) : null}
    </Flex>
  );
};

function CustomButton(props: ButtonProps) {
  return (
    <Button
      variant="link"
      fontWeight="300"
      fontSize="14px"
      lineHeight="16px"
      color="custom.purple.200"
      textTransform="unset"
      {...props}
    />
  );
}

function ShowElement({ show = true, children }: React.PropsWithChildren<{ show: boolean }>) {
  if (!show) return null;
  return <>{children}</>;
}
