import { useField } from "react-final-form";
import isArray from "lodash/isArray";

import { STAT_ENTITY_NAMES, STAT_ENTITY_NAMES_STRING } from "features/stats/api";

type Props = {
  defineType: STAT_ENTITY_NAMES_STRING[] | STAT_ENTITY_NAMES_STRING;
};

export function ShowNameElementField({ defineType, children }: React.PropsWithChildren<Props>) {
  const { input } = useField<STAT_ENTITY_NAMES>("name");
  const types = isArray(defineType)
    ? defineType.map((type) => STAT_ENTITY_NAMES[type])
    : scheme[STAT_ENTITY_NAMES[defineType]];
  const hasType = (types ?? []).includes(input.value);
  if (!hasType) return null;
  return <>{children}</>;
}

const scheme: Partial<Record<STAT_ENTITY_NAMES, STAT_ENTITY_NAMES[]>> = {
  [STAT_ENTITY_NAMES.SPHERE]: [
    STAT_ENTITY_NAMES.SPHERE,
    STAT_ENTITY_NAMES.SPHERE_RELATIVE,
    STAT_ENTITY_NAMES.OIV,
    STAT_ENTITY_NAMES.OIV_RELATIVE,
    STAT_ENTITY_NAMES.ORG,
    STAT_ENTITY_NAMES.ORG_RELATIVE,
    STAT_ENTITY_NAMES.VOTE_SPHERE,
    STAT_ENTITY_NAMES.VOTE_SPHERE_RELATIVE,
    STAT_ENTITY_NAMES.VOTE_OIV,
    STAT_ENTITY_NAMES.VOTE_OIV_RELATIVE,
    STAT_ENTITY_NAMES.VOTE_ORG,
    STAT_ENTITY_NAMES.VOTE_ORG_RELATIVE,
  ],
  [STAT_ENTITY_NAMES.OIV]: [
    STAT_ENTITY_NAMES.OIV,
    STAT_ENTITY_NAMES.OIV_RELATIVE,
    STAT_ENTITY_NAMES.ORG,
    STAT_ENTITY_NAMES.ORG_RELATIVE,
    STAT_ENTITY_NAMES.VOTE_OIV,
    STAT_ENTITY_NAMES.VOTE_OIV_RELATIVE,
    STAT_ENTITY_NAMES.VOTE_ORG,
    STAT_ENTITY_NAMES.VOTE_ORG_RELATIVE,
  ],
  [STAT_ENTITY_NAMES.ORG]: [
    STAT_ENTITY_NAMES.ORG,
    STAT_ENTITY_NAMES.ORG_RELATIVE,
    STAT_ENTITY_NAMES.VOTE_ORG,
    STAT_ENTITY_NAMES.VOTE_ORG_RELATIVE,
  ],
};
