export enum ROLES {
  DTOIV = 1,
  SPHERE = 2,
  OIV = 3,
  ORG = 4,
}

export type ROLES_STRING = keyof typeof ROLES;

export const DECODE_ROLE: Record<ROLES, string> = {
  [ROLES.DTOIV]: "dtoiv",
  [ROLES.SPHERE]: "sphere",
  [ROLES.OIV]: "oiv",
  [ROLES.ORG]: "org",
};
