import { PropsWithChildren } from "react";
import { Navigate } from "react-router";

import { useAccessStorageStore } from "shared/store/use-access-storage-store";
import { useProfileQuery } from "features/profile/api";

import { routePaths } from "./routePaths";

const useAccessController = () => useAccessStorageStore();

export function ProtectedRoute({ children }: PropsWithChildren) {
  const { access } = useAccessController();
  const { isError } = useProfileQuery();
  if (!access || isError) return <Navigate to={routePaths.login()} replace={true} />;
  return <>{children}</>;
}
