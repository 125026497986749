import { useCallback } from "react";

import { useAccessStorageStore } from "shared/store/use-access-storage-store";
import { ROLES, ROLES_STRING } from "shared/constants";

const useAccessController = () => useAccessStorageStore();

export function usePermissions() {
  const { access } = useAccessController();
  const currentUserRoleId = access?.roles?.[0].id ?? ROLES.ORG;

  const hasAny = useCallback(
    (permissions: ROLES_STRING[]) => {
      return permissions.some((perm) => ROLES[perm] === currentUserRoleId);
    },
    [currentUserRoleId],
  );

  return { hasAny };
}

type WithPermissionProps = React.PropsWithChildren<{
  permission: ROLES_STRING[];
}>;

export function WithPermission({ permission, children }: WithPermissionProps) {
  const { access } = useAccessController();
  const currentUserRoleId = access?.roles?.[0].id ?? ROLES.ORG;
  if (!permission.some((perm) => ROLES[perm] === currentUserRoleId)) return null;
  return <>{children}</>;
}
