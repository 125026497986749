import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const SearchIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        d="m17.5 17.5-3.738-3.745m2.071-5.005a7.084 7.084 0 1 1-14.167 0 7.084 7.084 0 0 1 14.167 0v0Z"
      />
    </Icon>
  );
};
