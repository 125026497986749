import { CellContext, RowData } from "@tanstack/react-table";
import { Flex, FlexProps, Text } from "@chakra-ui/react";

import { PercentileIndicator, PercentileIndicatorProps } from "shared/ui/percentile-indicator";

interface Props<TData extends RowData = Record<string, unknown>, TValue = unknown> extends FlexProps {
  cell: CellContext<TData, TValue>;
  type?: PercentileIndicatorProps["type"];
  accessorKey: keyof TData;
  isPercent?: boolean;
  sortedData: number[];
  showConsole?: boolean;
}

export function StringWithPercentagesCell<TData extends RowData = Record<string, unknown>, TValue = unknown>({
  cell,
  type,
  isPercent = true,
  accessorKey,
  sortedData,
  showConsole,
}: Props<TData, TValue>) {
  const percent = +cell.getValue<string>();
  const value = Number(cell.row.original?.[accessorKey] ?? 0);

  return (
    <Flex position="relative" gap="10px" height="100%" alignItems="center" justifyContent="center">
      <Text textStyle="textLight" color="custom.blue.dark.100" ml="-30px">
        {value}
      </Text>
      {!isPercent ? null : (
        <PercentileIndicator value={percent} sortedData={sortedData} type={type} showConsole={showConsole} />
      )}
    </Flex>
  );
}
