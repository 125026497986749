import { AccordionItem } from "@chakra-ui/react";

import { DictStatData } from "features/dictionaries/api";
import { getCurrentValues } from "features/users/helpers";

import { UserFilter } from "../filter-panel/popover";

import { OrgAccordionPanel } from "./org-accordion-panel";
import { CustomAccordionButton } from "./button";

interface Props {
  oiv: DictStatData;
  userFilter: UserFilter;
  lastItem: boolean;
  sphereId: number;
  handleClickAddButton: ({ title, orgId }: { title: string; orgId: number }) => void;
}

export function OivAccordionItem({ userFilter, oiv, lastItem, sphereId, handleClickAddButton }: Props) {
  const currentValues = getCurrentValues({ filter: userFilter, values: oiv });
  return (
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <CustomAccordionButton
            title={`Уровень 2: ${oiv?.oiv}`}
            isExpanded={isExpanded}
            summary={currentValues}
            lastItem={lastItem}
            pl="20px"
          />
          <OrgAccordionPanel
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            isExpanded={isExpanded}
            sphereId={sphereId}
            // @ts-ignore
            oivId={oiv.oiv_id}
            userFilter={userFilter}
            handleClickAddButton={handleClickAddButton}
          />
        </>
      )}
    </AccordionItem>
  );
}
