import { ValidationErrors } from "final-form";
import { Form } from "react-final-form";
import {
  IconButtonProps,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  IconButton,
  ModalBody,
  Button,
  Modal,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useAtomValue } from "jotai";

import { formatPhoneToMask, parseMaskToPhone } from "shared/lib/string";
import { CheckIcon, CrossIcon, PlusIcon } from "shared/ui/icons";
import { useCreateOblbuhMutation } from "features/users/api";
import { InputField } from "shared/form/input/field";
import { SelectOption } from "shared/form/select-v2";
import { SelectDictOrgStatField } from "features/dictionaries/components/fields/select-dict-org-stat-field";
import { readOnlyModeAtom } from "features/layout/atoms";

export type FormPayload = {
  org: SelectOption;
  phone: string;
};

export function CreateUserModalIconButton(props: Omit<IconButtonProps, "aria-label">) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isLoading, mutateAsync: create } = useCreateOblbuhMutation();

  const readOnlyMode = useAtomValue(readOnlyModeAtom);

  const handleOnSubmit = async (values: FormPayload) => {
    const newUser = {
      phone: values.phone,
      org_id: +values.org.value,
      status: 0,
    };
    create(newUser, { onSuccess: onClose });
  };

  const validate = async (v: FormPayload) => {
    const errors: ValidationErrors = {};
    if (v.phone?.length < 11) errors.phone = "Дозаполните номер телефона";
    if (!v.phone) errors.phone = "Заполните номер телефона";
    if (!v.org) errors.org = "Выберите уровень 3";
    return errors;
  };

  return (
    <>
      <IconButton
        variant="rounded"
        size="sm"
        icon={<PlusIcon color="white" />}
        aria-label="Добавить сотрудника"
        title="Добавить сотрудника"
        {...props}
        onClick={onOpen}
        {...(readOnlyMode && { isDisabled: readOnlyMode })}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <Form<FormPayload>
          onSubmit={handleOnSubmit}
          validate={validate}
          render={({ handleSubmit: submit, form, dirty, values }) => {
            return (
              <form onSubmit={submit}>
                <ModalContent py="30px" px="50px" minW="540px" gap="20px">
                  <ModalBody p="unset">
                    <Flex flexDir="column" align="center" gap="20px">
                      <Text textAlign="center" color="custom.grey.dark.100" textStyle="h1" textTransform="uppercase">
                        Добавление сотрудника
                      </Text>
                      <SelectDictOrgStatField name="org" selectProps={{ isMulti: false }} findAllOrgs />
                      <InputField
                        name="phone"
                        placeholder="Tелефон"
                        fieldConfig={{
                          parse: parseMaskToPhone,
                          format: formatPhoneToMask,
                        }}
                        inputProps={{
                          _placeholder: {
                            color: "custom.purple.100",
                            textTransform: "uppercase",
                            textStyle: "textLight",
                          },
                          variant: "flushed",
                          borderColor: "transparent",
                          borderBottomColor: "custom.purple.100",
                          _hover: { borderColor: "transparent", borderBottomColor: "custom.purple.200" },
                          _focus: { borderColor: "transparent", borderBottomColor: "custom.purple.200" },
                        }}
                      />
                    </Flex>
                  </ModalBody>

                  <ModalFooter p="unset">
                    <Flex
                      w="full"
                      justifyContent="center"
                      gap="20px"
                      bgColor="custom.grey.light.100"
                      py="20px"
                      px="30px"
                      borderTopWidth={0}
                      borderRadius={15}
                    >
                      <Button
                        minW="165px"
                        leftIcon={<CheckIcon />}
                        onClick={form.submit}
                        isDisabled={!dirty}
                        isLoading={isLoading}
                      >
                        Ок
                      </Button>
                      <Button minW="165px" isDisabled={isLoading} leftIcon={<CrossIcon />} onClick={onClose}>
                        Отмена
                      </Button>
                    </Flex>
                  </ModalFooter>
                </ModalContent>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
}
