import { CSSProperties } from "react";
import { ClearIndicatorProps } from "react-select";
import { Box } from "@chakra-ui/react";

import { CrossIcon } from "shared/ui/icons";

import { SelectOption } from "./types";

export const ClearIndicator = (props: ClearIndicatorProps<SelectOption>) => {
  const {
    children,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <Box {...restInnerProps} ref={ref} style={getStyles("clearIndicator", props) as CSSProperties}>
      <CrossIcon color="custom.purple.200" />
    </Box>
  );
};
