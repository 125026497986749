import { FC } from "react";
import { Global } from "@emotion/react";

export const Fonts: FC = () => (
  <Global
    styles={`
      @font-face {
          font-family: 'Roboto';
          src: url('/fonts/Roboto-Regular.woff2') format('woff2'),
              url('/fonts/Roboto-Regular.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
      }

      @font-face {
          font-family: 'Roboto';
          src: url('/fonts/Roboto-Light.woff2') format('woff2'),
              url('/fonts/Roboto-Light.woff') format('woff');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
      }

      @font-face {
          font-family: 'Roboto';
          src: url('/fonts/Roboto-Medium.woff2') format('woff2'),
              url('/fonts/Roboto-Medium.woff') format('woff');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
      }
`}
  />
);
