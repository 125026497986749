import { useState } from "react";
import { FC } from "react";
import { Box, Button, FormLabel, Icon, IconProps, VisuallyHiddenInput } from "@chakra-ui/react";

// Этот компонент костыль для случая переключения вида статистики для роли ОИВ (уровень 2)

type Value = 2 | 3;

export type DoubleSwitcherProps = {
  name: string;
  ariaLabel: string;
  iconColor: string;
  firstIcon: FC<IconProps>;
  secondIcon: FC<IconProps>;
  firstTip?: string;
  secondTip?: string;
  firstHaveBoldBorder?: boolean;
  secondHaveBoldBorder?: boolean;
  value: Value;
  onChange: (v: Value) => void;
};

export const DoubleSwitcher = ({
  name,
  ariaLabel,
  iconColor,
  firstIcon,
  secondIcon,
  firstTip = "",
  secondTip = "",
  firstHaveBoldBorder,
  secondHaveBoldBorder,
  value = 2,
  onChange,
}: DoubleSwitcherProps) => {
  const handleOnChange = () => {
    const newValue = (value < 3 ? value + 1 : 2) as Value;
    onChange(newValue);
  };

  const settings = {
    2: { transformPosition: 0, tip: firstTip, icon: firstIcon, style: { borderWidth: "3px" } },
    3: { transformPosition: 18, tip: secondTip, icon: secondIcon, style: { borderWidth: "3px" } },
  };

  const setting = settings[value];
  const style = firstHaveBoldBorder || secondHaveBoldBorder ? setting.style : {};

  return (
    <FormLabel
      htmlFor={`${name}-double-switcher`}
      as={"label"}
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={2}
      position="relative"
      title={setting.tip}
    >
      <Box
        position="absolute"
        display="inline-block"
        appearance="none"
        cursor="pointer"
        height="32px"
        width="50px"
        borderRadius="full"
        borderWidth="0.5px"
        boxShadow="shadows.inner"
        onClick={handleOnChange}
        zIndex={1}
      />
      <VisuallyHiddenInput id={`${name}-double-switcher`} name={name} value={value} readOnly />
      <Button
        aria-label={ariaLabel}
        variant="icon"
        size="icon"
        onClick={handleOnChange}
        transition="all 0.2s ease-in"
        transform={`translateX(${setting.transformPosition}px)`}
        position="absolute"
        cursor="pointer"
        top="1px"
        left="1px"
        w="30px"
        h="30px"
        bg="white"
        borderRadius="full"
        borderWidth={0}
        borderStyle="solid"
        borderColor="custom.purple.200"
        justifyContent="center"
        alignItems="center"
        {...style}
      >
        <Icon as={setting.icon} color={iconColor} w="20px" h="20px" />
      </Button>
      <Box
        display="inline-block"
        appearance="none"
        height="32px"
        width="50px"
        backgroundColor="custom.purple.100"
        borderRadius="full"
      />
    </FormLabel>
  );
};
