import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const BurgerMenuIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="26px" height="26px" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.25 6.75A1.25 1.25 0 0 1 5.5 5.5h15a1.25 1.25 0 0 1 0 2.5h-15a1.25 1.25 0 0 1-1.25-1.25Zm0 6.25a1.25 1.25 0 0 1 1.25-1.25h15a1.25 1.25 0 0 1 0 2.5h-15A1.25 1.25 0 0 1 4.25 13Zm0 6.25A1.25 1.25 0 0 1 5.5 18h15a1.25 1.25 0 0 1 0 2.5h-15a1.25 1.25 0 0 1-1.25-1.25Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};
