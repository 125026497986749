import { IconButton } from "@chakra-ui/react";

import { BarsIcon } from "shared/ui/icons";
import { Filter } from "features/stats/pages";

type Props = {
  filter: Filter;
  isActive: boolean;
  onClick: () => void;
};

export function EmployeeIconButton({ filter, isActive = false, onClick }: Props) {
  return (
    <IconButton
      variant="icon"
      size="icon"
      icon={<BarsIcon color={isActive ? "white" : "custom.purple.200"} />}
      aria-label="Потенциальный ресурс"
      title="Потенциальный ресурс"
      onClick={onClick}
      isActive={isActive}
      _focusVisible={{ boxShadow: "0px 0px 0px 1px var(--chakra-shadows-shadows-focusInput)" }}
      _hover={{ bg: "custom.purple.light.200" }}
      _active={{
        bg: "custom.purple.200",
        borderColor: "custom.purple.200",
        borderWidth: 1,
        borderStyle: "solid",
      }}
    />
  );
}
