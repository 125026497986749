import { useMemo } from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import { Checkbox, Flex, IconButton } from "@chakra-ui/react";

import { StringCell } from "shared/ui/table/cells/string";
import { PencilIcon, TrashIcon } from "shared/ui/icons";
import { DictAdminData } from "features/dictionaries/api";

import { ColumnHeader } from "../components/column-header";

interface useColumnsProps {
  handleEditOrg?: (row: Row<DictAdminData>) => void;
  handleRemoveOrg?: (row: Row<DictAdminData>) => void;
}

export const useColumns = ({ handleEditOrg, handleRemoveOrg }: useColumnsProps) =>
  useMemo<ColumnDef<DictAdminData>[]>(
    () => [
      {
        accessorKey: "select",
        size: 50,
        maxSize: 10,
        enableSorting: false,
        header: ({ table }) => (
          <Checkbox
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            p={1}
            isChecked={row?.getIsSelected()}
            isIndeterminate={row?.getIsSomeSelected()}
            onChange={row?.getToggleSelectedHandler()}
          />
        ),
      },
      {
        accessorKey: "sphere",
        size: 50,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">сфера</ColumnHeader>,
        cell: StringCell,
      },
      {
        accessorKey: "oiv",
        size: 50,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">ОИВ</ColumnHeader>,
        cell: StringCell,
      },
      {
        accessorKey: "org",
        size: 300,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">организация</ColumnHeader>,
        cell: StringCell,
      },
      {
        accessorKey: "inn",
        size: 35,
        enableSorting: true,
        sortDescFirst: true,
        header: () => <ColumnHeader textAlign="left">ИНН</ColumnHeader>,
        cell: StringCell,
      },
      {
        accessorKey: "actions",
        maxSize: 10,
        enableSorting: false,
        sortDescFirst: false,
        header: () => <ColumnHeader textAlign="left"> </ColumnHeader>,
        cell: ({ row }) => (
          <Flex gap="8px" justifyContent="flex-end">
            <IconButton
              variant="onlyIcon"
              size="sm"
              icon={<PencilIcon />}
              aria-label="Редактировать"
              title="Редактировать"
              onClick={() => handleEditOrg?.(row)}
            />
            <IconButton
              variant="onlyIcon"
              size="sm"
              icon={<TrashIcon />}
              aria-label="Удалить"
              title="Удалить"
              onClick={() => handleRemoveOrg?.(row)}
            />
          </Flex>
        ),
      },
    ],
    [handleEditOrg, handleRemoveOrg],
  );
