import { Box } from "@chakra-ui/react";

import { SelectOption } from "shared/form/select-v2";
import {
  ConfirmAccountIcon,
  DateBirthIcon,
  DegReadyIcon,
  PassportDateIcon,
  PassportNumIcon,
  PhoneIcon,
  RegistrationIcon,
  SnilsIcon,
  FIOIcon,
  PersonalAccountIcon,
  DublicateIcon,
  MoscowIcon,
  VerificationIcon,
  ReestrIcon,
  VotedIcon,
} from "shared/ui/icons/status";

export const statusesIcons = [
  <Box as="span" key="1" lineHeight={0}>
    <DegReadyIcon boxSize="16px" role="img" />
  </Box>, // 1 Готов ! title="Готов"
  <Box as="span" key="2" lineHeight={0}>
    <MoscowIcon boxSize="16px" role="img" />
  </Box>, // 2 Не москвич ! title="Прописка"
  <Box as="span" key="3" lineHeight={0}>
    <PersonalAccountIcon role="img" boxSize="16px" />
  </Box>, // 4 Нет личного кабинета ! title="Кабинет"
  <Box as="span" key="4" lineHeight={0}>
    <FIOIcon role="img" boxSize="16px" />
  </Box>, // 8 Нет ФИО ! title="ФИО"
  <Box as="span" key="5" lineHeight={0}>
    <DateBirthIcon role="img" boxSize="16px" />
  </Box>, // 16 Нет ДР ! title="ДР"
  <Box as="span" key="6" lineHeight={0}>
    <PhoneIcon role="img" boxSize="16px" />
  </Box>, // 32 Нет телефона ! title="Телефон"
  <Box as="span" key="7" lineHeight={0}>
    <RegistrationIcon role="img" boxSize="16px" />
  </Box>, // 64 Нет адреса регистрации ! title="Адрес регистрации"
  <Box as="span" key="8" lineHeight={0}>
    <SnilsIcon role="img" boxSize="16px" />
  </Box>, // 128 Нет СНИЛС ! title="СНИЛС"
  <Box as="span" key="9" lineHeight={0}>
    <PassportNumIcon role="img" boxSize="16px" />
  </Box>, // 256 Нет паспорта ! title="Паспорт"
  <Box as="span" key="10" lineHeight={0}>
    <PassportDateIcon role="img" boxSize="16px" />
  </Box>, // 512 Нет даты выдачи паспорта ! title="Дата выдачи"
  <Box as="span" key="11" lineHeight={0}>
    <ConfirmAccountIcon role="img" boxSize="16px" />
  </Box>, // 1024 ЛК не подтвержден ! title="Не подтвержден"
  <Box as="span" key="12" lineHeight={0}>
    <DublicateIcon key="12" role="img" boxSize="16px" />
  </Box>, // 2048 В ТИК // new СКАН ! title="В ТИК" //  у человека в личном кабинете на мосру все заполнено и подтверждено, но паспорт или фио не сошлись с мгик
  <Box as="span" key="12" lineHeight={0}>
    <ReestrIcon key="12" role="img" boxSize="16px" />
  </Box>, // 4096 В реестре ! title="В реестре"
  <Box as="span" key="12" lineHeight={0}>
    <VotedIcon key="12" role="img" boxSize="16px" />
  </Box>, // 8192 ОК ! title="ОК"
];

export const statusesText = [
  "Готов",
  "Не москвич",
  "Нет учётной записи",
  "Нет ФИО",
  "Нет ДР",
  "Нет телефона",
  "Нет адреса регистрации",
  "Нет СНИЛС",
  "Нет паспорта",
  "Нет даты выдачи паспорта",
  "ЛК не подтвержден",
  "Сотрудник другой организации",
];

export const statusOptions: SelectOption[] = [
  {
    value: "0",
    label: (
      <>
        <VerificationIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* НА ПРОВЕРКЕ */}
      </>
    ),
    color: "var(--chakra-colors-custom-grey-light-100)",
  },
  {
    value: "1024",
    label: (
      <>
        <ConfirmAccountIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* НЕ ПОДТВЕРЖДЕННЫЙ */}
      </>
    ),
    color: "var(--chakra-colors-custom-yellow-light-300)",
  },
  {
    value: "8",
    label: (
      <>
        <FIOIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* ФИО */}
      </>
    ),
    color: "var(--chakra-colors-custom-yellow-light-300)",
  },
  {
    value: "16",
    label: (
      <>
        <DateBirthIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* ДАТА РОЖДЕНИЯ */}
      </>
    ),
    color: "var(--chakra-colors-custom-yellow-light-300)",
  },
  {
    value: "32",
    label: (
      <>
        <PhoneIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* ТЕЛЕФОН */}
      </>
    ),
    color: "var(--chakra-colors-custom-yellow-light-300)",
  },
  {
    value: "64",
    label: (
      <>
        <RegistrationIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* АДРЕС */}
      </>
    ),
    color: "var(--chakra-colors-custom-yellow-light-300)",
  },
  {
    value: "128",
    label: (
      <>
        <SnilsIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* СНИЛС */}
      </>
    ),
    color: "var(--chakra-colors-custom-purple-light-200)",
  },
  {
    value: "256",
    label: (
      <>
        <PassportNumIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* ПАСПОРТНЫЕ ДАННЫЕ */}
      </>
    ),
    color: "var(--chakra-colors-custom-purple-light-200)",
  },
  {
    value: "512",
    label: (
      <>
        <PassportDateIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* ДАТА ВЫДАЧИ ПАСПОРТА */}
      </>
    ),
    color: "var(--chakra-colors-custom-purple-light-200)",
  },
  {
    value: "4",
    label: (
      <>
        <PersonalAccountIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* ЛИЧНЫЙ КАБИНЕТ */}
      </>
    ),
    color: "var(--chakra-colors-custom-red-light-300)",
  },
  {
    value: "2",
    label: (
      <>
        <MoscowIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* НЕ МОСКВИЧ */}
      </>
    ),
    color: "var(--chakra-colors-custom-red-light-300)",
  },
  {
    value: "2048",
    label: (
      <>
        <DublicateIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* ДУБЛИКАТ // В ТИК */}
      </>
    ),
    color: "var(--chakra-colors-custom-red-light-300)",
  },
  {
    value: "1",
    label: (
      <>
        <DegReadyIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* ГОТОВЫЕ */}
      </>
    ),
    color: "var(--chakra-colors-custom-green-light-100)",
  },
  {
    value: "8192",
    label: (
      <>
        <VotedIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* ГОЛОСОВАЛИ */}
      </>
    ),
    color: "var(--chakra-colors-custom-green-light-100)",
  },
  {
    value: "4096",
    label: (
      <>
        <ReestrIcon role="img" mr="5px" boxSize="1.1rem" />
        {/* В РЕЕСТРЕ */}
      </>
    ),
    color: "var(--chakra-colors-custom-green-light-100)",
  },
];
