import { ReactElement } from "react";
import {
  InputProps as CUIInputProps,
  Input as CUIInput,
  IconProps,
  forwardRef,
  InputGroup,
  InputRightElement,
  Button,
  InputLeftElement,
} from "@chakra-ui/react";

import { CrossIcon } from "shared/ui/icons";

export type InputProps = CUIInputProps & {
  icon?: ReactElement<IconProps>;
  onClear?: () => void;
  isClearable?: boolean;
};

export const Input = forwardRef<InputProps, "input">(({ icon, onClear, isClearable = true, ...props }, ref) => {
  return (
    <InputGroup>
      {!icon ? null : (
        <InputLeftElement pointerEvents="none">
          <icon.type color="custom.purple.200" mr="8px" mb="6px" {...icon.props} />
        </InputLeftElement>
      )}
      <CUIInput {...props} ref={ref} />;
      <InputRightElement>
        {isClearable && onClear && props.value ? (
          <Button
            onClick={onClear}
            isDisabled={props.isDisabled || props.isReadOnly}
            variant="onlyIcon"
            size="sm"
            type="button"
            w="full"
            p={0}
            m={0}
            mr="0px"
            mb="8px"
            borderColor="transparent"
            borderRadius="0 8px 8px 0"
            pointerEvents="all"
            tabIndex={-1}
          >
            <CrossIcon />
          </Button>
        ) : null}
      </InputRightElement>
    </InputGroup>
  );
});
