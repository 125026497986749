import { Flex, IconButton } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { z } from "zod";
import debounce from "lodash/debounce";

import { useOrgSelectedAtoms, useUsersSelectedAtoms } from "features/users/atoms";
import { TripleSwitcher } from "shared/form/triple-switcher";
import { BallotPaperCrossIcon, BallotPaperIcon, EraserIcon, ListIcon } from "shared/ui/icons";
import { SelectOption } from "shared/form/select-v2";
import { OblbuhData, useOblbuhQuery } from "features/users/api";
import { formValidator } from "shared/form/form-validator";
import { formatPhoneToMask } from "shared/lib/string";
import { toast } from "shared/ui/toast";

import { RemoveUsersModalIconButton } from "../modals/remove-users-modal-icon-button";
import { MoveUserModalIconButton } from "../modals/move-user-modal-icon-button";
import { CreateUserModalIconButton } from "../modals/create-user-modal-icon-button";
import { ExportOblbuhIconButton } from "../buttons/export-oblbuh-icon-button";
import { ImportUsersModalIconButton } from "../modals/import-users-modal-icon-button";
import { PhoneUsersField } from "../fields/mask-users-field";

import { FilterPopover, UserFilter } from "./popover";

interface Props {
  onUserFilterChange: (filter: UserFilter) => void;
  userFilter: UserFilter;
}

export type PhoneFilter = {
  phone: string;
};

export const FilterActions = ({ onUserFilterChange, userFilter }: Props) => {
  const { usersSelected, setUsersSelected } = useUsersSelectedAtoms();
  const { orgSelected, setOrgSelected } = useOrgSelectedAtoms();
  const [value, setValue] = useState<1 | 2 | 3>(1);
  const [phone, setPhone] = useState<string | undefined>(undefined);

  const { data: options = [], isSuccess } = useOblbuhQuery<OblbuhData[]>(
    { limit: 10, phone },
    {
      enabled: Boolean(phone),
      keepPreviousData: false,
      select: ({ data = [] }) =>
        data?.map((o) => ({ ...o, value: String(o.id), label: formatPhoneToMask(o.phone) })) || [],
    },
  );

  useEffect(() => {
    if (options.length > 0) {
      const opt = options[0] as SelectOption<string> & OblbuhData;

      onUserFilterChange({
        phone: opt,
        sphere: [
          {
            value: String(opt?.sphere_id),
            sphere_id: Number(opt?.sphere_id),
            label: "",
          },
        ],
        oiv: [
          {
            value: String(opt?.oiv_id),
            oiv_id: Number(opt?.oiv_id),
            sphere_id: opt?.sphere_id,
            label: "",
          },
        ],
        org: [
          {
            value: String(opt?.org_id),
            org_id: Number(opt?.org_id),
            sphere_id: opt?.sphere_id,
            oiv_id: opt?.oiv_id,
            label: "",
          },
        ],
      });
    }
    if (options.length === 0 && Boolean(phone) && isSuccess) {
      toast.info("Ничего не найдено", { isClosable: true });
    }
    if (phone === undefined && options.length === 0) {
      onUserFilterChange({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUserFilterChange, phone, isSuccess]);

  const onCleanAtoms = () => {
    setUsersSelected([]);
    setOrgSelected([]);
  };

  const handleChange = (v: 1 | 2 | 3) => {
    setValue(v);
    if (v === 1) {
      onUserFilterChange({
        ...userFilter,
        statuses: undefined,
      });
    }
    if (v === 2) {
      onUserFilterChange({
        ...userFilter,
        statuses: [4096],
      });
    }
    if (v === 3) {
      onUserFilterChange({
        ...userFilter,
        statuses: [8192],
      });
    }
  };

  const validationSchema = z.object({
    phone: z.string().trim().nullish().or(z.literal("")),
  });

  const validate = formValidator(validationSchema);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetInputValue = useCallback(
    debounce((value) => {
      setValue(1); // возвращаем свитчер в дефолт
      setPhone(value);
    }, 400),
    [],
  );

  const onPhoneChange = (value: string) => {
    if (value?.length === 11) {
      debounceSetInputValue(value);
    }
    if (value?.length === 0 || value === undefined) {
      debounceSetInputValue(undefined);
    }
  };

  return (
    <Flex gap="20px">
      <Form<PhoneFilter>
        onSubmit={() => undefined}
        validate={validate}
        render={({ handleSubmit }) => {
          return (
            <Flex as="form" onSubmit={handleSubmit}>
              <PhoneUsersField onCustomChange={onPhoneChange} />
            </Flex>
          );
        }}
      />
      <FilterPopover onFilterChange={onUserFilterChange} filter={userFilter} />
      <Flex gap="5px">
        <CreateUserModalIconButton />
        <MoveUserModalIconButton />
        <RemoveUsersModalIconButton />
        <IconButton
          variant="rounded"
          size="sm"
          icon={<EraserIcon color="white" />}
          aria-label="Очистить выбор"
          title="Очистить выбор"
          onClick={onCleanAtoms}
          isDisabled={usersSelected.length + orgSelected.length === 0}
        />
        <ExportOblbuhIconButton filter={userFilter} />
        <ImportUsersModalIconButton />
      </Flex>

      <TripleSwitcher
        name="ballot"
        ariaLabel="ballot"
        firstIcon={ListIcon}
        secondIcon={BallotPaperCrossIcon}
        secondHaveBoldBorder={true}
        thirdIcon={BallotPaperIcon}
        firstTip="Заполненность кабинетов"
        secondTip="Не приняли участие"
        thirdTip="Приняли участие"
        iconColor="custom.purple.200"
        value={value}
        onChange={handleChange}
      />
    </Flex>
  );
};
