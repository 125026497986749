import { SystemStyleObjectRecord } from "@chakra-ui/react";

export const textStyles: SystemStyleObjectRecord = {
  h1: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "23px",
    textTransform: "uppercase",
  },
  header: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "uppercase",
  },
  description: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
  },
  miniDescription: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
  },
  text: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
  },
  textBold: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
  },
  textLight: {
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "16px",
  },
  title: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "23px",
  },
  link: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    textDecorationLine: "underline",
  },
  button: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "uppercase",
  },
};
