import { useMutation, useQueryClient } from "@tanstack/react-query";
import { saveAs } from "file-saver";

import { toast } from "shared/ui/toast";
import { QueryError, UserQueryError } from "api/types";
import { formatPhoneToMask } from "shared/lib/string";

import {
  CreateOblbuhParams,
  UpdateOblbuhParams,
  RemoveOblbuhParams,
  MoveOblbuhParams,
  UploadOblbuhParams,
  UpdateCommentOblbuhParams,
  ExportOblbuhParams,
} from "./types";
import {
  createOblbuh,
  createReportOblbuh,
  downloadOblbuhXls,
  exportOblbuh,
  moveOblbuh,
  removeOblbuh,
  updateCommentOblbuh,
  updateOblbuh,
  uploadOblbuh,
} from "./api";
import { OBLBUH_QUERY_KEYS } from "./keys";

export function useCreateReportOblbuh() {
  return useMutation<{ uuid: string; status: "doing" | "done" | "error" }, QueryError, ExportOblbuhParams>(
    createReportOblbuh,
    {
      onError: () => toast.error("Ошибка!"),
    },
  );
}

export function useExportOblbuh() {
  return useMutation<Blob | string, QueryError, { uuid: string }>(exportOblbuh, {
    onSuccess: (data) => {
      if (typeof data === "string") {
        return data;
      } else {
        return saveAs(data, "oblbuh.xlsx");
      }
    },
  });
}

export function useCreateOblbuhMutation() {
  return useMutation<boolean, UserQueryError, CreateOblbuhParams>(createOblbuh, {
    useErrorBoundary: false,
    onError: (error, params) => {
      if (error.response?.data.error === "parent phone not found") {
        toast.error(error.response?.data.result.msg);
      }
      if (error.response?.data.error === "already exists") {
        const { phone } = params;
        const result = error.response?.data.result;
        const { employee: isWorker, msg: orgName } = result;

        toast.error(
          `${isWorker ? "Coтрудник" : "Дополнительный телефон"} ${phone ? `${formatPhoneToMask(phone)}` : ""} уже существует в ${orgName}`,
        );
      }
    },
  });
}

export function useUpdateOblbuhMutation() {
  return useMutation<boolean, UserQueryError, UpdateOblbuhParams>(updateOblbuh, {
    useErrorBoundary: false,
    onError: (error, params) => {
      if (error.response?.data.error === "already exists") {
        const { phone } = params;
        const result = error.response?.data.result;
        const { employee: isWorker, msg: orgName } = result;

        toast.error(
          `${isWorker ? "Coтрудник" : "Дополнительный телефон"} ${phone ? `${formatPhoneToMask(phone)}` : ""} уже существует в ${orgName}`,
        );
      }
    },
  });
}

export function useUpdateCommentOblbuhMutation() {
  const queryClient = useQueryClient();
  return useMutation<boolean, QueryError, UpdateCommentOblbuhParams>(updateCommentOblbuh, {
    onSuccess: async (data) => {
      if (data) {
        toast.success("Комментарий сохранен");
      }
    },
    onError: (error) => {
      toast.error(error.response?.data?.error ?? "Ошибка при сохранении комментария");
    },
  });
}

export function useRemoveOblbuhMutation() {
  return useMutation<boolean, QueryError, RemoveOblbuhParams>(removeOblbuh, {
    onError: (error) => toast.error(error.response?.data.error ?? "Ошибка!"),
  });
}

export function useMoveOblbuhMutation() {
  const queryClient = useQueryClient();
  return useMutation<boolean, QueryError, MoveOblbuhParams>(moveOblbuh, {
    onSuccess: () => {
      toast.success("Выбранные записи успешно перемещены");
    },
    onError: (error) => toast.error(error.response?.data.error ?? "Ошибка!"),
  });
}

export function useUploadOblbuhMutation() {
  return useMutation<boolean, QueryError, UploadOblbuhParams>(uploadOblbuh, {
    onSuccess: () => {
      toast.success("Файл успешно загружен");
    },
    onError: (error) => toast.error(error.response?.data.error ?? "Ошибка!"),
  });
}

export function useDownloadOblbuhXls({ filename }: { filename: string }) {
  return useMutation<Blob | string, QueryError>(downloadOblbuhXls, {
    onSuccess: (data) => {
      if (typeof data === "string") {
        return data;
      } else {
        return saveAs(data, `Отчет_${filename}.xlsx`);
      }
    },
  });
}
