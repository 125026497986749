import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const PersonalAccountIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#8A0000" rx="2" />
      <path
        fill="#FAFAFF"
        d="M3.875 10.75s-.625 0-.625-.625.625-2.5 3.75-2.5 3.75 1.875 3.75 2.5-.625.625-.625.625h-6.25ZM7 7a1.875 1.875 0 1 0 0-3.75A1.875 1.875 0 0 0 7 7Z"
      />
    </Icon>
  );
};
