import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Accordion, Progress, useDisclosure } from "@chakra-ui/react";

import { DICT_ENTITY_NAMES, useDictSphereStatInfiniteQuery } from "features/dictionaries/api";
import { Page } from "shared/ui/layout/page";

import { SphereAccordionItem } from "../components/accordion/sphere-accordion-item";
import { FilterStatsPanel } from "../components/filter-panel";
import { UserFilter } from "../components/filter-panel/popover";
import { userFilterAdapter } from "../lib";
import { CreateUserForOrganizationModal } from "../components/modals/create-user-for-org-modal";
import { getCurrentValues } from "../helpers";

export const UsersPage = () => {
  const [userFilter, setUserFilter] = React.useState<UserFilter>({});
  const [selectedOrg, setSelectedOrg] = useState<{ title: string; orgId: number } | undefined>(undefined);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dctInfiniteQuery = useDictSphereStatInfiniteQuery({
    ...userFilterAdapter(userFilter, ["sphere_ids", "statuses"]),
    limit: 100,
    order_by: "sphere",
    order_type: "asc",
  });
  const spheres = dctInfiniteQuery.data?.pages.flatMap((page) => page.data || []) ?? [];

  const summary = spheres.reduce(
    (acc, o) => ({
      relative: acc.relative + getCurrentValues({ filter: userFilter, values: o }).relative,
      staff: acc.staff + getCurrentValues({ filter: userFilter, values: o }).staff,
      cnt_all: acc.cnt_all + getCurrentValues({ filter: userFilter, values: o }).cnt_all,
    }),
    {
      relative: 0,
      staff: 0,
      cnt_all: 0,
    },
  );

  const handleSetOrganizationId = ({ title, orgId }: { title: string; orgId: number }) => {
    setSelectedOrg({ title, orgId });
    onOpen();
  };

  const handleCloseModal = () => {
    setSelectedOrg(undefined);
    onClose();
  };

  return (
    <Page height="fit-content">
      <FilterStatsPanel userFilter={userFilter} onUserFilterChange={setUserFilter} summary={summary} />
      <Accordion allowMultiple id={`accordion_${DICT_ENTITY_NAMES.SPHERE}`}>
        <InfiniteScroll
          dataLength={spheres.length}
          next={dctInfiniteQuery.fetchNextPage}
          hasMore={!!dctInfiniteQuery.hasNextPage}
          loader={<Progress size="xs" isIndeterminate />}
          style={{ overflow: "unset" }}
        >
          {spheres?.map((sphere, index) => (
            <SphereAccordionItem
              key={`${DICT_ENTITY_NAMES.SPHERE}_${sphere.sphere_id}`}
              sphere={sphere}
              userFilter={userFilter}
              handleClickAddButton={handleSetOrganizationId}
              lastItem={spheres.length - 1 === index}
            />
          ))}
        </InfiniteScroll>
      </Accordion>
      <CreateUserForOrganizationModal isOpen={isOpen} onClose={handleCloseModal} org={selectedOrg} />
    </Page>
  );
};
