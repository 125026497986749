import { ValidationErrors } from "final-form";
import { Form } from "react-final-form";
import { ModalOverlay, ModalContent, ModalFooter, ModalBody, Button, Modal, Flex, Text } from "@chakra-ui/react";

import { formatPhoneToMask, parseMaskToPhone } from "shared/lib/string";
import { CheckIcon, CrossIcon } from "shared/ui/icons";
import { useCreateOblbuhMutation } from "features/users/api";
import { InputField } from "shared/form/input/field";

export type FormPayload = {
  phone: string;
};

type CreateUserForOrganizationModal = {
  org?: { title: string; orgId: number };
  isOpen: boolean;
  onClose: () => void;
};

export function CreateUserForOrganizationModal({ org, isOpen, onClose }: CreateUserForOrganizationModal) {
  const { isLoading, mutateAsync: create } = useCreateOblbuhMutation();

  const handleOnSubmit = async (values: FormPayload) => {
    if (org?.orgId) {
      const newUser = {
        phone: values.phone,
        org_id: org?.orgId,
        status: 0,
      };
      create(newUser, { onSuccess: onClose });
    }
  };

  const validate = async (v: FormPayload) => {
    const errors: ValidationErrors = {};
    if (v.phone?.length < 11) errors.phone = "Дозаполните номер телефона";
    if (!v.phone) errors.phone = "Заполните номер телефона";
    return errors;
  };

  return (
    <Modal isOpen={isOpen && Boolean(org?.orgId)} onClose={onClose}>
      <ModalOverlay />
      <Form<FormPayload>
        onSubmit={handleOnSubmit}
        validate={validate}
        render={({ handleSubmit: submit, form, dirty, values }) => {
          return (
            <form onSubmit={submit}>
              <ModalContent py="30px" px="50px" minW="540px" gap="20px">
                <ModalBody p="unset">
                  <Flex flexDir="column" align="center" gap="20px">
                    <Text textAlign="center" color="custom.grey.dark.100" textStyle="h1" textTransform="none">
                      Добавление сотрудника в уровень 3
                      <br />
                      {`"${org?.title}"`}
                    </Text>
                    <InputField
                      name="phone"
                      placeholder="Tелефон"
                      fieldConfig={{
                        parse: parseMaskToPhone,
                        format: formatPhoneToMask,
                      }}
                      inputProps={{
                        _placeholder: {
                          color: "custom.purple.100",
                          textTransform: "uppercase",
                          textStyle: "textLight",
                        },
                        variant: "flushed",
                        borderColor: "transparent",
                        borderBottomColor: "custom.purple.100",
                        _hover: { borderColor: "transparent", borderBottomColor: "custom.purple.200" },
                        _focus: { borderColor: "transparent", borderBottomColor: "custom.purple.200" },
                      }}
                    />
                  </Flex>
                </ModalBody>

                <ModalFooter p="unset">
                  <Flex
                    w="full"
                    justifyContent="center"
                    gap="20px"
                    bgColor="custom.grey.light.100"
                    py="20px"
                    px="30px"
                    borderTopWidth={0}
                    borderRadius={15}
                  >
                    <Button
                      minW="165px"
                      leftIcon={<CheckIcon />}
                      onClick={form.submit}
                      isDisabled={!dirty}
                      isLoading={isLoading}
                    >
                      Ок
                    </Button>
                    <Button minW="165px" isDisabled={isLoading} leftIcon={<CrossIcon />} onClick={onClose}>
                      Отмена
                    </Button>
                  </Flex>
                </ModalFooter>
              </ModalContent>
            </form>
          );
        }}
      />
    </Modal>
  );
}
