import { useState } from "react";
import { forwardRef } from "@chakra-ui/react";
import { useField } from "react-final-form";
import { NumberFormatValues } from "react-number-format";

import { FormControl } from "../form-control";

import { MaskInput } from "./mask-input";
import { formatPhone, parsePhone } from "./masks/phone";
import { MaskInputFieldProps } from "./field";

export const PhoneField = forwardRef<MaskInputFieldProps & { onCustomChange?: (v: string) => void }, "input">(
  (
    {
      name,
      label,
      fieldConfig,
      caption,
      placeholder,
      autoComplete,
      type,
      icon,
      inputFormat,
      defaultValue,
      inputProps,
      isClearable,
      onCustomChange,
      ...props
    },
    ref,
  ) => {
    const {
      input: { value, onChange, onBlur },
      meta,
    } = useField<string, HTMLInputElement, string>(name, {
      ...fieldConfig,
    });

    const parsedValue = value ? parsePhone(value) : value;
    const [inputValue, setInputValue] = useState<string>(parsedValue);

    const handleValueChange = (values: NumberFormatValues) => {
      setInputValue(formatPhone(values.value));
      onChange(formatPhone(values.value));
      onCustomChange?.(formatPhone(values.value));
    };

    const handleClear = () => {
      setInputValue("");
      onChange({ target: { value: "" } });
      onCustomChange?.("");
    };

    return (
      <FormControl
        label={label}
        isInvalid={meta.touched && !!meta.error && meta.submitFailed}
        caption={(meta.touched && meta.error) || caption}
        {...props}
      >
        <MaskInput
          {...inputProps}
          valueIsNumericString={true}
          value={inputValue}
          onValueChange={handleValueChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
          inputFormat={inputFormat}
          placeholder={placeholder}
          autoComplete={autoComplete}
          type={type}
          icon={icon}
          ref={ref}
          isClearable={isClearable}
          onClear={handleClear}
        />
      </FormControl>
    );
  },
);
