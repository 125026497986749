import { useMutation } from "@tanstack/react-query";
import { saveAs } from "file-saver";

import { toast } from "shared/ui/toast";
import { QueryError } from "api/types";

import { exportEmployeesStat } from "./api";
import { STAT_TYPE } from "./types";

export function useGetExportStats({ filename }: { filename: string }) {
  return useMutation<Blob | string, QueryError, { type: STAT_TYPE }>(({ type }) => exportEmployeesStat({ type }), {
    onSuccess: (data) => {
      if (typeof data === "string") {
        return data;
      } else {
        toast.success("Загрузка отчета завершена");

        return saveAs(data, `Отчет_${filename}.xlsx`);
      }
    },
  });
}
