import { Form } from "react-final-form";
import uniqBy from "lodash/uniqBy";
import omit from "lodash/omit";
import {
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  PopoverHeader,
  PopoverFooter,
  PopoverBody,
  IconButton,
  Popover,
  Button,
  Portal,
  Flex,
  Text,
} from "@chakra-ui/react";

import { SelectDictSphereStatField } from "features/dictionaries/components/fields/select-dict-sphere-stat-field";
import { SelectDictOivStatField } from "features/dictionaries/components/fields/select-dict-oiv-stat-field";
import { SelectDictOrgStatField } from "features/dictionaries/components/fields/select-dict-org-stat-field";
import { CheckIcon, CrossIcon, FilterIcon } from "shared/ui/icons";
import { DictStatData } from "features/dictionaries/api";
import { SelectOption } from "shared/form/select-v2";
import { OblbuhData } from "features/users/api";

import { SelectStatusesField } from "../fields/select-statuses-field";

export type ExtendedDictStat = SelectOption &
  Omit<
    DictStatData,
    | "arch_attracted"
    | "relative"
    | "attracted_no_voted"
    | "attracted_voted"
    | "arch_staff"
    | "staff"
    | "staff_no_voted"
    | "staff_voted"
    | "arch_cnt_all"
    | "cnt_all"
    | "cnt_all_no_voted"
    | "cnt_voted"
  >;

export type UserFilter = {
  sphere?: Array<ExtendedDictStat>;
  oiv?: Array<ExtendedDictStat>;
  org?: Array<ExtendedDictStat>;
  statuses?: number[];
  phone?: SelectOption & OblbuhData;
};

export type OrgFilter = {
  sphere?: Array<ExtendedDictStat>;
  oiv?: Array<ExtendedDictStat>;
  org?: Array<ExtendedDictStat>;
};

interface Props {
  filter: UserFilter;
  onFilterChange: (filter: UserFilter) => void;
}

export const FilterPopover = ({ filter, onFilterChange }: Props) => {
  const popoverDisclosure = useDisclosure();

  const handleOnSubmit = async (v: UserFilter) => {
    onFilterChange({ ...filter, ...v });
    popoverDisclosure.onClose();
  };

  return (
    <Popover isLazy size="lg" placement="bottom-start" {...popoverDisclosure} closeOnBlur>
      <PopoverTrigger>
        <IconButton
          variant="rounded"
          size="sm"
          icon={<FilterIcon color="white" />}
          aria-label="Фильтр"
          title="Фильтр"
          bg={popoverDisclosure.isOpen ? "custom.purple.200" : "custom.purple.100"}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          w="510px"
          borderRadius={10}
          borderWidth={1}
          borderStyle="solid"
          borderColor="custom.purple.light.200"
          boxShadow="shadows.default"
          px="50px"
          pt="30px"
          pb="40px"
        >
          <Form<UserFilter>
            onSubmit={handleOnSubmit}
            initialValues={filter}
            render={({ handleSubmit: submit, form, dirty, values }) => {
              return (
                <>
                  <Flex as="form" onSubmit={submit} flexDir="column">
                    <PopoverHeader borderBottomWidth={0}>
                      <Text textStyle="h1" color="custom.grey.dark.100">
                        Фильтр
                      </Text>
                    </PopoverHeader>
                    <PopoverBody>
                      <Flex id="user-filter" flexDir="column" gap="20px">
                        <SelectDictSphereStatField
                          selectProps={{
                            allowSelectAll: true,
                          }}
                          onChange={() => {
                            form.initialize((values) => omit(values, ["oiv", "org", "statuses"]));
                          }}
                        />
                        <SelectDictOivStatField
                          selectProps={{
                            allowSelectAll: true,
                          }}
                          onChange={(v) => {
                            const value = v.length ? v : values.sphere ?? [];
                            const sphere = uniqBy(
                              value.map((o) => ({
                                value: String(o?.sphere_id),
                                sphere_id: Number(o?.sphere_id),
                                label: "",
                              })),
                              "sphere_id",
                            );
                            form.initialize((values) => ({
                              ...omit(values, ["statuses", "org"]),
                              sphere,
                            }));
                          }}
                        />
                        <SelectDictOrgStatField
                          onChange={(v) => {
                            const value = v.length ? v : values.oiv ?? [];
                            const sphere = uniqBy(
                              value.map((o) => ({
                                value: String(o?.sphere_id),
                                sphere_id: Number(o?.sphere_id),
                                label: "",
                                title: "",
                              })),
                              "sphere_id",
                            );
                            const oiv = uniqBy(
                              value.map((o) => ({
                                value: String(o?.oiv_id),
                                oiv_id: Number(o?.oiv_id),
                                sphere_id: o?.sphere_id,
                                label: "",
                              })),
                              "oiv_id",
                            );
                            form.initialize((values) => ({
                              ...omit(values, ["statuses"]),
                              sphere,
                              oiv,
                            }));
                          }}
                        />
                        <SelectStatusesField />
                      </Flex>
                    </PopoverBody>
                    <PopoverFooter
                      bgColor="custom.grey.light.100"
                      mt="20px"
                      py="20px"
                      borderRadius={15}
                      borderTopWidth={0}
                    >
                      <Flex justifyContent="center" gap="30px">
                        <Button type="submit" w="full" leftIcon={<CheckIcon />} isDisabled={!dirty}>
                          Применить
                        </Button>
                        <Button
                          onClick={() => {
                            onFilterChange({});
                            form.reset();
                          }}
                          w="full"
                          leftIcon={<CrossIcon />}
                        >
                          Очистить
                        </Button>
                      </Flex>
                    </PopoverFooter>
                  </Flex>
                </>
              );
            }}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
