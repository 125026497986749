import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const NoSortIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="11px" height="11px" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M2.854 5.542h5.292a.459.459 0 0 0 .343-.764L5.843 1.802a.457.457 0 0 0-.685 0L2.511 4.778a.459.459 0 0 0 .343.764Zm2.304 4.656a.457.457 0 0 0 .684 0L8.488 7.22a.458.458 0 0 0-.342-.763H2.854a.459.459 0 0 0-.342.763l2.646 2.977Z"
      />
    </Icon>
  );
};
