import { Accordion, AccordionPanel, AccordionPanelProps, Button, Progress } from "@chakra-ui/react";
import { atom, useAtom } from "jotai";

import { DICT_ENTITY_NAMES, useDictOrgStatInfiniteQuery } from "features/dictionaries/api";
import { userFilterAdapter } from "features/users/lib";

import { UserFilter } from "../filter-panel/popover";

import { OrgAccordionItem } from "./org-accordion-item";

interface Props extends AccordionPanelProps {
  userFilter: UserFilter;
  isExpanded?: boolean;
  sphereId: number;
  oivId: number;
  handleClickAddButton: ({ title, orgId }: { title: string; orgId: number }) => void;
}

export function OrgAccordionPanel({ userFilter, isExpanded, sphereId, oivId, handleClickAddButton }: Props) {
  const orgQuery = useDictOrgStatInfiniteQuery(
    {
      ...userFilterAdapter(userFilter, ["org_ids", "statuses"]),
      limit: 100,
      sphere_ids: [sphereId],
      oiv_ids: [oivId],
      order_by: "org",
      order_type: "asc",
    },
    {
      enabled: isExpanded,
    },
  );
  const orgs = orgQuery.data?.pages.flatMap((page) => page.data || []) ?? [];

  return (
    <>
      <Progress h="2px" mt="0" isIndeterminate={(isExpanded && orgQuery.isLoading) || orgQuery.isFetchingNextPage} />
      <AccordionPanel key={DICT_ENTITY_NAMES.ORG}>
        <Accordion allowToggle>
          {orgs?.map((org) => (
            <OrgAccordionItem
              key={`${DICT_ENTITY_NAMES.ORG}_${org.org_id}`}
              org={org}
              userFilter={userFilter}
              handleClickAddButton={handleClickAddButton}
              lastItem={false}
            />
          ))}
          <Button
            hidden={!orgQuery.hasNextPage}
            w="full"
            size="sm"
            borderRadius="unset"
            bg="rgb(120 114 180 / 50%)"
            onClick={() => orgQuery.fetchNextPage()}
            isLoading={orgQuery.isLoading || orgQuery.isFetchingNextPage}
          >
            Ещё уровни
          </Button>
        </Accordion>
      </AccordionPanel>
    </>
  );
}
