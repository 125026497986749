import { ValidationErrors } from "final-form";

import { NON_DIGITS_REGEXP } from "./constants";
import { FormPayload, XlsData, XlsDataError } from "./types";

export const validatePhone = (phone: number) => {
  const phoneStrOnlyDigits = String(phone).replace(NON_DIGITS_REGEXP, "");

  if (typeof Number(phoneStrOnlyDigits) !== "number") {
    return true;
  }

  if (
    phoneStrOnlyDigits.length !== 7 &&
    phoneStrOnlyDigits.length !== 10 &&
    phoneStrOnlyDigits.length <= 11 &&
    phoneStrOnlyDigits.length !== 11
  ) {
    return true;
  }

  return false;
};

export const validateUser = (user: XlsData, index: number, rawData: XlsData[]) => {
  const staffOrder = user[0];
  const phone = user[4];
  const attractedOrder = user[1];
  let err: XlsDataError;

  if (typeof staffOrder !== "number") {
    err = { rowId: index, staff: true };
  }
  if (validatePhone(phone)) {
    err = { ...err, rowId: index, phone: true };
  }

  // поиск подчиненных без указанных сотрудников
  if (Boolean(attractedOrder)) {
    if (
      !rawData.some((el) => {
        const currentStaffOrder = el[0];
        const itsANotAttracted = !Boolean(el[1]);

        return currentStaffOrder === staffOrder && itsANotAttracted;
      })
    ) {
      err = { ...err, rowId: index, notLinkedAttracted: true };
    }
  }

  if (err) {
    return err;
  }
};

export const validate = async (v: FormPayload) => {
  const errors: ValidationErrors = {};
  if (!v.file) errors.file = "Выберите файл для импорта";
  if (!v.org) errors.org = "Выберите уровень 3";
  return errors;
};
