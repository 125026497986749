import qs from "qs";

import { apiInstance, oblbuhInstance } from "api/client";
import { APIResponse } from "api/types";

import {
  BatchOblbuhParams,
  CreateOblbuhParams,
  ExportOblbuhParams,
  MoveOblbuhParams,
  OblbuhData,
  OblbuhParams,
  OblbuhQuery,
  RemoveOblbuhParams,
  UpdateCommentOblbuhParams,
  UpdateOblbuhParams,
  UploadOblbuhParams,
} from "./types";

export const getOblbuh = async (params: OblbuhParams) => {
  const queryString = qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
  const url = `/?${queryString}`;
  const { data } = await oblbuhInstance.get<OblbuhQuery>(url);
  return data.result;
};

export const createOblbuh = async (params: CreateOblbuhParams) => {
  const url = "/";
  const { data } = await oblbuhInstance.post<boolean>(url, params);
  return data.result;
};

export const updateOblbuh = async (params: UpdateOblbuhParams) => {
  const url = `/${params.id}`;
  const { data } = await oblbuhInstance.put<boolean>(url, params);
  return data.result;
};

export const updateCommentOblbuh = async (params: UpdateCommentOblbuhParams) => {
  const url = `/${params.id}/comment`;
  const { data } = await oblbuhInstance.patch<boolean>(url, { comment: params.comment });
  return data.result;
};

export const removeOblbuh = async (params: RemoveOblbuhParams) => {
  const url = "/";
  const { data } = await oblbuhInstance.delete<boolean>(url, { data: params });
  return data.result;
};

export const moveOblbuh = async (params: MoveOblbuhParams) => {
  const { new_org_id, ...restParams } = params;

  const url = `/move_orgs/${new_org_id}`;
  const { data } = await oblbuhInstance.patch<boolean>(url, { ...restParams });
  return data.result;
};

export const createReportOblbuh = async (params: ExportOblbuhParams) => {
  const url = "/download";
  const { data } = await oblbuhInstance.post(url, { ...params });
  return data.result;
};

export const exportOblbuh = async (params: { uuid: string }) => {
  const queryString = qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
  const url = `/download?${queryString}`;
  const data = await oblbuhInstance
    .get(url, { responseType: "blob" })
    .then((response) => {
      const blob = response.data;
      const headerType = response.headers["content-type"] as string;

      return new Blob([blob], { type: headerType });
    })
    .catch(async (error) => {
      const jsonResponse = JSON.parse(await error.response.data.text()) as APIResponse;
      return jsonResponse.error;
    });

  return data;
};

export const uploadOblbuh = async (params: UploadOblbuhParams) => {
  const { org_id, formData } = params;
  const url = `/batch/upload/${org_id}`;
  const { data } = await oblbuhInstance.post<boolean>(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data.result;
};

export const downloadOblbuhXls = async () => {
  const url = "/batch/download";
  const data = await oblbuhInstance
    .get(url, { responseType: "blob" })
    .then((response) => {
      const blob = response.data;
      const headerType = response.headers["content-type"] as string;

      return new Blob([blob], { type: headerType });
    })
    .catch(async (error) => {
      const jsonResponse = JSON.parse(await error.response.data.text()) as APIResponse;
      return jsonResponse.error;
    });

  return data;
};
