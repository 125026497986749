import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useAccessStorageStore } from "shared/store/use-access-storage-store";
import { routePaths } from "config/routes/routePaths";
import { QueryError } from "api/types";

import { SignInParams, SignInQuery } from "./types";
import { signIn, signOut } from "./api";

const useAccessController = () => useAccessStorageStore();

export function useSignInMutation() {
  const { setAccess } = useAccessController();
  const navigate = useNavigate();
  return useMutation<SignInQuery, QueryError, SignInParams>(signIn, {
    onSuccess: (data) => {
      setAccess(data);
      navigate(routePaths.users(), { replace: true });
    },
    onError: () => console.info("[unauthorized]"),
  });
}

export function useSignOutMutation() {
  const { removeAccess } = useAccessController();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(signOut, {
    onSuccess: () => {
      removeAccess();
      queryClient.removeQueries();
      navigate(routePaths.login(), { replace: true });
    },
  });
}
