import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const StateOneIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="3" height="20" x="8.5" fill="currentColor" rx="1.5" />
    </Icon>
  );
};
