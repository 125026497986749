import { Flex } from "@chakra-ui/react";

import { CollapseArrowsIcon, ExpandArrowsIcon } from "shared/ui/icons";

export const AccordionIcon = ({ isExpanded }: { isExpanded: boolean }) => {
  return isExpanded ? (
    <IconContainer isExpanded={isExpanded}>
      <ExpandArrowsIcon color="white" />
    </IconContainer>
  ) : (
    <IconContainer isExpanded={isExpanded}>
      <CollapseArrowsIcon color="white" />
    </IconContainer>
  );
};

const IconContainer = ({ children, isExpanded }: { children: React.ReactNode; isExpanded: boolean }) => (
  <Flex
    w="30px"
    h="30px"
    justifyContent="center"
    alignItems="center"
    bgColor={isExpanded ? "custom.purple.200" : "custom.purple.light.200"}
    _hover={{ bgColor: "custom.purple.200" }}
    borderRadius="full"
    mr="4px"
  >
    {children}
  </Flex>
);
