import axios from "axios";

import { getLocalStorageData } from "features/auth/helpers";
import { SignInQuery } from "features/auth/api/types";

import { APIResponse } from "./types";

export interface ExtendedAxiosRequestConfig {
  skipAuthRefresh?: boolean;
}

declare module "axios" {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface AxiosRequestConfig extends ExtendedAxiosRequestConfig {}
  /* eslint-enable @typescript-eslint/no-empty-interface */

  // prettier-ignore
  /* eslint-disable @typescript-eslint/no-explicit-any */
  export interface AxiosInstance {
    request<T = any, R = AxiosResponse<APIResponse<T>>>(config: AxiosRequestConfig): Promise<R>;
    get<T = any, R = AxiosResponse<T extends Blob ? T : APIResponse<T>>>(url: string, config?: AxiosRequestConfig): Promise<R>;
    delete<T = any, R = AxiosResponse<APIResponse<T>>>(url: string, config?: AxiosRequestConfig): Promise<R>;
    head<T = any, R = AxiosResponse<APIResponse<T>>>(url: string, config?: AxiosRequestConfig): Promise<R>;
    options<T = any, R = AxiosResponse<APIResponse<T>>>(url: string, config?: AxiosRequestConfig): Promise<R>;
    post<T = any, R = AxiosResponse<APIResponse<T>>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
    put<T = any, R = AxiosResponse<APIResponse<T>>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
    patch<T = any, R = AxiosResponse<APIResponse<T>>>(url: string, data?: any, config?: AxiosRequestConfig ): Promise<R>;
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "/api",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  transformRequest: (data, headers) => {
    const accessStorage = getLocalStorageData<SignInQuery>("ACCESS");
    if (accessStorage) headers["Authorization"] = `Bearer ${accessStorage.token}`;
    if (data instanceof FormData) return data;
    data = JSON.stringify(data);
    return data;
  },
});

export const authInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_HOST,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  transformRequest: (data, headers) => {
    const accessStorage = getLocalStorageData<SignInQuery>("ACCESS");
    if (accessStorage) headers["Authorization"] = `Bearer ${accessStorage.token}`;
    if (data instanceof FormData) return data;
    data = JSON.stringify(data);
    return data;
  },
});

export const statInstance = axios.create({
  baseURL: process.env.REACT_APP_STAT_HOST,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  transformRequest: (data, headers) => {
    const accessStorage = getLocalStorageData<SignInQuery>("ACCESS");
    if (accessStorage) headers["Authorization"] = `Bearer ${accessStorage.token}`;
    if (data instanceof FormData) return data;
    data = JSON.stringify(data);
    return data;
  },
});

export const oblbuhInstance = axios.create({
  baseURL: process.env.REACT_APP_OBLBUH_HOST,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  transformRequest: (data, headers) => {
    const accessStorage = getLocalStorageData<SignInQuery>("ACCESS");
    if (accessStorage) headers["Authorization"] = `Bearer ${accessStorage.token}`;
    if (data instanceof FormData) return data;
    data = JSON.stringify(data);
    return data;
  },
});
