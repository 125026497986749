import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const MoscowIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#8A0000" rx="2" />
      <path
        fill="#fff"
        d="m7 9.635 3.09 1.865-.82-3.515L12 5.62l-3.595-.305L7 2 5.595 5.315 2 5.62l2.73 2.365-.82 3.515L7 9.635Z"
      />
    </Icon>
  );
};
