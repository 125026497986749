import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const VotedIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" fill="#528661" rx="2" />
      <path
        fill="#fff"
        d="M8.912 3.8v6.4H7.593V3.8h1.319Zm3.832 0-2.536 3.16-1.463 1.574-.238-1.248.99-1.354 1.63-2.131h1.617Zm-1.499 6.4L9.36 7.241l.975-.818 2.47 3.775h-1.56ZM6.644 6.85v.304c0 .486-.066.923-.198 1.31a2.88 2.88 0 0 1-.558.988 2.42 2.42 0 0 1-.861.62 2.745 2.745 0 0 1-1.103.215c-.402 0-.77-.072-1.103-.215a2.473 2.473 0 0 1-.862-.62 2.896 2.896 0 0 1-.566-.989 4.045 4.045 0 0 1-.198-1.31v-.302c0-.49.066-.926.198-1.31a2.88 2.88 0 0 1 .558-.989c.243-.272.53-.48.861-.624a2.764 2.764 0 0 1 1.103-.215c.404 0 .772.072 1.103.215.334.144.621.352.861.624.244.273.431.602.563.989.135.384.202.82.202 1.31Zm-1.332.304v-.312c0-.34-.03-.639-.092-.897a1.943 1.943 0 0 0-.272-.65 1.176 1.176 0 0 0-.44-.396 1.256 1.256 0 0 0-.593-.136c-.223 0-.42.046-.593.136-.17.088-.315.22-.435.396a2.003 2.003 0 0 0-.268.65 3.888 3.888 0 0 0-.093.897v.312c0 .337.031.636.093.896.061.258.152.476.272.655.12.176.267.31.44.4.172.09.37.136.593.136.222 0 .42-.045.593-.136a1.18 1.18 0 0 0 .435-.4c.117-.179.207-.397.268-.655.062-.26.093-.56.093-.896Z"
      />
    </Icon>
  );
};
