import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const PaperclipIcon: FC<IconProps> = (props) => {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M3.448 16.607c-1.956-2.019-1.92-5.274.046-7.285l7.024-7.184a3.747 3.747 0 0 1 5.38 0 3.898 3.898 0 0 1 0 5.433l-6.123 6.257a2.498 2.498 0 0 1-3.615-.034c-.947-1.003-.917-2.594.048-3.581l4.813-4.916a.536.536 0 0 1 .758-.008l.765.749a.536.536 0 0 1 .008.757L7.74 11.711c-.165.17-.176.45-.022.613a.357.357 0 0 0 .525.005l6.123-6.256a1.754 1.754 0 0 0 0-2.437 1.606 1.606 0 0 0-2.316 0L5.026 10.82c-1.163 1.19-1.181 3.117-.04 4.296a2.855 2.855 0 0 0 4.12.01l5.762-5.893a.536.536 0 0 1 .757-.009l.766.749a.536.536 0 0 1 .009.757l-5.761 5.893a4.996 4.996 0 0 1-7.191-.016Z"
      />
    </Icon>
  );
};
