import { ChakraTheme, extendTheme } from "@chakra-ui/react";

import { Input } from "./components/input";
import { Button } from "./components/button";
import { Checkbox } from "./components/checkbox";
import { FormLabel } from "./components/form-label";
import { FormError } from "./components/form-error";
import { Accordion } from "./components/accordion";
import { config } from "./config";
import { colors } from "./colors";
import { shadows } from "./shadows";
import { textStyles } from "./text-styles";
import { typography } from "./typography";

export const customTheme: Partial<ChakraTheme> = {
  config,
  colors,
  shadows,
  components: {
    Input,
    Button,
    Checkbox,
    FormLabel,
    FormError,
    Accordion,
  },
  textStyles,
  ...typography,
};

export const theme = extendTheme(customTheme) as ChakraTheme;

export { Fonts } from "./fonts";
