import { components, MultiValueProps } from "react-select";
import { Text } from "@chakra-ui/react";

import { SelectOption } from "./types";

export const MultiValue = (props: MultiValueProps<SelectOption>) => {
  const labelToBeDisplayed = `${props.data.label}, `;

  return (
    <components.MultiValue {...props}>
      <Text as="span">{labelToBeDisplayed}</Text>
    </components.MultiValue>
  );
};
