import { IconButtonProps, useDisclosure, IconButton } from "@chakra-ui/react";
import { useAtomValue } from "jotai";

import { useOrgSelectedAtoms, useUsersSelectedAtoms } from "features/users/atoms";
import { RemoveOblbuhParams, useRemoveOblbuhMutation } from "features/users/api";
import { readOnlyModeAtom } from "features/layout/atoms";
import { TrashIcon } from "shared/ui/icons";
import { toast } from "shared/ui/toast";

import { AlertModal } from "./alert-modal";

export function RemoveUsersModalIconButton(props: Omit<IconButtonProps, "aria-label">) {
  const { usersSelected, setUsersSelected } = useUsersSelectedAtoms();
  const { orgSelected, setOrgSelected } = useOrgSelectedAtoms();
  const readOnlyMode = useAtomValue(readOnlyModeAtom);

  const isNothingSelected = usersSelected.length + orgSelected.length === 0;

  const onCleanAtoms = () => {
    setUsersSelected([]);
    setOrgSelected([]);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isLoading, mutateAsync: remover } = useRemoveOblbuhMutation();

  const handleOnRemoveClick = async () => {
    const ids = usersSelected.map((u) => u.id);
    const org_ids = orgSelected;

    const params: RemoveOblbuhParams = { ids };
    if (org_ids.length) params.org_ids = org_ids;

    remover(params, {
      onSuccess: () => {
        toast.success("Выбранные записи успешно удалены");
        onCleanAtoms();
        onClose();
      },
    });
  };

  return (
    <>
      <IconButton
        variant="rounded"
        size="sm"
        icon={<TrashIcon color="white" />}
        aria-label="Удалить"
        title="Удалить"
        {...((readOnlyMode || isNothingSelected) && { isDisabled: readOnlyMode || isNothingSelected })}
        {...props}
        onClick={onOpen}
      />
      <AlertModal
        isOpen={isOpen}
        onClose={onClose}
        handleSubmit={handleOnRemoveClick}
        handleCancel={onClose}
        titleText="Удалить выбранные записи?"
        descText={
          <>
            Удаление сотрудников влечёт
            <br />
            Удаление всех их дополнительных телефонов
          </>
        }
        submitText="Удалить"
        cancelText="Отмена"
        isLoadingSubmit={isLoading}
        isDisabledSubmit={usersSelected.length + orgSelected.length === 0}
      />
    </>
  );
}
