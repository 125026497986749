import { SelectFieldProps } from "shared/form/select-v2/field";
import { statusOptions } from "features/users/constants";
import { SelectField } from "shared/form/select-v2";

interface Props extends Omit<SelectFieldProps, "options" | "name" | "onChange"> {
  name?: string;
  onChange?: (v: number[]) => void;
}

export function SelectStatusesField({ selectProps, onChange, name = "statuses", ...args }: Props) {
  return (
    <SelectField
      name={name}
      selectProps={{
        isMulti: true,
        ...selectProps,
      }}
      valueLabel="статусов"
      placeholder="ВЫБЕРИТЕ СТАТУС..."
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      fieldConfig={{
        format: (ids) => {
          if (!ids) return [];
          // @ts-ignore
          return statusOptions.filter((s) => ids.some((id) => id.toString() === s.value));
        },
        parse: (v) => {
          if (!v) return [];
          // @ts-ignore
          return v.map((o) => +o.value);
        },
      }}
      isSearchable={false}
      {...args}
      // @ts-ignore
      onChange={onChange}
      /* eslint-enable @typescript-eslint/ban-ts-comment */
      options={statusOptions}
    />
  );
}
