import { components, DropdownIndicatorProps } from "react-select";

import { SortDownIcon } from "shared/ui/icons";

import { SelectOption } from "./types";

export const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <SortDownIcon color="custom.purple.200" />
    </components.DropdownIndicator>
  );
};
